import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";


export const baseURL = process.env.REACT_APP_BASE_URL;


// Define a function to switch to a specific tab by its ID
export function switchTab(tabId) {
    // Remove the "show active" class from all tab panes
    const tabPanes = document.querySelectorAll('.tab-pane');
    tabPanes.forEach((pane) => {
        pane.classList.remove('show', 'active');
    });

    // Add the "show active" class to the selected tab pane
    const selectedTabPane = document.querySelector(tabId);
    if (selectedTabPane) {
        selectedTabPane.classList.add('show', 'active');
    }

    // Remove the "active" class from all nav-links
    const navLinks = document.querySelectorAll('.nav-item.nav-link');
    navLinks.forEach((navLink) => {
        navLink.classList.remove('active');
    });

    // Add the "active" class to the nav-link corresponding to the selected tab
    const selectedNavLink = document.querySelector(`[href="${tabId}"]`);
    if (selectedNavLink) {
        selectedNavLink.classList.add('active');
    }
};


// Function to create a File object from a URL
export const createFileFromURL = async (url) => {
    // Fetch the image from the URL
    const response = await fetch(url);
    const blob = await response.blob();

    // Create a File object
    const file = new File([blob], url /*as the FileName*/, { type: blob.type });

    return file;
};


export const uploadImage = async (imageFile, user) => {
    // const navigate = useNavigate();

    // if (!(user && user.user.token)) {
    //     navigate('/login');
    //     toast.warn("You seem to have lost your access token. Please try again later.");
    //     return;
    // }

    // Check if the name attribute contains 'cloudinary.com/'
    if (imageFile.name && typeof imageFile.name === '' && imageFile.name.includes('cloudinary.com/')) {
        return imageFile.name; // Return the full name
    }

    const formData = new FormData();
    formData.append('file', imageFile);

    const uploadOptions = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${user.user.token}`,
        },
        body: formData,
    };

    try {
        const response = await fetch(`${baseURL}/uploader`, uploadOptions);
        const data = await response.json();
        return data.link;
    } catch (error) {
        toast.error('Error uploading an image.');
        console.error('Error uploading image:', error);
        throw error;
    }
};


export const uploadImagesOptimized = async (imageFiles, user) => {

  return await Promise.all(
    imageFiles.map(async (imageFile) => {
      // Check if the name attribute contains 'cloudinary.com/'
      if (imageFile.name.includes("cloudinary.com/")) {
        return imageFile.name; // Return the full name
      } else {
        // Upload the file and return the link
        return await uploadImagesOptimized(imageFile, user);
      }
    })
  );
};


export const handleNotOkHttpResponse = async (response, navigate = null) => {
  if (!response) {
    console.warn("The response object is invalid!");
    return;
  }

  try {
    const data = await getTheHttpResponseValue(response);
    if (response.status === 400 || response.status === 404 || response.status === 401) {
      if(data.message) {
        toast.warning(data.message);
      } else if(typeof data === 'string') {
        toast.warning(data);
      }

      if (response.status === 401 && navigate) {
        navigate("/");
      }
    } else {
      toast.warning("This request has failed.");
      console.error("This request has failed.");
    }
  } catch (error) {
    toast.error("Failed to parse error response.");
    console.error("Failed to parse error response:", error);
  }
};

export const getTheHttpResponseValue = async (response) => {
  const contentType = response.headers.get("Content-Type");

  let result;
  if (contentType && (contentType.includes("application/json") || contentType.includes("application/problem+json"))) {
      result = await response.json(); // Parse as JSON
  } else if (contentType && contentType.includes("text/plain")) {
      result = await response.text(); // Parse as plain text
  } else {
    toast.error("Unsupported content type: " + contentType);
    console.error("Unsupported content type: " + contentType);
  }

  return result;
}


export const doesRoleExist = (user, roleName) => {
  let roleExists = false;

  if (user && user.user && user.user.roles) {
    const roles = user.user.roles;

    // Convert the role name to lowercase for case-insensitive search
    const lowerCaseRoleName = roleName.toLowerCase();

    // Use Array.some to check if any role matches the name
    roleExists = roles.some((role) => role.name.toLowerCase() === lowerCaseRoleName);
  }

  return roleExists;
};

export function getAllWorkflowRoleIds() {
  return [
    '58509D3D-5EE2-4BA4-8AA5-0181C3E5E8DF', 
    'DDD26E2B-2BA6-4216-958C-0707AD1AE271', 
    '8E7C85E5-EFF3-4628-9AB7-0E1CF620A0D9', 
    'DA0F4489-51D6-4A3D-8DB4-11E491359086', 
    '77E8CE5F-B5BD-47E6-834E-1860BAD66F99', 
    '7AA3886F-1B49-4661-9537-1AB1D4C253EF', 
    'D344A823-CC99-4588-95BE-427B8DEEF672', 
    '44E46C38-C48B-41EA-936E-430F4A5784F1', 
    'EED16407-D878-4837-9562-44A7A15E63F6', 
    'C440D5A4-6EA7-427B-8403-4B96D50C27FF', 
    '4748CF47-D01B-4877-B75B-62195CCD1187', 
    '95AA2955-0C26-446E-B7F9-80FC33F97F9E', 
    '5C86BD5A-3DEB-4665-8A0D-89E259F508A6', 
    '30C6FFF0-6A1B-4A6B-ABF5-8B196F48BC0D', 
    '3A2453E1-A331-4E7E-9919-8CC58B627605', 
    'FEE18B58-D32D-4D4E-8096-8EF7D2D001DD', 
    '3988551E-CED7-4254-A436-9953114E6F59', 
    '20FB624E-93DA-40B6-8025-A9667B24AED0', 
    '8A8594A4-340F-462F-A0AD-C10B41F6C487', 
    '5A8C4767-DC26-496C-BD4F-CDD5D60438A4', 
    'B68C9CB8-9C79-4596-ACAC-CE286266C337',
  ];
}

export function hasRoleInWorkflowRoles(roles) {
  const workflowRolesSet = new Set(getAllWorkflowRoleIds().map(id => id.toLowerCase()));
  return roles.some(role => workflowRolesSet.has(role.id.toLowerCase()));
}

export const hasAdminRole = (roles) => {
  // Ensure roles is an array before calling some
  return Array.isArray(roles) && roles.some(role => role.name === 'Admin' || role.name === 'Farmer');
};

export const hasBuyAndAdminRole = (roles) => {
  // Ensure roles is an array before calling some
  return Array.isArray(roles) && roles.some(role => role.name === 'Admin' || role.name === 'Farmer' || role.name === 'Buyer');
};