import { useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../../../components/common/SideBarWrapper";
import Navbar from "../../../../layout/Navbar";
import { useEffect, useState } from "react";
import { baseURL } from "../../../../utils/misc";
import useAuth from "../../../../hooks/useAuth";
import { toast } from "react-toastify";
import TableStagesProcess from "../TableStagesProcess";
import Preloader from "../../../../components/common/Preloader";

const TableStages = () => {
    const { workflowProcessId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();

    const [showPreloader, setShowPreloader] = useState(false);

    const [workflowData, setWorkflowData] = useState(null);

    useEffect(() => {
        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }

        // Fetch workflow process data when the component mounts
        const getWorkFlowProcessStages = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
                toast.warn("You seem to have lost your access token. Please try again later.");
                return;
            }
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                }
            };
            // Fetch user data when the component mounts
            fetch(`${baseURL}/workflowstages/workflowprocess/${workflowProcessId}`, requestOptions)
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.error('Error fetching workflowProcessesStages')
                    }
                })
                .then((data) => {
                    // Set the useState valuations with the fetched data
                    setWorkflowData(data);
                })
                .catch((error) => {
                    toast.error("Error fetching workflowstages.");
                    console.error("Error fetching workflowstages:", error);
                });
        }
        getWorkFlowProcessStages();
    }, [workflowProcessId]);





    return (<>
        {/* Loading starts */}
        {/* <div id="loading-wrapper">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div> */}
        {/* Loading ends */}

        
        <Preloader show={showPreloader} />


        {/* Page wrapper start */}
        <div className="page-wrapper">

            {/* Sidebar wrapper start */}
            <SideBarWrapper />
            {/* Sidebar wrapper end */}

            {/* Page content start  */}
            <div className="page-content">

                {/* Header start */}
                <Navbar />
                {/* Header end */}

                {/* Page header start */}
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item active">Workflow Stages</li>
                    </ol>
                    <ul className="app-actions">
                    </ul>
                </div>
                {/* Page header end */}

                {/* Main container start */}
                <div className="main-container">
                    <TableStagesProcess workflowProcessId={workflowProcessId}  />
                </div>
                {/* Main container end */}

            </div>
            {/* Page content end */}

        </div>
        {/* Page wrapper end */}
    </> );
}
 
export default TableStages;