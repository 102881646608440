import { Link, useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import React,{ useEffect, useState } from "react";
import { baseURL } from "../../../utils/misc";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import FarmTable from "../../../components/Farm/FarmTable";
import { getFarmerByUserId } from "../../../api/Farmer/getFarmerByUserId";
import Preloader from "../../../components/common/Preloader";
import Footer from "../../../layout/Footer";

const Farms = () => {
    const { farmerIdParam } = useParams();
    const [showPreloader, setShowPreloader] = useState(false);

    const [farmerId, setFarmerId] = useState(farmerIdParam);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(!user || !user.user) {
            toast.warn("You are not authorized to perform this function!");
            navigate('/');
            return;
        }
        
        if(!farmerId) {
            try {
                setShowPreloader(true);
                getFarmerByUserId(user.user.id, user.user.token)
                .then((theFarmer) => {
                    if(theFarmer) {
                        setFarmerId(theFarmer.id);
                    }
                });
            } catch{} finally {
                setShowPreloader(false);
            }
        }
    }, []);


    return (
        <>
        <Preloader show={showPreloader} />
            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            {/* <li className="breadcrumb-item">Farmer</li> */}
                            <li className="breadcrumb-item active">Farms</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                       {farmerId && <FarmTable farmerId={farmerId} /> }
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer/>

            </div>
            {/* Page wrapper end */}
        </>
    );
}

export default Farms;