import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { isoToDate } from "../../utils/helpers";
import './LogisticsWaybill.css';


const LogisticsWaybill = ({ waybillData }) => {
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const waybillRef = useRef();

  if (!waybillData) return null; // Render nothing until data is fetched

  const downloadPDF = () => {
    setShowPreloader(true);
    
    const content = document.getElementById("waybillContent");
  
    // Add the PDF class
    content.classList.add('pdf-mode');
  
    html2canvas(content, { scale: 2 }) // Adjust scale if needed
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({ orientation: "portrait", unit: "mm", format: "a4", putOnlyUsedFonts: true });
        const imgWidth = 200; // Adjust width to fit PDF page
        const pageHeight = pdf.internal.pageSize.height;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        pdf.addImage(imgData, "PNG", 5, position, imgWidth, imgHeight); // Adjust position if needed
        heightLeft -= pageHeight;
  
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 5, position, imgWidth, imgHeight); // Adjust position if needed
          heightLeft -= pageHeight;
        }
  
        pdf.save(`${waybillData.code} - ${isoToDate(waybillData.date)}.pdf`);
        setShowPreloader(false);
  
        // Remove the PDF class after generation
        content.classList.remove('pdf-mode');
      });
  };
  
  

  return (
    <>
      <Preloader show={showPreloader} />
      <div className="row gutters">
        <div className="col-12" ref={waybillRef}>
          <div className="d-flex justify-content-between mb-3">
                <button className="btn btn-primary" onClick={() => navigate(-1)}>
                  Go Back
                </button>
                <button className="btn btn-primary" onClick={downloadPDF}>
                  Download PDF
                </button>
          </div>
          <div className="card" id="waybillContent">
            <div className="card-header" style={{ textAlign: 'center', padding: '10px' }}>
              <img
                src={window.location.origin + "/assets/img/Synergy Traceability System_Logo_Portrait.jpeg"}
                alt="STS Logo"
                style={{ maxWidth: '100px', marginBottom: '10px' }}
              />
              <h2 style={{ margin: '0', fontSize: '1.8rem' }}>Documentation: {waybillData.documentation || "N/A"}</h2>
              <h4 style={{ margin: '5px 0', fontSize: '1.2rem' }}>Transport Mode: {waybillData.transportMode || "N/A"}</h4>
              <h4 style={{ margin: '5px 0', fontSize: '1.2rem' }}>Date: {new Date(waybillData.date).toLocaleDateString() || "N/A"}</h4>
            </div>
            <div className="card-body" style={{ padding: '20px', backgroundColor: '#eff1f5' }}>
              <div className="d-flex justify-content-between mb-4">
                <h5 style={{ fontWeight: 'bold' }}>Logistics Code: {waybillData.code || "N/A"}</h5>
                <h5 style={{ fontWeight: 'bold' }}>Vehicle: {waybillData.vehicleDetails || "N/A"}</h5>
              </div>
              <div className="waybill-summary mb-4">
                <h5 className="summary-title" style={{ borderBottom: '2px solid #01902d', paddingBottom: '5px' }}>Logistics Details</h5>
                <table className="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td style={{ fontWeight: 'bold' }}>Logistics Provider</td>
                      <td>{waybillData.logisticsProvider || "N/A"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr />

              <div className="additional-details mb-4">
                <h5 className="summary-title" style={{ borderBottom: '2px solid #01902d', paddingBottom: '5px' }}>
                  Additional Details
                </h5>
                <div className="details-card">
                  <div className="detail-item">
                    <strong>Dispatch Time:</strong>
                    <span>{new Date(waybillData.dispatchTime).toLocaleString()}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Expected Delivery Time:</strong>
                    <span>{new Date(waybillData.expectedDeliveryTime).toLocaleString()}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Actual Delivery Time:</strong>
                    <span>{new Date(waybillData.actualDeliveryTime).toLocaleString()}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Departure Location:</strong>
                    <span>{waybillData.departureLocation || "N/A"}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Destination Location:</strong>
                    <span>{waybillData.destinationLocation || "N/A"}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Quantity:</strong>
                    <span>{waybillData.quantity} {waybillData.quantityUnit}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Status:</strong>
                    <span>{waybillData.status || "N/A"}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Transport Conditions:</strong>
                    <span>{waybillData.transportConditions || "N/A"}</span>
                  </div>
                  <div className="detail-item">
                    <strong>Comments:</strong>
                    <span>{waybillData.comments || "N/A"}</span>
                  </div>
                </div>
              </div>


              <hr />

              <div className="environmental-conditions mb-4">
                <h5 className="summary-title" style={{ borderBottom: '2px solid #01902d', paddingBottom: '5px' }}>
                  Environmental Conditions
                </h5>
                <div className="conditions-card">
                  <div className="condition-item">
                    <strong>Temperature:</strong>
                    <span>{waybillData.environmentalCondition?.temperature || 'N/A'}</span>
                  </div>
                  <div className="condition-item">
                    <strong>Weather Conditions:</strong>
                    <span>{waybillData.environmentalCondition?.weatherConditions || 'N/A'}</span>
                  </div>
                  <div className="condition-item">
                    <strong>Humidity:</strong>
                    <span>{waybillData.environmentalCondition?.humidity || 'N/A'}</span>
                  </div>
                  <div className="condition-item">
                    <strong>Wind Speed:</strong>
                    <span>{waybillData.environmentalCondition?.windSpeed || 'N/A'}</span>
                  </div>
                  <div className="condition-item">
                    <strong>Recorded Date:</strong>
                    <span>{new Date(waybillData.environmentalCondition?.recordedDate).toLocaleDateString() || 'N/A'}</span>
                  </div>
                  <div className="condition-item">
                    <strong>Effects:</strong>
                    <span>{waybillData.environmentalCondition?.effects || 'N/A'}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogisticsWaybill;
