import { useEffect, useState } from "react";
import { getGetWorkflowRecordActivityById } from "../../../api/workflowRecordActivity/getGetWorkflowRecordActivityById";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import ContractReview from "../../../components/Contracts/WorkflowForms/ContractReview";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import PreExportProcess from "../../../components/Contracts/WorkflowForms/PreExportProcess";

const PreExportProcessesIndex = () => {
  const { contractId, workflowRecordActivityId } = useParams();

  const { user } = useAuth();
  const navigate = useNavigate();

  const [showPreloader, setShowPreloader] = useState(false);

  const [theWorkflowRecordActivity, setTheWorkflowRecordActivity] = useState();
  
  useEffect(() => {
    getTheWorkflowRecordActivity();
    
    if(theWorkflowRecordActivity) {
      toast.warn("The Document is missing! Please, try it again. Let Admin know if it still persists.");
      navigate(-1);
    }
  }, []);

  const getTheWorkflowRecordActivity = async () => {
    setShowPreloader(true);
    const theActivity = await getGetWorkflowRecordActivityById(user.user.token, workflowRecordActivityId);
    setTheWorkflowRecordActivity(theActivity);
    setShowPreloader(false);

    if(!theActivity) {
      toast.warning("The Workflow Record Activity is not available.");
      navigate(-1);
      return;
    }
  }

  return (
    <>
      {/* Page wrapper start */}
      <div className="page-wrapper">
        {/* Sidebar wrapper start */}
        <SideBarWrapper />
        {/* Sidebar wrapper end */}

        {/* Page content start  */}
        <div className="page-content">
          {/* Header start */}
          <Navbar />
          {/* Header end */}

          {/* Page header start */}
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item">Contracts</li>
              <li className="breadcrumb-item">
                {theWorkflowRecordActivity && theWorkflowRecordActivity.workflowProcess
                  ? theWorkflowRecordActivity.workflowProcess.name.replace("Contract - ", "") : "--"}</li>
              <li className="breadcrumb-item active">
                {theWorkflowRecordActivity && theWorkflowRecordActivity.workflowStage
                  ? theWorkflowRecordActivity.workflowStage.name : "--"}
              </li>
            </ol>
            <ul className="app-actions"></ul>
          </div>
          {/* Page header end */}

          {/* Main container start */}
          <div className="main-container">
            <PreExportProcess  contractId={contractId} theWorkflowRecordActivity={theWorkflowRecordActivity}/>
          </div>
          {/* Main container end */}
        </div>
        {/* Page content end */}
      </div>
      {/* Page wrapper end */}
    </>
  );
};

export default PreExportProcessesIndex;
