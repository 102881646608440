import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import FieldForm from "../../../components/Field/FieldForm";
import Footer from "../../../layout/Footer";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getFarmById } from "../../../api/Farms/getFarmById";
import useAuth from "../../../hooks/useAuth";

const CreateField = () => {

    const { user } = useAuth();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const farmId = queryParams.get('farm'); // Retrieve the value of a specific query parameter

    const [farm, setFarm] = useState();

    useEffect(() => {   
        getFarmById(user.user.token, farmId).then(f => { setFarm(f); });
    }, []);
    return (
        <>


            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item">
                                <Link to={`/farms/farmer`}>Farm</Link>
                            </li>
                            <li className="breadcrumb-item">Field</li>
                            <li className="breadcrumb-item active">Create</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                        <FieldForm farm={farm} />
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer />

            </div>
            {/* Page wrapper end */}
        </>);
}

export default CreateField;