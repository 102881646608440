import SideBarWrapper from "../../components/common/SideBarWrapper";
import useAuth from "../../hooks/useAuth";
import Navbar from "../../layout/Navbar";
import AttentionRequiredContracts from "../../components/Contracts/AttentionRequiredContracts";

const BMRPool = () => {
  const { user } = useAuth();
  return (
    <>
      {/* Loading starts */}
      {/* <div id="loading-wrapper">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div> */}
      {/* Loading ends */}

      {/* Page wrapper start */}
      <div className="page-wrapper">
        {/* Sidebar wrapper start */}
        <SideBarWrapper />
        {/* Sidebar wrapper end */}

        {/* Page content start  */}
        <div className="page-content">
          {/* Header start */}
          <Navbar />
          {/* Header end */}

          {/* Page header start */}
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item">Contracts</li>
              <li className="breadcrumb-item active">Attention Required</li>
            </ol>
            <ul className="app-actions"></ul>
          </div>
          {/* Page header end */}

          {/* Main container start */}
          <div className="main-container">

            <AttentionRequiredContracts />
            
          </div>
          {/* Main container end */}
        </div>
        {/* Page content end */}
      </div>
      {/* Page wrapper end */}
    </>
  );
};

export default BMRPool;
