import SideBarWrapper from "../../../../components/common/SideBarWrapper";
import Navbar from "../../../../layout/Navbar";
import Footer from "../../../../layout/Footer";
import ManufacturingProcessForm from "../../../../components/ManufacturingRecords/ManufacturingProcessRecords/ManufacturingProcessForm";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { getManufacturingById } from "../../../../api/ManufacturingRecords/getManufacturingList";

const CreateManufacturingProcess = () => {

    const [manufacturing, setManufacturing] = useState();
    const { user } = useAuth();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const manufacturingRecordId = queryParams.get('manufacturingRecord'); // Retrieve the value of a specific query parameter


    useEffect(() => {
        getManufacturingById(user.user.token, manufacturingRecordId).then(f => { setManufacturing(f); });
    });

    return (
        <>

            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item">{manufacturing && manufacturing.code}</li>
                            <li className="breadcrumb-item">
                                <Link to={`/manufacturingrecord/edit/${manufacturing && manufacturing.id}`} >Manufacturing Processes</Link>
                            </li>
                            <li className="breadcrumb-item active">Create</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                        <ManufacturingProcessForm manufacturing={manufacturing} />
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer />

            </div>
            {/* Page wrapper end */}
        </>);
}

export default CreateManufacturingProcess;