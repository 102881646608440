import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { baseURL } from "../../utils/misc";
import useAuth from "../../hooks/useAuth";
import Select from "react-select";

const SelectCropToManufacturing = () => {
  const { user } = useAuth();
  const [cropList, setCropList] = useState([]);
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getSetupData();
  }, []);

  const getSetupData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch crops
    fetch(`${baseURL}/crops`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error('Error fetching Crops.');
          throw new Error('Error fetching Crops.');
        }
      })
      .then((data) => {
        setCropList(data);
      })
      .catch((error) => {
        toast.error(error.message || "Error fetching crops.");
      })
      .finally(() => { setShowPreloader(false); });
  };

  // crop list for react-select
  const cropOptions = cropList.map(crop => ({
    value: crop.id,
    label: `${crop.name} - ${crop.quantity} - ${crop.quantityUnit}`
  }));

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      navigate(`/manufacturingrecords/crop/${selectedOption.value}`);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Select Crop To Navigate To Manufacturing Records</div>
            </div>
            <div className="card-body pt-0">
              <div className="form-group row gutters">
                <label
                  htmlFor="cropSelection"
                  className="col-sm-2 col-form-label"
                >
                  Crop:
                </label>
                <div className="col-sm-10">
                  <Select
                    id="cropSelection"
                    options={cropOptions}
                    onChange={handleChange}
                    placeholder="Select Crop..."
                    isClearable
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default SelectCropToManufacturing;
