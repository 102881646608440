import { useEffect, useState } from "react";
import Preloader from "../../../components/common/Preloader";
import { getAllNextWorkflowStages } from "../../../api/WorkflowStage/getAllNextWorkflowStages";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import { useNavigate } from "react-router-dom";

const ReferencedFormsFooter = ({ WorkflowRecordActivity, submitFunction }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [nextWorkflowStagesList, setNextWorkflowStagesList] = useState([]);
    const [nextWorkflowStageId, setNextWorkflowStageId] = useState("");
    const [nextWorkflowStageLabel, setNextWorkflowStageLabel] = useState();
    const [instructions, setInstructions] = useState();
    const [showInstructions, setShowInstructions] = useState(false);

    useEffect(() => {
      if (WorkflowRecordActivity) {
          getTheAllNextWorkflowStages(WorkflowRecordActivity.workflowStageId);
      }
    }, []);

    useEffect(() => {
        // Set nextWorkflowStageId to the value of the first option
        if (nextWorkflowStagesList.length > 0) {
          setNextWorkflowStageId(nextWorkflowStagesList[0].id); 
        }
    }, [nextWorkflowStagesList]);

    // useEffect(() => {
    //   const selectElement = document.getElementById('nextWorkflowStageId');
    //   if (selectElement) {
    //     const options = selectElement.options;
    //     for (let i = 0; i < options.length; i++) {
    //       if (!options[i].innerHTML.includes('(Default)')) {
    //         options[i].classList.add('non-default-transition-option');
    //       }
    //     }
    //   }
    // }, []);

    const getTheAllNextWorkflowStages = async (workflowStageId) => {
        setShowPreloader(true);
        setNextWorkflowStagesList(await getAllNextWorkflowStages(user.user.token, workflowStageId));
        setShowPreloader(false);
    };

  const submitToNextStage = async (e) => {
    if (!nextWorkflowStageId) {
      toast.error("Please select the next workflow stage.");
      return;
    }

    var submitResult = await submitFunction(e);
    if(!submitResult) return; //Make sure the submission of form is successful

    if(nextWorkflowStageLabel && !nextWorkflowStageLabel.includes('(Default)')) {//Code should run only for next stage submission which is not default 
      const theVal = await submitRejectionInstructions();
      if(!theVal) return; //Don't continue the submission to the next stage if the instruction is not posted successfully
    }

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
    };

    try {
      setShowPreloader(true);
      const response = await fetch(`${baseURL}/workflowrecordactivities/${WorkflowRecordActivity.id}/workflowStage/${nextWorkflowStageId}`, requestOptions);

      if (response.ok) {
        toast.success("Record successfully set to next workflow stage.");
        navigate("/contracts/attention-required");
      } else {
        handleNotOkHttpResponse(response, navigate);
      }
    } catch (error) {
      toast.error("Error setting the next workflow stage!");
    } finally {
      setShowPreloader(false);
    }
  };

  const submitRejectionInstructions = async () => {
    if(!nextWorkflowStageLabel) return true;
    if(nextWorkflowStageLabel.includes('(Default)')) return true; //Code should only run for next stage submission which is not default 

    if(!instructions) {
      toast.warn("You have to enter the instruction if you are not submitting to the default stage!");
      return;
    }

    const data = {
      contractId: WorkflowRecordActivity.workflowRecord.contractId,
      sourceStageID: WorkflowRecordActivity.workflowStageId,
      targetStageID: nextWorkflowStageId,
      instructions
    };
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };

    setShowPreloader(true);
    return fetch(`${baseURL}/workflow-rejection-instructions`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error updating the Workflow Rejection Instructions record!");
        }
      })
      .then(async (data) => {
        setShowPreloader(false);
        //Very necessary part of code
        return true;
      })
      .catch((error) => {
        toast.error("Error updating the Workflow Rejection Instructions record!");
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handleNextWorkflowStageChange = (event) => {
    const selectedValue = event.target.value;
    setNextWorkflowStageId(selectedValue);
  
    const selectedOption = event.target.options[event.target.selectedIndex];
    setNextWorkflowStageLabel(selectedOption.innerHTML);
    if(!selectedOption){
      setShowInstructions(false);
    }
    else if (selectedOption.innerHTML.includes('(Default)')) {
      setInstructions("");
      setShowInstructions(false);
    } else {
      setShowInstructions(true);
    }
  };
  

  return (
    <>
      <Preloader show={showPreloader} />
      <div className="form-group row">
          <label className="col-sm-2 col-form-label" htmlFor="nextWorkflowStageId">Next Stage:</label>
          <div className="col-sm-10">
            <select
              id="nextWorkflowStageId"
              className="form-control form-control-lg mr-3"
              value={nextWorkflowStageId}
              onChange={handleNextWorkflowStageChange}
            >
              {nextWorkflowStagesList.map((theStage) => (
                <option
                  key={theStage.id}
                  value={theStage.id}
                  className={!theStage.name.includes('(Default)') ? 'non-default-transition-option': ''}
                >
                  {(theStage.label) ? theStage.label : theStage.name}
                </option>
              ))}
            </select>
          </div>
      </div>
      <div className="form-group row" hidden={!showInstructions}>
          <label className="col-sm-2 col-form-label" htmlFor="nextWorkflowStageId">Instructions/Reasons:</label>
          <div className="col-sm-10">
            <textarea
              id="comments"
              className="form-control form-control-lg mr-3"
              rows={7}
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
            ></textarea>
          </div>
      </div>
      <div className="row">
          <div className="col-6">
            <button type="button" onClick={submitFunction} className="btn btn-outline-primary">
              <i className="icon-save" />&nbsp; Save
            </button>
            </div>
          <div className="col-6">
            <button type="button" onClick={submitToNextStage} className="btn btn-primary mr-3" disabled={!nextWorkflowStageId}>
                <i className="icon-save" />&nbsp; Submit to Next Stage
              </button>
          </div>
      </div>
      <br />
    </>
  );
};

export default ReferencedFormsFooter;
