import { Navigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate,useLocation, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getFieldPreparationActivity, getFieldPreparationQuantityUnit } from "../../api/FieldPreparationRecords/getFieldPreparationList";
import EnvironmentalCondition from "../common/EnvironmentalCondition";

const FieldPreparationForm = ({ fieldPreparationRecords, crop }) => {
  const { user } = useAuth();
  const [date, setDate] = useState('');
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();

  const [fieldPreparationId, setFieldPreparationId] = useState(null);
  const [code, setCode] = useState('');

  const [preparationActivity, setPreparationActivity] = useState('');
  const [performedBy , setPerformedBy] = useState('');
  const [supervisedBy , setSupervisedBy] = useState('');
  const [quantityUsed, setQuantityUsed] = useState('');
  const [quantityUnit, setQuantityUnit] = useState('');
  const [areaCovered, setAreaCovered] = useState('');
  const [issuesIdentified, setIssuesIdentified] = useState('');
  const [correctiveActions, setCorrectiveActions] = useState('');
  const [notes, setNotes] = useState('');
  const [toolOrMaterialUsed, setToolOrMaterialUsed] = useState('');
  const [associatedCost, setAssociatedCost] = useState('');
  const [quantityUnitList, setQuantityUnitList] = useState([]);
  const [preparationActivityList, setPreparationActivityList] = useState([]);


  //env conditions data
  const [environmentalConditionId, setEnvironmentalConditionId] = useState('');
  const [environmentalCondition, setEnvironmentalCondition] = useState();


  useEffect(() => {
    getSetUpData();
  }, []);

  useEffect(() => {

    if (fieldPreparationId) {
      getFieldPreparationRecords(fieldPreparationId);
    }
  }, [fieldPreparationId])

  useEffect(() => {
    if (fieldPreparationRecords) {
      setFieldPreparationId(fieldPreparationRecords.id);
      setDate(fieldPreparationRecords.date);
      setPerformedBy(fieldPreparationRecords.performedBy);
      setSupervisedBy(fieldPreparationRecords.supervisedBy);
      setQuantityUsed(fieldPreparationRecords.quantityUsed);
      setQuantityUnit(fieldPreparationRecords.quantityUnit);
      setAreaCovered(fieldPreparationRecords.areaCovered);
      setPreparationActivity(fieldPreparationRecords.plantingMaterialForm);
      setQuantityUnit(fieldPreparationRecords.treatmentBeforePlanting);
      setIssuesIdentified(fieldPreparationRecords.issuesIdentified);
      setCorrectiveActions(fieldPreparationRecords.correctiveActions);
      setToolOrMaterialUsed(fieldPreparationRecords.toolOrMaterialUsed);
      setNotes(fieldPreparationRecords.notes);
      setAssociatedCost(fieldPreparationRecords.associatedCost);
      setCode(fieldPreparationRecords.code);

      if (fieldPreparationRecords.environmentalCondition) {
        setEnvironmentalConditionId(fieldPreparationRecords.environmentalCondition.id);
        setEnvironmentalCondition(fieldPreparationRecords.environmentalCondition);
      }

      // setUserId(userData.id);
    }
  }, [fieldPreparationRecords]);



  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    try {
      setShowPreloader(true);
      setQuantityUnitList(await getFieldPreparationQuantityUnit(user.user.token));
      setPreparationActivityList(await getFieldPreparationActivity(user.user.token));
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const getFieldPreparationRecords = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }
  
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);
  
    try {
      const response = await fetch(`${baseURL}/fieldpreparationrecords/${fieldPreparationId}`, requestOptions);
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response text:", errorText);
        throw new Error('Failed to fetch data');
      }
  
      const data = await response.json();
  
      setDate(data.date);
      setNotes(data.notes);
      setPreparationActivity(data.preparationActivity);
      setQuantityUsed(data.quantityUsed);
      setQuantityUnit(data.treatmentBeforePlanting);
      setAreaCovered(fieldPreparationRecords.areaCovered);
      setPerformedBy(data.performedBy);
      setSupervisedBy(data.supervisedBy);
      setIssuesIdentified(data.issuesIdentified);
      setCorrectiveActions(data.correctiveActions);
      setToolOrMaterialUsed(data.toolOrMaterialUsed);
      setAssociatedCost(fieldPreparationRecords.associatedCost);
      setCode(fieldPreparationRecords.code);
  
      if (data.environmentalCondition) {
        setEnvironmentalConditionId(data.environmentalCondition.id);
        setEnvironmentalCondition(data.environmentalCondition);
      }
    } catch (error) {
      toast.error('Error fetching field preparation records data.');
      console.error("Error fetching field preparation records data:", error);
    } finally {
      setShowPreloader(false);
    }
  };
  

  const validateFields = () => {
    let retVal = true;



    if (!date) {
      fieldWithIdRequired('date');
      toast.error("Planting Date is required.");
      retVal = false;
    }
    if (!preparationActivity) {
      fieldWithIdRequired('preparationActivity');
      toast.error("Preparation Activity is required.");
      retVal = false;
    }

    if (!performedBy) {
      fieldWithIdRequired('performedBy');
      toast.error("Performed By is required.");
      retVal = false;
    }

    if (!toolOrMaterialUsed) {
      fieldWithIdRequired('toolOrMaterialUsed');
      toast.error("Tool Or Material Used is required.");
      retVal = false;
    }

    // environmental conditions data

    if (!environmentalCondition.effects) {
      fieldWithIdRequired('effects');
      toast.error("Effects field is required.");
      retVal = false;
    }

    if (!environmentalCondition.recordedDate) {
      fieldWithIdRequired('recordedDate');
      toast.error("Recorded Date is required.");
      retVal = false;
    }

    if (!environmentalCondition.temperature) {
      fieldWithIdRequired('temperature');
      toast.error("Temperature is required.");
      retVal = false;
    }

    if (!environmentalCondition.weatherConditions) {
      fieldWithIdRequired('weatherConditions');
      toast.error("Whether Conditions is required.");
      retVal = false;
    }

    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      cropId:crop.id,
      date: date,
      notes: notes,
      preparationActivity: preparationActivity,
      supervisedBy: supervisedBy,
      performedBy: performedBy,
      correctiveActions: correctiveActions,
      issuesIdentified: issuesIdentified,
      quantityUsed: quantityUsed,
      quantityUnit: quantityUnit,
      areaCovered: areaCovered,
      toolOrMaterialUsed:toolOrMaterialUsed,
      environmentalCondition,
      associatedCost,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/fieldpreparationrecords`, requestOptions);

      if (response.ok) {
        toast.success("The Field Preparation Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/fieldpreparationrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the field preparation record.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: fieldPreparationId,
      cropId:crop.id,
      date: date,
      notes: notes,
      preparationActivity: preparationActivity,
      supervisedBy: supervisedBy,
      performedBy: performedBy,
      correctiveActions: correctiveActions,
      issuesIdentified: issuesIdentified,
      quantityUsed: quantityUsed,
      quantityUnit:quantityUnit,
      areaCovered: areaCovered,
      toolOrMaterialUsed:toolOrMaterialUsed,
      treatmentBeforePlanting: quantityUnit,
      environmentalConditionId,
      environmentalCondition,
      associatedCost,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/fieldpreparationrecords/${fieldPreparationId}`, requestOptions);

      if (response.ok) {
        toast.success("The field preparation record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/fieldpreparationrecords/crop/${crop.id}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating field preparation record.");
    } finally {
      setShowPreloader(false);
    }
  };


  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Field Preparation Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/fieldpreparationrecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>

            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">

                {fieldPreparationId && 
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="name">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={code ? code : '--'}
                        readOnly
                      />
                    </div>
                  </div>
                }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Field Preparation Date *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="name"
                      value={isoToDate(date)}
                      onChange={(e) => {setDate(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Preparation Activity *</label>
                    <select
                      className="form-control form-control-lg"
                      id="preparationActivity"
                      value={preparationActivity}
                      onChange={(e) => { setPreparationActivity(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Preparation Activity Type...</option>
                      {preparationActivityList && preparationActivityList.map((activityList) => (
                        <option key={activityList} value={activityList}>
                          {activityList}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Tool Or Material Used *</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      placeholder="Enter the Tool or Material Used"
                      id="toolOrMaterialUsed"
                      value={toolOrMaterialUsed}
                      onChange={(e) => {setToolOrMaterialUsed(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityUsed">Quantity Used</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter the Quantity Used"
                      id="quantityUsed"
                      value={quantityUsed}
                      onChange={(e) => setQuantityUsed(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="quantityUnit">Quantity Unit</label>
                    <select
                      className="form-control form-control-lg"
                      id="quantityUnit"
                      value={quantityUnit}
                      onChange={(e) => setQuantityUnit(e.target.value)}
                    >
                      <option value="">Select Quantity Unit...</option>
                      {quantityUnitList && quantityUnitList.map((quantityUnit) => (
                        <option key={quantityUnit} value={quantityUnit}>
                          {quantityUnit}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="areaCovered">Area Covered <em>(in Acres)</em></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter the Area Covered"
                      id="areaCovered"
                      value={areaCovered}
                      onChange={(e) => setAreaCovered(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="performedBy">Performed By *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="appliedBy"
                      placeholder="Enter the performed by..."
                      value={performedBy}
                      onChange={(e) => {setPerformedBy(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="supervisedBy">Supervised By</label>
                    <input
                      type="text"
                      className="form-control"
                      id="supervisedBy"
                      placeholder="Enter the supervised by..."
                      value={supervisedBy}
                      onChange={(e) => setSupervisedBy(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="issuesIdentified">Issues Identified</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="issuesIdentified"
                      placeholder="Enter the issues identified..."
                      value={issuesIdentified}
                      onChange={(e) => setIssuesIdentified(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="correctiveActions">Corrective Actions</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="correctiveActions"
                      placeholder="Enter the corrective actions..."
                      value={correctiveActions}
                      onChange={(e) => setCorrectiveActions(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="notes">Notes</label>
                    <textarea
                      rows="3"
                      className="form-control"
                      id="notes"
                      placeholder="Enter the notes..."
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="areaCovered">Associated Cost <em>(in GHS)</em></label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter the Associated Cost"
                      id="associatedCost"
                      value={associatedCost}
                      onChange={(e) => setAssociatedCost(e.target.value)}
                    />
                  </div>
                </div>


                  <br />

              </div>

                <EnvironmentalCondition environmentalCondition={environmentalCondition} setEnvironmentalCondition={setEnvironmentalCondition} />

            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(fieldPreparationId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Row end */}
      </div>
    </>
  );
}

export default FieldPreparationForm;