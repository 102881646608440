import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { baseURL } from "../../../utils/misc";
import { attachActors, getUserFullName, getUserFullNameByUserId, isoToDateTime, isoToTime } from "../../../utils/helpers";
import Preloader from "../../common/Preloader";
import { isSinglePageActive } from "../../../utils/daynamicNavigation";

const Invitations = ({}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  const [items, setItems] = useState([]);

  
  const $ = require("jquery");

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      //Initialize DataTable when users are available
      $(document).ready(function () {
        const dataTable = $("#data-table").DataTable();

        // Destroy DataTable if it exists
        if (dataTable) {
          dataTable.destroy();
        }

        $("#data-table").DataTable({
          // Add any required DataTable options here
        });
      });
    }
  }, [items]);
  
  const getData = async () => {
    setShowPreloader(true);
    if (!(user && user.user.token)) {
      navigate("/login");
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }
      try {
      const response = await fetch(`${baseURL}/invitations`, {
        headers: {
          Authorization: `Bearer ${user.user.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setItems(await attachActors(user.user.token, data, "createdBy", "createdByUser"));
      } else {
        toast.warn("Error fetching Invitations!");
      }
    } catch (error) {
      toast.error("Error fetching Invitations!");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
              <div className="card-header">
                <div className="card-title">List of Invitations Sent</div>
                <Link to={`/registration/invitations/create`} className="btn btn-primary float-end">
                  Send a New Invitation
                </Link>
              </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="table-responsive">
                    <table id="data-table" className="table custom-table">
                          <thead className="thead-dark">
                              <tr>
                                  <th>Telephone</th>
                                  <th>Email</th>
                                  <th>Target</th>
                                  <th>Created Date</th>
                                  <th>Expiry</th>
                                  <th>Sent By</th>
                                  <th>Status</th>
                                  <th>Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              {items && items.length > 0 ? items.map((item, index) => (
                                <>
                                  <tr key={index}> 
                                    <td>{item.telephone}</td>
                                    <td>{item.email}</td>
                                    <td>{item.target}</td>
                                    <td>{isoToDateTime(item.createdDate)}</td>
                                    <td>{isoToDateTime(item.expiry)}</td>
                                    <td>{item.createdByUser}</td>
                                    <td>{item.status}</td>
                                    <td className="">
                                      <ul>
                                        <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                          {/* <div className="dropdown">
                                            <Link
                                              className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                              role="button"
                                              href="#"
                                              id={"dropdownMenuLink" + item.id}
                                              data-bs-toggle="dropdown"
                                            >
                                              Actions <i className="fa fa-caret-down"></i>
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby={"dropdownMenuLink" + item.id}>
                                                <li key={item.id + '-' + ''}>
                                                  <Link
                                                    to={"/"}
                                                    className="dropdown-item"
                                                    style={isSinglePageActive("/", navigate.pathname) ? { color: "#ff5a5f" } : undefined}
                                                  >
                                                    <i className="flaticon-transfer-1"></i>&nbsp;&nbsp;The action
                                                  </Link>
                                                </li>
                                            </ul>
                                          </div> */}
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                </>
                              ))  : (<tr><td colSpan="7" className="text-center"><em>No data available!</em></td></tr>)}
                          </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
};

export default Invitations;
