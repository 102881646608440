import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse, hasAdminRole } from "../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { isSinglePageActive } from "../../utils/daynamicNavigation";
import Preloader from "../common/Preloader";
import { isoToDate } from "../../utils/helpers";
import { Button, Modal } from "react-bootstrap";
import TransferForm from "./TransferForm";
import TransferFormBasic from "./TransferFormBasic";

function TransferTable({ transferRecords, transferRecord, crop, setTransferRecord,
    handleModalShow, handleSubmit, showModal, handleModalClose, transferId, allowModalEdit }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const isAdmin = user && user.user && hasAdminRole(user.user.roles);

    const profileMenuItems = [
        {
            id: 1,
            name: "View",
            icon: "icon-eye1",
            onClick: (process) => {
                handleShowViewModal(process);
            },
            authEdit: "admin",
        },
        {
            id: 2,
            name: "Edit",
            icon: "icon-edit1",
            authType: 'admin',
            onClick: (record) => {
                if (allowModalEdit) {
                    handleModalShow(record, true); // Open modal for editing
                } else {
                    // Redirect to edit page if modal edit is not allowed
                    window.location.href = `/transferrecord/edit/${record.id}`;
                }
            },
        },
        {
            id: 3,
            name: "Delete",
            icon: "icon-delete",
            onClick: (process) => {
                handleShowDeleteModal(process);
            },
            authType: 'admin',
        }
    ];

    // Define the path pattern for user profiles
    const $ = require("jquery");

    useEffect(() => {
        if (transferRecords && transferRecords.length > 0) {
            //Initialize DataTable when users are available
            $(document).ready(function () {
                const dataTable = $("#data-table").DataTable();

                // Destroy DataTable if it exists
                if (dataTable) {
                    dataTable.destroy();
                }

                $("#data-table").DataTable({
                    // Add any required DataTable options here
                    columnDefs: [
                        { width: "12%", targets: 0 },
                        { width: "12%", targets: 2 }
                    ]
                });
            });
        }
    }, [transferRecords, $]);

    const handleShowViewModal = (record) => {
        setSelectedRecord(record);
        setShowViewModal(true);
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setSelectedRecord(null);
    };

    const handleShowDeleteModal = (record) => {
        setSelectedRecord(record);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedRecord(null);
    };

    const handleRemoveTransfer = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }

        setShowPreloader(true);
        try {
            if (!selectedRecord) {
                console.error('No selected Record');
                return;
            }

            const requestOptions = {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow",
            };

            const response = await fetch(`${baseURL}/transferrecords/${selectedRecord.id}`, requestOptions);
            if (response.ok) {
                toast.success("Transfer Record removed successfully");
                handleCloseDeleteModal();
                setShowPreloader(true);
                window.location.reload();
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on removing Transfer Record:", error.message);
        } finally {
            setShowPreloader(false);
        }
    };


    return (
        <>
            <Preloader show={showPreloader} />
            <div className="card-body pt-0">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="table-responsive">
                            <table id="data-table" className="table custom-table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Code</th>
                                        <th>GIAI</th>
                                        <th>Date</th>
                                        <th>Transport Method</th>
                                        <th>Transporter Name</th>
                                        <th>Transporter Contact</th>
                                        <th>Vehicle Details</th>
                                        <th>Destination</th>
                                        <th>Origin</th>
                                        <th>Quantity</th>
                                        <th><div className="float-end">Actions</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transferRecords && transferRecords.length > 0 ? transferRecords.map((transferRecord, index) => (
                                        <tr key={index}>
                                            <td>{transferRecord.code ? transferRecord.code : '--'}</td>
                                            <td>{transferRecord.giai ? transferRecord.giai : '--'}</td>
                                            <td>{isoToDate(transferRecord.date)}</td>
                                            <td>{transferRecord.transportMethod}</td>
                                            <td>{transferRecord.transporterName}</td>
                                            <td>{transferRecord.transporterContact}</td>
                                            <td>{transferRecord.vehicleDetails}</td>
                                            <td>{transferRecord.destination}</td>
                                            <td>{transferRecord.origin}</td>
                                            <td>{transferRecord.quantity} {transferRecord.quantityUnit}</td>
                                            <td className="">
                                                <ul>
                                                    <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                                        <div className="dropdown">
                                                            <Link
                                                                className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                                                role="button"
                                                                to="#"
                                                                id={"dropdownMenuLink" + transferRecord.id}
                                                                data-bs-toggle="dropdown"
                                                            >
                                                                Actions <i className="fa fa-caret-down"></i>
                                                            </Link>
                                                            <ul
                                                                className="dropdown-menu"
                                                                aria-labelledby={"dropdownMenuLink" + transferRecord.id}
                                                            >
                                                                {profileMenuItems
                                                                    .filter(item => !item.authType || (item.authType === "admin" && isAdmin)) // Filter items based on role
                                                                    .map(actionMenuItem => (
                                                                        <li key={transferRecord.id + '-' + actionMenuItem.id}>
                                                                            {actionMenuItem.onClick ? (
                                                                                <Link
                                                                                    to="#"
                                                                                    className="dropdown-item"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault(); // Prevent default anchor behavior
                                                                                        actionMenuItem.onClick(transferRecord);
                                                                                    }}
                                                                                    style={
                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                            ? { color: "#ff5a5f" }
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                </Link>
                                                                            ) : (
                                                                                <Link
                                                                                    to={actionMenuItem.routerPath + "/" + transferRecord.id} // Changed href to to
                                                                                    className="dropdown-item"
                                                                                    style={
                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                            ? { color: "#ff5a5f" }
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                </Link>
                                                                            )}
                                                                        </li>
                                                                    ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )) : (<tr><td colSpan="7" className="text-center"><em>No data available!</em></td></tr>)}
                                </tbody>
                            </table>

                            {/* Update Modal */}
                            <Modal show={showUpdateModal} onHide={(/*handleCloseDeleteModal*/ () => { })}>
                                <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                    <Modal.Title style={{ color: 'white' }}>Edit Transfer Record</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedRecord ? (
                                        <TransferForm crop={selectedRecord.crop} transferRecords={selectedRecord} />
                                    ) : (
                                        <p>No record selected.</p>
                                    )}
                                </Modal.Body>
                                <Modal.Footer style={{ borderTop: 'none' }}>
                                    <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleCloseDeleteModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveTransfer}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Delete Modal */}
                            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                                <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                    <Modal.Title style={{ color: 'white' }}>Delete Transfer Record</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="mb-4">
                                        Are you sure you want to delete this transfer record?
                                    </p>
                                </Modal.Body>
                                <Modal.Footer style={{ borderTop: 'none' }}>
                                    <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleCloseDeleteModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveTransfer}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* View Modal */}
                            <Modal size="lg" show={showViewModal} onHide={handleCloseViewModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Transfer Record Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedRecord ? (
                                        <div className="formView">
                                            <p><strong>Code:</strong> {selectedRecord.code ? selectedRecord.code : "n/a"}</p>
                                            <p><strong>Date:</strong> {isoToDate(selectedRecord.date)}</p>
                                            <p><strong>GIAI:</strong> {selectedRecord.giai ? selectedRecord.giai : "n/a"}</p>
                                            <p><strong>Origin:</strong> {selectedRecord.origin}</p>
                                            <p><strong>Destination:</strong> {selectedRecord.destination}</p>
                                            <p><strong>Quantity:</strong> {selectedRecord.quantity} {selectedRecord.quantityUnit}</p>
                                            <p><strong>Transport Method:</strong> {selectedRecord.transportMethod}</p>
                                            <p><strong>Transporter Name:</strong> {selectedRecord.transporterName}</p>
                                            <p><strong>Transporter Contact:</strong> {selectedRecord.transporterContact}</p>
                                            <p><strong>Vehicle Details:</strong> {selectedRecord.vehicleDetails}</p>
                                            <p><strong>Remarks:</strong> {selectedRecord.remarks}</p>

                                            {/* Environmental Conditions */}
                                            <hr />
                                            <h4>Environmental Conditions</h4>
                                            {selectedRecord.environmentalCondition ? (
                                                <div>
                                                    <p><strong>Temperature:</strong> {selectedRecord.environmentalCondition.temperature}</p>
                                                    <p><strong>Weather Conditions:</strong> {selectedRecord.environmentalCondition.weatherConditions}</p>
                                                    <p><strong>Humidity:</strong> {selectedRecord.environmentalCondition.humidity ? selectedRecord.environmentalCondition.humidity : "n/a"}</p>
                                                    <p><strong>Rainfall:</strong> {selectedRecord.environmentalCondition.rainfall ? selectedRecord.environmentalCondition.rainfall : "n/a"}</p>
                                                    <p><strong>Wind Speed:</strong> {selectedRecord.environmentalCondition.windSpeed ? selectedRecord.environmentalCondition.windSpeed : "n/a"}</p>
                                                    <p><strong>Recorded Date:</strong> {isoToDate(selectedRecord.environmentalCondition.recordedDate)}</p>
                                                    <p><strong>Effects:</strong> {selectedRecord.environmentalCondition.effects}</p>
                                                </div>
                                            ) : (
                                                <p>No environmental conditions data available.</p>
                                            )}
                                        </div>
                                    ) : (
                                        <p>No record selected.</p>
                                    )}

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseViewModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal size="lg" show={showModal} onHide={handleModalClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{transferId ? "Edit Transfer Record" : "Add Transfer Record"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <TransferFormBasic
                                        crop={crop}
                                        transferRecord={transferRecord}
                                        setTransferRecord={setTransferRecord}
                                        handleSubmit={handleSubmit}
                                    />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TransferTable;