import React, {
  useEffect,
  useState,
} from 'react';

import { Navbar } from 'react-bootstrap';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import SideBarWrapper from '../../components/common/SideBarWrapper';
import { baseURL } from '../../utils/misc';
import useAuth from '../../hooks/useAuth';

const AddAndEditWorkFLow = ({workflowId}) => {
  const {user} = useAuth();
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { id: routeId } = useParams();
  const navigate = useNavigate();
  const token = user && user.user ? user.user.token : null;

  useEffect(() => {
    if (!token) {
      alert("not authorized");
    }

    setId(routeId || null); // Initialize id to null if not found

    if (routeId) {
      fetchDataById(routeId);
    }
  }, [token, routeId, workflowId]);

  const fetchDataById = async (id) => {
    try {
      const response = await fetch(`${baseURL}/workflows/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setName(data.name);
        setDescription(data.description);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddAndEditWorkFLow = async (e) => {
    e.preventDefault();

    try {
      const AddAndEditWorkFLowData = {
        id,
        name,
        description,
      };

      const requestOptions = {
        method: id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(AddAndEditWorkFLowData),
      };

      const url = id ? `${baseURL}/workflows/${id}` : `${baseURL}/workflows`;

      const response = await fetch(url, requestOptions);

      if (response.ok) {
        navigate("/workflows");
        toast.success("Workflow and features have been successfully created.");
        console.log(`Workflow data ${id ? "updated" : "saved"} successfully.`);
      } else {
        console.error(
          `Error ${id ? "updating" : "saving"} WorkFLow data:`,
          response.statusText
        );
        console.log("Response:", await response.json());
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <SideBarWrapper />
        <div className="page-content">
          <Navbar />
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item">WorkFLow</li>
              <li className="breadcrumb-item active">
                {routeId ? "Update" : "Create"}
              </li>
            </ol>
            <ul className="app-actions"></ul>
          </div>
          {/* Page header end */}

          {/* Main container start */}
          <div className="main-container">
            {/* Row start */}
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">New WorkFLow</div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                        <div className="form-group">
                          <label htmlFor="productCode">Flow Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="productCode"
                            placeholder="flow name..."
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                        <div className="form-group">
                          <label htmlFor="fillVolume">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fillVolume"
                            placeholder="description..."
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                        <div className="float-end">
                          <button
                            type="button"
                            className="btn btn-primary float-end"
                            onClick={handleAddAndEditWorkFLow}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Row end */}
          </div>
          {/* Main container end */}
        </div>
        {/* Page content end */}
      </div>
      {/* Page wrapper end */}
    </>
  );
};

export default AddAndEditWorkFLow;
