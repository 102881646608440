import { getFullBuyerName, getFullCropName } from "../../../utils/helpers";

const ReferencedFormContractInfo = ({ theWorkflowRecordActivity }) => {
  return (
    <>
      {theWorkflowRecordActivity && (
        <>
          <div className="p-2 workflow-stage-product-info">
            <div className="mt-2 mb-1">
              <strong>CONTRACT: </strong>{theWorkflowRecordActivity ? `${theWorkflowRecordActivity.workflowRecord.contract.contractCode}` : ""}
            </div>
            <div className="mt-2 mb-1">
              <strong>BUYER: </strong>{theWorkflowRecordActivity ? getFullBuyerName(theWorkflowRecordActivity.workflowRecord.contract.buyer) : ""}
            </div>
            <div className="mt-2 mb-1">
              <strong>CROP #: </strong>{theWorkflowRecordActivity ? getFullCropName(theWorkflowRecordActivity.workflowRecord.contract.crop) : ""}
            </div>
          </div>
          <hr />
        </>
      )}
    </>
  );
};

export default ReferencedFormContractInfo;
