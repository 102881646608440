import { baseURL } from "../../utils/misc";


 export const getAdminDashTopCards = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/home/admin-dash-top-cards`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Admin Dashboard Top Cards!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};
