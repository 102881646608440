import {
  useRef,
  useState,
} from 'react';

import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';
import { baseURL, getTheHttpResponseValue } from '../../../utils/misc';
import Footer from '../../../layout/Footer';
import { runningCompany } from '../../../appSetup';
import { fieldRequired } from '../../../utils/helpers';
import Preloader from '../../../components/common/Preloader';
import { toast } from 'react-toastify';

function ResetPassword() {
  const { dispatch } = useAuth();
  const { username, resetCode } = useParams();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [information, setInformation] = useState('');

  const [showPreloader, setShowPreloader] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();


    // Client-side validation
    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }

    try {
      setShowPreloader(true);
      setLoading(true);

      const apiUrl = `${baseURL}/Users/updatepassword/${username}`;

      console.log('PATCH Request URL:', apiUrl);

      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
          passwordResetCode: resetCode,
        }),
      });

      if (response.ok) {
        toast.success("You Password is Successfully Updated.");
        navigate('/users');
      } else {
        const responseMessage = await getTheHttpResponseValue(response);
        if(responseMessage) {
          setInformation(null);
          setError(responseMessage);
        }
        else {
          setInformation(null);
          setError('Password update failed');
        }
      }
    } catch (error) {
      console.error('Error occurred during password update:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setShowPreloader(false);
      setLoading(false);
    }
  };


  return (
    <div className="container">
      <Preloader show={showPreloader} />
      <form onSubmit={handleSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
            <div className="login-screen">
              <div className="login-box">
                <Link to="#" className="login-logo">
                  <img src={`${window.location.origin}/assets/img/company_logos/${runningCompany.logo}`} alt={`${runningCompany.name} Logo`} style={{ display: 'block', margin: 'auto' }} />
                </Link>
                <h5>Reset your password!</h5>
                <div className="form-group">
                  <label htmlFor="resetCode">Username: {username}</label>
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password:</label>
                  <input type="password" id="password" value={password} onChange={(e) => { setPassword(e.target.value); fieldRequired(e); }} className="form-control" placeholder="Password" required />
                  <div className="invalid-feedback">This is required</div>
                </div>
                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password:</label>
                  <input type="password" id="confirmPassword" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); fieldRequired(e); }} className="form-control" placeholder="Confirm Password" required />
                  <div className="invalid-feedback">This is required</div>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                {information && <div className='alert alert-info'>{information}</div>}
                <div className="actions mt-4">
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
                <br />
                <Footer/>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
