import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { getBMR_BMRAttachmentsByBMRId } from "../../../api/BMRAttachments/getBMR_BMRAttachmentsByBMRId";
import Preloader from "../../../components/common/Preloader";
import { saveAs } from "file-saver";

const AttachmentsFormView = ({ bmr }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  const [id, setId] = useState(null);
  const [autoclaveGraph, setAutoclaveGraph] = useState(null);
  const [certificateOfAnalysis, setCertificateOfAnalysis] = useState(null);

  const [autoclaveGraphFilename, setAutoclaveGraphFilename] = useState("");
  const [certificateOfAnalysisFilename, setCertificateOfAnalysisFilename] = useState("");

  useEffect(() => {
    if (bmr) {
      getData();
    }
  }, [bmr]);

  const getData = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    try {
      setShowPreloader(true);
      const theRecord = await getBMR_BMRAttachmentsByBMRId(user.user.token, bmr.id);
      if (theRecord) {
        setId(theRecord.id);
        setAutoclaveGraph(theRecord.autoclaveGraph);
        setCertificateOfAnalysis(theRecord.certificateOfAnalysis);

        // Extract filenames from URLs
        setAutoclaveGraphFilename(theRecord.autoclaveGraph ? theRecord.autoclaveGraph.split('/').pop() : "");
        setCertificateOfAnalysisFilename(theRecord.certificateOfAnalysis ? theRecord.certificateOfAnalysis.split('/').pop() : "");
      } else {
        toast.info("There is no BMR Attachment here !")
      }
      console.log("bmr attachment", theRecord);
    } catch (error) {
      console.error("Failed to fetch BMR attachments", error);
      toast.error("Failed to fetch BMR attachments.");
    } finally {
      setShowPreloader(false);
    }
  };

  const downloadFile = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      let newFilename = prompt("Enter a new filename", filename);
      if (newFilename) {
        if (!newFilename.includes('.')) {
          // Adding original file extension if not provided by user
          const fileExtension = filename.split('.').pop();
          newFilename += `.${fileExtension}`;
        }
        saveAs(blob, newFilename);
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
      toast.error('Failed to download the file.');
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-body pt-0">
              {/* <ReferencedFormsHeader theWorkflowRecordActivity={theWorkflowRecordActivity} /> */}
              <div className="row gutters">
                <div className="col-xl-6">
                  <div className="form-group">
                    <label htmlFor="autoclaveGraph">Auto Graph or Chart</label>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="autoclaveGraph"
                        title={autoclaveGraphFilename} // Show filename as tooltip
                        disabled // Prevent modification
                      />
                      {autoclaveGraph && (
                        <>
                          <Link to={autoclaveGraph} target="_blank" className="input-group-text">
                            <span className="icon-eye1"></span>
                          </Link>
                          <button
                            type="button"
                            className="input-group-text"
                            onClick={() => downloadFile(autoclaveGraph, autoclaveGraphFilename)}
                          >
                            <span className="icon-download"></span>
                          </button>
                        </>
                      )}
                    </div>
                    {autoclaveGraphFilename && (
                      <em className="form-text text-muted">
                        <b>Current file: {autoclaveGraphFilename}</b>
                      </em>
                    )}
                  </div>
                </div>
              </div>
              <div className="row gutters">
                <div className="col-xl-6">
                  <div className="form-group">
                    <label htmlFor="certificateOfAnalysis">Certificate of Analysis</label>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="certificateOfAnalysis"
                        title={certificateOfAnalysisFilename} // Show filename as tooltip
                        disabled // Prevent modification
                      />
                      {certificateOfAnalysis && (
                        <>
                          <Link to={certificateOfAnalysis} target="_blank" className="input-group-text">
                            <span className="icon-eye1"></span>
                          </Link>
                          <button
                            type="button"
                            className="input-group-text"
                            onClick={() => downloadFile(certificateOfAnalysis, certificateOfAnalysisFilename)}
                          >
                            <span className="icon-download"></span>
                          </button>
                        </>
                      )}
                    </div>
                    {certificateOfAnalysisFilename && (
                      <em className="form-text text-muted">
                        <b>Current file: {certificateOfAnalysisFilename}</b>
                      </em>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
};

export default AttachmentsFormView;
