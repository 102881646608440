import { Navigate } from "react-router";
import useAuth from "../../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation,useNavigate } from 'react-router-dom';
import Preloader from "../../../components/common/Preloader";

const WorkFlowProcessForm = ({ workflowData }) => {
  const { user } = useAuth();
  const [workFlowList, setWorkflowList] = useState([]);
  const [workflowID, setWorkflowID] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [order, setOrder] = useState('');
  const [workflowprocessId, setWorkFlowProcessId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const value = queryParams.get('workflow'); // Retrieve the value of a specific query parameter  
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getWorkFlowData();

    if (workflowprocessId) {
      getWorkFlowProcessData(workflowprocessId);
    }
  }, [workflowprocessId])


  useEffect(() => {
    if (value) {
      setWorkflowID(value);
    }
  }, [value]);



  useEffect(() => {
    if (workflowData) {
      setWorkflowID(workflowData.workflowID);
      setName(workflowData.name);
      setDescription(workflowData.description);
      setOrder(workflowData.order);
      setWorkFlowProcessId(workflowData.id);
    }
  }, [workflowData]);


  const getWorkFlowData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);
    // Fetch user data when the component mounts
    fetch(`${baseURL}/workflows`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error('Error fetching users')
        }
      })
      .then((data) => {
        // Set the useState valuations with the fetched data
        setWorkflowList(data);
      })
      .catch((error) => {
        toast.error("Error fetching BMR Product Types.");
      })
      .finally (()=>{
        setShowPreloader(false);
      });
  };

  const getWorkFlowProcessData = async (workflowprocessId) => {

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);
    // Fetch service data when the component mounts
    fetch(`${baseURL}/workflowprocesses/${workflowprocessId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setName(data.name);
        setWorkflowID(data.workflowID);
        setDescription(data.description);
        setOrder(data.order);
      })
      .catch((error) => {
        toast.error('Error fetching service data.');
        console.error("Error fetching service data:", error);
      })
      .finally (()=>{
        setShowPreloader(false);
      });
  };


  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    // Basic field validations
    if ( !name || !description || !order) {
      toast.error("All fields are required. Please fill in all the required fields.");
      return;
    }

    const data = {
      workflowID: workflowID,
      name: name,
      description: description,
      order: order,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/workflowprocesses`, requestOptions);

      if (response.ok) {
        toast.success("The Workflow Process has been successfully initiated.");
        setShowPreloader(true);
        navigate(`/workflowprocesses/workflow/${workflowID}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error initiating the workflow process.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    // Basic field validations
    if ( !name || !description || !order) {
      toast.error("All fields are required. Please fill in all the required fields.");
      return;
    }

    const data = {
      id: workflowprocessId,
      workflowID: workflowID,
      name: name,
      description: description,
      order: order,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/workflowprocesses/${workflowprocessId}`, requestOptions);

      if (response.ok) {
        toast.success("The BMR has been successfully updated.");
        setShowPreloader(true);
        navigate(`/workflowprocesses/workflow/${workflowID}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error initiating the BMR.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">WorkFlowProcess</div>
            </div>
            <div className="card-body pt-0">
              <div className="form-group row gutters">
                <label
                  htmlFor="workflowID"
                  className="col-sm-2 col-form-label"
                >
                  WorkFLow Type:
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="workflowID"
                    value={workFlowList.find(workflow => workflow.id === workflowID)?.name || ''}
                    readOnly
                  />
                  {/* <select
                    id="workflowID"
                    className="form-control form-control-lg"
                    value={workflowID}
                    onChange={(e) => setWorkflowID(e.target.value)}
                  >
                    <option value="">Select WorkFlow Type...</option>
                    {workFlowList.map((workFlowStageType) => (
                      <option key={workFlowStageType.id} value={workFlowStageType.id}>
                        {workFlowStageType.name}
                      </option>
                    ))}
                  </select> */}
                </div>
              </div>
              {/* <hr /> */}
              <div className="row gutters">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter the Name..."
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="order">Order</label>
                    <input
                      type="number"
                      className="form-control"
                      id="order"
                      placeholder="Enter the Order..."
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="fillVolume">Description</label>
                    <textarea
                      rows="7"
                      className="form-control"
                      id="description"
                      placeholder="Enter the Description.."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    >
                    </textarea>
                  </div>
                </div>

              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(workflowprocessId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default WorkFlowProcessForm;