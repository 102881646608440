import { baseURL } from "../../utils/misc";

 export const getFarmById = async (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/farms/${id}`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching the farm!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};