import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getDocumentationOptions, getLogisticsQuantityUnit, getLogisticsStatuses, getTransportModes } from "../../api/LogisticsRecords/getLogisticsList";
import FileInput from "../common/FileInput";
import EnvironmentalConditionNew from "../common/EnvironmentalConditionNew";

const LogisticsFormBasic = ({ logisticsRecord, setLogisticsRecord, handleSubmit }) => {
  const { user } = useAuth();

  const [quantityUnitList, setQuantityUnitList] = useState([]);
  const [transportModeList, setTransportModeList] = useState([]);
  const [documentationOptionsList, setDocumentationOptionList] = useState([]);
  const [logisticsStatusList, setLogisticsStatusList] = useState([]);

  //env conditions data
  const [environmentalCondition, setEnvironmentalCondition] = useState('');

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getSetUpData();
  }, []);

  useEffect(() => {
    if (logisticsRecord) {
      if (logisticsRecord.environmentalCondition) {
        setEnvironmentalCondition(logisticsRecord.environmentalCondition);
      }
    }
  }, [logisticsRecord]);

  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    try {
      setShowPreloader(true);
      setQuantityUnitList(await getLogisticsQuantityUnit(user.user.token));
      setTransportModeList(await getTransportModes(user.user.token));
      setDocumentationOptionList(await getDocumentationOptions(user.user.token));
      setLogisticsStatusList(await getLogisticsStatuses(user.user.token));
    } catch { } finally {
      setShowPreloader(false);
    }
  };

  const validateFields = () => {
    let retVal = true;

    if (!logisticsRecord.date) {
      fieldWithIdRequired('date');
      toast.error("Date is required.");
      retVal = false;
    }

    if (!logisticsRecord.expectedDeliveryTime) {
      fieldWithIdRequired('expectedDeliveryTime');
      toast.error("Expected Delivery Time is required.");
      retVal = false;
    }
    if (!logisticsRecord.dispatchTime) {
      fieldWithIdRequired('dispatchTime');
      toast.error("Dispatch Time is required.");
      retVal = false;
    }

    if (!logisticsRecord.departureLocation) {
      fieldWithIdRequired('departureLocation');
      toast.error("Departure Location is required.");
      retVal = false;
    }
    if (!logisticsRecord.destinationLocation) {
      fieldWithIdRequired('destinationLocation');
      toast.error("Destination Location is required.");
      retVal = false;
    }

    if (!logisticsRecord.logisticsProvider) {
      fieldWithIdRequired('logisticsProvider');
      toast.error("Logistics Provider is required.");
      retVal = false;
    }

    if (!logisticsRecord.quantity) {
      fieldWithIdRequired('quantity');
      toast.error("Quantity is required.");
      retVal = false;
    }

    if (!logisticsRecord.quantityUnit) {
      fieldWithIdRequired('quantityUnit');
      toast.error("Quantity Unit is required.");
      retVal = false;
    }


    if (!logisticsRecord.transportMode) {
      fieldWithIdRequired('transportMode');
      toast.error("Transport Mode is required.");
      retVal = false;
    }

    // environmental conditions data

    if (!environmentalCondition.effects) {
      fieldWithIdRequired('effects');
      toast.error("Effects field is required.");
      retVal = false;
    }

    if (!environmentalCondition.recordedDate) {
      fieldWithIdRequired('recordedDate');
      toast.error("Recorded Date is required.");
      retVal = false;
    }

    if (!environmentalCondition.temperature) {
      fieldWithIdRequired('temperature');
      toast.error("Temperature is required.");
      retVal = false;
    }

    if (!environmentalCondition.weatherConditions) {
      fieldWithIdRequired('weatherConditions');
      toast.error("Whether Conditions is required.");
      retVal = false;
    }



    return retVal;
  };

  const handleChange = (field, value, required) => {
    const updatedItem = { ...logisticsRecord }; // Create a copy of the items array
    updatedItem[field] = value; // Update the value in the copy
    setLogisticsRecord(updatedItem); // Update the state with the modified copy
    if (required) fieldWithIdRequired(field); //Show UI validation
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      {/* <hr /> */}
      <div className="row gutters">

        {logisticsRecord && logisticsRecord.id &&
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
            <div className="form-group">
              <label htmlFor="name">Code</label>
              <input
                type="text"
                className="form-control"
                id="code"
                value={logisticsRecord.code ? logisticsRecord.code : '--'}
                readOnly
              />
            </div>
          </div>
        }

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="giai">GIAI</label>
            <input
              type="text"
              className="form-control"
              id="giai"
              placeholder="Enter the GIAI..."
              value={logisticsRecord && logisticsRecord.giai}
              onChange={(e) => handleChange("giai", e.target.value, false)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="name">Date *</label>
            <input
              type="date"
              className="form-control"
              id="date"
              value={isoToDate(logisticsRecord && logisticsRecord.date)}
              onChange={(e) => handleChange("date", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="logisticsProvider">Logistics Provider *</label>
            <input
              type="text"
              className="form-control"
              id="logisticsProvider"
              placeholder="Enter the logistics provider..."
              value={logisticsRecord && logisticsRecord.logisticsProvider}
              onChange={(e) => handleChange("logisticsProvider", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Transport Mode *</label>
            <select
              className="form-control form-control-lg"
              id="transportMethod"
              value={logisticsRecord && logisticsRecord.transportMode}
              onChange={(e) => handleChange("transportMode", e.target.value, true)}
            >
              <option value="">Select Transport Mode...</option>
              {transportModeList && transportModeList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="name">Dispatch Time *</label>
            <input
              type="datetime-local"
              className="form-control"
              id="dispatchTime"
              value={logisticsRecord && logisticsRecord.dispatchTime}
              onChange={(e) => handleChange("dispatchTime", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="name">Expected Delivery Time *</label>
            <input
              type="datetime-local"
              className="form-control"
              id="expectedDeliveryTime"
              value={logisticsRecord && logisticsRecord.expectedDeliveryTime}
              onChange={(e) => handleChange("expectedDeliveryTime", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="name">Actual Delivery Date</label>
            <input
              type="datetime-local"
              className="form-control"
              id="actualDeliveryTime"
              value={logisticsRecord && logisticsRecord.actualDeliveryTime}
              onChange={(e) => handleChange("actualDeliveryTime", e.target.value, false)}
            />
            {/* <div className="invalid-feedback">This is required</div> */}
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="departureLocation">Departure Location *</label>
            <input
              type="text"
              className="form-control"
              id="departureLocation"
              placeholder="Enter the departure location..."
              value={logisticsRecord && logisticsRecord.departureLocation}
              onChange={(e) => handleChange("departureLocation", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="destinationLocation">Destination Location *</label>
            <input
              type="text"
              className="form-control"
              id="destinationLocation"
              placeholder="Enter the destination location..."
              value={logisticsRecord && logisticsRecord.destinationLocation}
              onChange={(e) => handleChange("destinationLocation", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="quantity">Quantity *</label>
            <input
              type="number"
              className="form-control"
              id="quantity"
              placeholder="Enter the quantity..."
              value={logisticsRecord && logisticsRecord.quantity}
              onChange={(e) => handleChange("quantity", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="quantityUnit">Quantity Unit *</label>
            <select
              className="form-control form-control-lg"
              id="quantityUnit"
              value={logisticsRecord && logisticsRecord.quantityUnit}
              onChange={(e) => handleChange("quantityUnit", e.target.value, true)}
            >
              <option value="">Select Quantity Type...</option>
              {quantityUnitList && quantityUnitList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Vehicle Details</label>
            <input
              type="text"
              className="form-control"
              id="vehicleDetails"
              placeholder="Enter the vehicle details..."
              value={logisticsRecord && logisticsRecord.vehicleDetails}
              onChange={(e) => handleChange("vehicleDetails", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Documentation </label>
            <select
              className="form-control form-control-lg"
              id="documentation"
              value={logisticsRecord && logisticsRecord.documentation}
              onChange={(e) => handleChange("documentation", e.target.value, false)}
            >
              <option value="">Select Documentation...</option>
              {documentationOptionsList && documentationOptionsList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="attachments">Documentation File</label>
            <FileInput id={"documentationFile"}
              value={logisticsRecord && logisticsRecord.documentationFile}
              onChange={(e) => handleChange("documentationFile", e.target.value, false)}
              required={false} className="form-control" />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Logistics Status</label>
            <select
              className="form-control form-control-lg"
              id="status"
              value={logisticsRecord && logisticsRecord.status}
              onChange={(e) => handleChange("status", e.target.value, false)}
            >
              <option value="">Select Status...</option>
              {logisticsStatusList && logisticsStatusList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="comments">Comments</label>
            <textarea
              rows="2"
              className="form-control"
              id="comments"
              placeholder="Enter the comments..."
              value={logisticsRecord && logisticsRecord.comments}
              onChange={(e) => handleChange("comments", e.target.value, false)}
            />
          </div>
        </div>

      </div>
      <br />
      <EnvironmentalConditionNew transferRecord={logisticsRecord} setTransferRecord={setLogisticsRecord} />

      {/* <EnvironmentalCondition environmentalCondition={environmentalCondition} setEnvironmentalCondition={setEnvironmentalCondition} /> */}
      <hr />
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
          <div className="float-end">
            <button type="button" className="btn btn-primary float-end"
              onClick={(e) => { if (validateFields()) { handleSubmit(e); } }}>
              Submit
            </button>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default LogisticsFormBasic;