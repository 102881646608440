import { toast } from "react-toastify";
import { baseURL } from "../../utils/misc";
import { getWorkflowStageById } from "../WorkflowStage/getWorkflowStageById";


 export const openWorkflowDocument = async (token, navigate, workflowRecordActivityId, workflowRecordId, workflowStageId, contractId) => {
  
  if (!(token)) {
    navigate("/login");
  }
  
  const theWorkflowStage = await getWorkflowStageById(token, workflowStageId);
  
  if(theWorkflowStage && theWorkflowStage.formURL && theWorkflowStage.formURL !== "/"){
    navigate(`${theWorkflowStage.formURL}/contract/${contractId}/activity/${workflowRecordActivityId}`);
  }
  else {
    toast.warn("No form route has been configured for this stage. Contact Admin.");
    return;
  }
};
