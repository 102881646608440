import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { baseURL } from "../../../utils/misc";
import { toast } from "react-toastify";
import { runningCompany } from "../../../appSetup";


function Register() {

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [otherNames, setOtherNames] = useState("");
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [telephone, setTelephone] = useState("");
	const [password, setPassword] = useState("");
	const [reEnterPassword, setReEnterPassword] = useState("");

	const [error, setError] = useState('');
	const [isConnecting, setIsConnecting] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const from = location?.state?.from.pathname || '/'

	const handleSubmit = async (e) => {
		e.preventDefault();
	
		// Validation: Check if required fields are filled
		if (!firstName || !lastName || !username || !email || !password) {
		  toast.error("Please fill in all required fields.");
		  return;
		}
	
		// Validation: Check if password and re-entered password match
		if (password !== reEnterPassword) {
		  toast.error("Passwords do not match.");
		  return;
		}
	
		try {
		  // Create a user object with the form data
		  const user = {
			firstName,
			lastName,
			otherNames,
			username,
			email,
			telephone,
			password,
		  };
	
		  // Make a POST request to create the user
		  const response = await fetch(`${baseURL}/Users`, {
			method: "POST",
			headers: {
			  "Content-Type": "application/json",
			},
			body: JSON.stringify(user),
		  });
	
		  // Check if the request was successful
		  if (response.ok) {
			// User created successfully, reset form
			setFirstName("");
			setLastName("");
			setOtherNames("");
			setUsername("");
			setEmail("");
			setTelephone("");
			setPassword("");
			setReEnterPassword("");
	
			toast.success("Registration successful!");
			navigate("/login");
	
			//Navigate to user other registrations.
		  } else {
			// Handle authentication error
			toast.warning('Unable to register you now.\n\nContact admin if this persists.');
			console.error('User registration failed.');
		  }
		} catch (error) {
		  // Handle other errors, e.g., network issues
		  toast.error("An error occurred while registering.");
		  console.error(error);
		}
	  };
	return (

		<div className="container">
			<form onSubmit={handleSubmit}>
				<div className="row justify-content-md-center">
					<div className="col-xl-5 col-lg-6 col-md-6 col-sm-12">
						<div className="login-screen">
							<div className="login-box">
								{error && <div className="alert alert-danger">{error}</div>}
								<a href="#" className="login-logo">
									<img src={`${window.location.origin}/assets/img/company_logos/${runningCompany.logo}`} alt={`${runningCompany.name} Logo`} style={{ display: 'block', margin: 'auto' }} />
								</a>
								<h5>Welcome,<br />Create your Admin Account.</h5>
								<div className="form-group">
									<input type="text" 
									onChange={(e) => setFirstName(e.target.value)} 
									value={firstName} 
									className="form-control" 
									placeholder="First Name" required />
								</div>
								<div className="form-group">
									<input type="text" 
									value={lastName} 
									onChange={(e) => setLastName(e.target.value)} 
									className="form-control" 
									placeholder="Last Name" required />
								</div>
								<div className="form-group">
									<input type="text" 
									onChange={(e) => setOtherNames(e.target.value)} 
									value={otherNames} 
									className="form-control" 
									placeholder="Other Names" required />
								</div>
								<div className="form-group">
									<input type="text" 
									value={username} 
									onChange={(e) => setUsername(e.target.value)} 
									className="form-control" 
									placeholder="Username" required />
								</div>
								<div className="form-group">
									<input type="email" 
									onChange={(e) => setEmail(e.target.value)} 
									value={email} 
									className="form-control" 
									placeholder="Email Address" required />
								</div>
								<div className="form-group">
									<input type="tel" 
									onChange={(e) => setTelephone(e.target.value)} 
									value={telephone} 
									className="form-control" 
									placeholder="Telephone" required />
								</div>

								<div className="form-group">
									<div className="input-group">
										<input type="password" 
										onChange={(e) => setPassword(e.target.value)} 
										value={password} 
										className="form-control" 
										placeholder="Password" required/>
										<input type="password"
										value={reEnterPassword}
										onChange={(e) => setReEnterPassword(e.target.value)}
										className="form-control" 
										placeholder="Confirm Password" required/>
									</div>
									<small id="passwordHelpInline" className="text-muted">
										Password must be 8-20 characters long.
									</small>
								</div>

								<div className="actions mb-4">
									{isConnecting ? (
										<button className="btn btn-primary">
											<span className="spinner-border spinner-border-sm"></span> Connecting...
										</button>
									) : (
										<button type="submit" className="btn btn-primary">
											Submit
										</button>
									)}
								</div>
								<hr/>
								<div className="m-0">
									<span className="additional-link">Back to Login? </span>
									<a href="/login" className="btn btn-light">Login</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>

	);
}

export default Register;