import { Link, useParams } from "react-router-dom";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import React, { useEffect, useState } from "react";
import Footer from "../../../layout/Footer";
import useAuth from "../../../hooks/useAuth";
import { getFieldById } from "../../../api/Fields/getFieldById";
import CropListing from "../../../components/Crop/CropListing";

const Crops = () => {
    const { fieldId } = useParams();
    const { user } = useAuth();
    const [field, setField] = useState();

    useEffect(() => {
        const fetchField = async () => {
            if (fieldId) {
                const fieldData = await getFieldById(user.user.token, fieldId);
                setField(fieldData);
            }
        };
    
        fetchField();
    }, [fieldId, user.user.token]);

    return (
        <>
            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to={`/fields/farm/${field && field.farmId}`}>Fields</Link></li>
                            <li className="breadcrumb-item">{field && field.fieldCode}</li>
                            <li className="breadcrumb-item active">Crops</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>

                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                        <CropListing fieldId={fieldId} />
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer/>

            </div>
            {/* Page wrapper end */}
        </>
    );
}

export default Crops;
