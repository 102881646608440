import { useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { baseURL } from "../../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../../common/Preloader";
import ManufacturingProcessTable from "./ManufacturingProcessTable";

function ManufacturingProcessListing({ manufacturingId }) {
    const { user } = useAuth();

    const [manufacturingProcesses, setManufacturingProcesses] = useState([]);
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);

    useEffect(() => {
        const fetchManufacturingProcesses = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
                toast.warn("You seem to have lost your access token. Please try again later.");
                return;
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/manufacturingprocesses/manufacturingrecord/${manufacturingId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching manufacturing records');
                }
                const data = await response.json();
                setManufacturingProcesses(data);
            } catch (error) {
                toast.error("Error fetching manufacturing records.");
                console.error("Error fetching manufacturing records:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchManufacturingProcesses();
    }, [manufacturingId]);

    return (
        <>
            <Preloader show={showPreloader} />

            <div className="row gutters" style={{ paddingTop: "8px", backgroundColor: "#f1f7fa" }}>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">List of Manufacturing Processes</div>
                            <div className="mt-3 p-2 parentLabel">
                                <div className="mt-2 mb-1">
                                </div>
                            </div>
                            <br />
                            <Link
                                to={`/manufacturingprocess/create/?manufacturingRecord=${manufacturingId}`}
                                className="btn btn-primary float-end"
                            >
                                <i className="icon-add"></i> Add Manufacturing Process
                            </Link>
                            <br />
                            {/* <div><span>Crop: </span> <strong className="card-title parentLabel">{cropName}</strong></div> */}
                        </div>
                        <ManufacturingProcessTable manufacturingProcesses={manufacturingProcesses} allowEdit={true} />
                    </div>
                </div>
            </div>

        </>
    );
}

export default ManufacturingProcessListing;