import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import WorkFlowForm from "../WorkFlowForm";

const CreateWorkFlow = () => {
    return (
    <>
        {/* Loading starts */}
        {/* <div id="loading-wrapper">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div> */}
        {/* Loading ends */}


        {/* Page wrapper start */}
        <div className="page-wrapper">

            {/* Sidebar wrapper start */}
            <SideBarWrapper />
            {/* Sidebar wrapper end */}

            {/* Page content start  */}
            <div className="page-content">

                {/* Header start */}
                <Navbar />
                {/* Header end */}

                {/* Page header start */}
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item">WorkFlow</li>
                        <li className="breadcrumb-item active">Create</li>
                    </ol>
                    <ul className="app-actions">
                    </ul>
                </div>
                {/* Page header end */}

                {/* Main container start */}
                <div className="main-container">
                    <WorkFlowForm />
                </div>
                {/* Main container end */}

            </div>
            {/* Page content end */}

        </div>
        {/* Page wrapper end */}
    </> );
}
 
export default CreateWorkFlow;