import { useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import React, { useEffect, useState } from "react";
import Preloader from "../../../components/common/Preloader";
import useAuth from "../../../hooks/useAuth";
import { getContractById } from "../../../api/Contracts/getContractById";
import { toast } from "react-toastify";
import AttachmentsFormView from "./AttachmentsFormView";

const BmrAttachment = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { bmrId } = useParams();

    const [showPreloader, setShowPreloader] = useState(false);
    const [theBMR, setTheBMR] = useState(null);

    useEffect(() => {
        if (bmrId && user) {
            getTheBMR();
        }
    }, [bmrId, user]);

    const getTheBMR = async () => {
        setShowPreloader(true);
        try {
            const bmr = await getContractById(user.user.token, bmrId);
            setTheBMR(bmr);
            if (!bmr) {
                toast.warning("The BMR is missing!");
                navigate('/bmrs/owned');
            }
        } catch (error) {
            console.error("Error fetching BMR:", error);
            toast.error("An error occurred while fetching the BMR.");
        }
        setShowPreloader(false);
    };
    
    

    return (
        <>
            <Preloader show={showPreloader} />

            {/* Page wrapper start */}
            <div className="page-wrapper">
                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}
                {/* Page content start */}
                <div className="page-content">
                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}
                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Home</li>
                            <li className="breadcrumb-item">BMR</li>
                            <li className="breadcrumb-item">Attachments</li>
                        </ol>
                        <ul className="app-actions"></ul>
                    </div>
                    {/* Page header end */}
                    {/* Main container start */}
                    <div className="main-container">
                        <div className="row gutters">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="bmr-card">
                                    <div className="bmr-card-body pt-0">
                                        <hr />
                                        <div className="row gutters">
                                            <div className="col-12">
                                                {theBMR ? (
                                                    <div id="bmr-report">
                                                        <AttachmentsFormView bmr={theBMR} />
                                                    </div>
                                                ) : (
                                                    <div>Loading...</div>
                                                )}
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Main container end */}
                </div>
                {/* Page content end */}
            </div>
            {/* Page wrapper end */}
        </>
    );
}

export default BmrAttachment;
