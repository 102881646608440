import { useState } from "react";
import SideBarWrapper from "../../components/common/SideBarWrapper";
import Navbar from "../../layout/Navbar";
import InitiateContractForm from "../../components/Contracts/InitiateContractForm";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Preloader from "../../components/common/Preloader";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { toast } from "react-toastify";

const InitiateContract = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [showPreloader, setShowPreloader] = useState(false);

  const [contract, setContract] = useState({
    cropId: null,
    buyerId: null,
    description: null, 
    details: '',
    startDate: '',
    endDate: '',
    contractAmount: null,
    currency: null,
    contractStatus: null,
    paymentTerms: null,
    deliveryTerms: null,
    exporterContactInfo: null,
    buyerContactInfo: null,
    attachments: null,
    documentIds: [],
  });

  const initiateContract = async (e) => {
    e.preventDefault();
  
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

     if (!Array.isArray(contract.documentIds) || contract.documentIds.some(id => id === null || id.trim() === '')) {
    toast.error("All document IDs must be valid.");
    return;
  }

    const data = {
      cropId: contract.cropId,
      buyerId: contract.buyerId,
      description: contract.description,
      details: contract.details,
      startDate: contract.startDate,
      endDate: contract.endDate,
      contractAmount: contract.contractAmount,
      currency: contract.currency,
      contractStatus: contract.contractStatus,
      paymentTerms: contract.paymentTerms,
      deliveryTerms: contract.deliveryTerms,
      exporterContactInfo: contract.exporterContactInfo,
      buyerContactInfo: contract.buyerContactInfo,
      attachments: contract.attachments,
      documentIds: contract.documentIds
    };
    console.log('contracts data >>:', data);
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
  
    try {
      setShowPreloader(true);
      
      const response = await fetch(`${baseURL}/contracts`, requestOptions);
  
      if (response.ok) {
        toast.success("The Contract has been successfully initiated.");
        navigate('/contracts');
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error initiating the Contract.");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Page wrapper start */}
      <div className="page-wrapper">
        {/* Sidebar wrapper start */}
        <SideBarWrapper />
        {/* Sidebar wrapper end */}

        {/* Page content start  */}
        <div className="page-content">
          {/* Header start */}
          <Navbar />
          {/* Header end */}

          {/* Page header start */}
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item">Contracts</li>
              <li className="breadcrumb-item active">Initiate</li>
            </ol>
            <ul className="app-actions"></ul>
          </div>
          {/* Page header end */}

          {/* Main container start */}
          <div className="main-container">
            <InitiateContractForm contract={contract} setContract={setContract} submitFunction={initiateContract}/>
          </div>
          {/* Main container end */}
        </div>
        {/* Page content end */}
      </div>
      {/* Page wrapper end */}
    </>
  );
};

export default InitiateContract;
