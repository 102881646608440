import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { baseURL, doesRoleExist } from "../../utils/misc";
import useAuth from "../../hooks/useAuth";
import Select from "react-select";
import { runningCompany } from "../../appSetup";

const Help = () => {
  const { user } = useAuth();
  const [cropList, setCropList] = useState([]);
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title"><i className="icon-help-circle"></i> Help</div>
            </div>
            <div className="card-body pt-0">
              <div className="form-group">
                <h4 style={{color: "#01902d"}}>
                  Support Line: +233 531 100 067
                </h4>
              </div>
              {(doesRoleExist(user, "Admin") || doesRoleExist(user, "Super Admin") || doesRoleExist(user, "Farmer")) && <div className="form-group">
                <br />
                <br />
                <div>
                  <a href={`${window.location.origin}/assets/help_docs/${runningCompany.helpDoc}`} target="_back" className="btn btn-lg btn-primary" role="button">
                    Download User Manual
                  </a>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default Help;
