import { useEffect, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Accordion, Button, Card, CardHeader } from "react-bootstrap";
import Preloader from "../common/Preloader";
import { getFullCropName, isoToDate, recordAccessAllowed } from "../../utils/helpers";
import { getFieldPreparationsByCropId } from "../../api/FieldPreparationRecords/getFieldPreparationsByCropId";

import FieldPreparationTable from "../FieldPreparationRecords/FieldPreparationTable";
import { getPlantingRecordsByCropId } from "../../api/PlantingRecords/getPlantingRecordsByCropId";
import { getWeedRecordsByCropId } from "../../api/WeedManagementRecords/getWeedRecordsList";
import WeedTable from "../WeedManagementRecords/WeedTable";
import { getNutrientRecordsByCropId } from "../../api/NutrientManagementRecords/getNutrientRecordsList";
import NutrientTable from "../NutrientManagementRecords/NutrientTable";
import { getPestRecordsByCropId } from "../../api/PestManagementRecords/getPestRecordsList";
import PestTable from "../PestManagementRecords/PestTable";
import { getInductionRecordsByCropId } from "../../api/InductionRecords/getInductionList";
import InductionTable from "../InductionRecords/InductionTable";
import { getHarvestingRecordsByCropId } from "../../api/HarvestingRecords/getHarvestingRecords";
import HarvestingTable from "../HarvestingRecords/HarvestingTable";
import { getPackhouseRecordsByCropId } from "../../api/PackHouseRecords/getPackHouseRecordsList";
import PackHouseTable from "../PackHouseRecords/PackHouseTable";
import { getManufacturingRecordsByCropId } from "../../api/ManufacturingRecords/getManufacturingList";
import ManufacturingTable from "../ManufacturingRecords/ManufacturingTable";
import { getPackagingRecordsByCropId } from "../../api/PackagingRecords/getPackagingList";
import PackagingTable from "../PackagingRecords/PackagingTable";
import { getTransferRecordsByCropId } from "../../api/TransferRecords/getTransferList";
import TransferTable from "../TransferRecords/TransferTable";
import { getLogisticsRecordsByCropId } from "../../api/LogisticsRecords/getLogisticsList";
import LogisticsTable from "../LogisticsRecords/LogisticsTable";
import { getWarehouseRecordsByCropId } from "../../api/WareHouseRecords/getWareRecordsList";
import WarehouseTable from "../WareHouseRecords/WarehouseTable";
import { getSpoilageRecordsByCropId } from "../../api/SpoilageRecords/getSpoilageRecordsList";
import SpoilageTable from "../SpoilageReports/SpoilageTable";
import PlantingTable from "../PlantingRecords/PlantingTable";
import { runningCompanyHasModule } from "../../appSetup";
import { geAllRecordAccessSetups } from "../../api/RecordAccessSetups/geAllRecordAccessSetups";

function CropRecords({ crop }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);

    const [fieldPreparationRecords, setFieldPreparationRecords] = useState();
    const [plantingRecords, setPlantingRecords] = useState();
    const [weedManagementRecords, setWeedManagementRecords] = useState();
    const [nutrientManagementRecords, setNutrientManagementRecords] = useState();
    const [pestManagementRecords, setPestManagementRecords] = useState();
    const [inductionRecords, setInductionRecords] = useState();
    const [harvestingRecords, setHarvestingRecords] = useState();
    const [packhouseRecords, setPackhouseRecords] = useState();
    const [manufacturingRecords, setManufacturingRecords] = useState();
    const [packagingRecords, setPackagingRecords] = useState();
    const [transferRecords, setTransferRecords] = useState();
    const [logisticsRecords, setLogisticsRecords] = useState();
    const [warehouseRecords, setWarehouseRecords] = useState();
    const [spoilageRecords, setSpoilageRecords] = useState();


    const [recordAccessSetups, setRecordAccessSetups] = useState([]);

    useEffect(() => {
        if(!user || !user.user || !user.user.token){
            toast.warn("You are not authorized to do this!");
            navigate("/");
            return;
        }

        if(crop) {
            getFieldPreparationsByCropId(user.user.token, crop.id).then(m => {setFieldPreparationRecords(m); });
            getPlantingRecordsByCropId(user.user.token, crop.id).then(m => {setPlantingRecords(m); });
            getWeedRecordsByCropId(user.user.token, crop.id).then(m => {setWeedManagementRecords(m); });
            getNutrientRecordsByCropId(user.user.token, crop.id).then(m => {setNutrientManagementRecords(m); });
            getPestRecordsByCropId(user.user.token, crop.id).then(m => {setPestManagementRecords(m); });
            getInductionRecordsByCropId(user.user.token, crop.id).then(m => {setInductionRecords(m); });
            getHarvestingRecordsByCropId(user.user.token, crop.id).then(m => {setHarvestingRecords(m); });
            getPackhouseRecordsByCropId(user.user.token, crop.id).then(m => {setPackhouseRecords(m); });
            getManufacturingRecordsByCropId(user.user.token, crop.id).then(m => {setManufacturingRecords(m); });
            getPackagingRecordsByCropId(user.user.token, crop.id).then(m => {setPackagingRecords(m); });
            getTransferRecordsByCropId(user.user.token, crop.id).then(m => {setTransferRecords(m); });
            getLogisticsRecordsByCropId(user.user.token, crop.id).then(m => {setLogisticsRecords(m); });
            getWarehouseRecordsByCropId(user.user.token, crop.id).then(m => {setWarehouseRecords(m); });
            getSpoilageRecordsByCropId(user.user.token, crop.id).then(m => {setSpoilageRecords(m); });

            geAllRecordAccessSetups(user.user.token).then(m => { setRecordAccessSetups(m); console.log(m); });
        }
    }, []);

    const getRecordAccessSetupByCode = (code) => {
        return recordAccessSetups.find(item => item.code === code);
    };

    let count = 0;
    return (
        <>
            <Preloader show={showPreloader} />
            <div className="main-container">
                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="card" style={{backgroundColor: "#eff1f5"}}>
                            <div className="card-header">
                                <div className="card-title">
                                    Crop Records {crop ? " - " + getFullCropName(crop) : ""}
                                    <h6 className="text-muted mt-3"><em>Kindly click on each record for its corresponding list.</em></h6>
                                </div>
                            </div>
                            <div className="card-body pt-0 mt-0">
                                <div className="row gutters">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="accordion" id="defaultAccordion">
                                            {recordAccessAllowed(getRecordAccessSetupByCode("FIELD_PREPARATION_RECORD"), "showToBuyer") && fieldPreparationRecords && fieldPreparationRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="fieldPreparationRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#fieldPreparationRecordsCollapse" aria-expanded="true" aria-controls="fieldPreparationRecordsCollapse">
                                                            {`${count}) Field Preparation Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="fieldPreparationRecordsCollapse" className={"collapse"/* + (count === 1) ? " show" : ""*/} aria-labelledby="fieldPreparationRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <FieldPreparationTable fieldPreparationRecords={fieldPreparationRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("PLANTING_RECORD"), "showToBuyer") && plantingRecords && plantingRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="plantingRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#plantingRecordsCollapse" aria-expanded="true" aria-controls="plantingRecordsCollapse">
                                                            {`${count}) Planting Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="plantingRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="plantingRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <PlantingTable plantingrecords={plantingRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("WEED_MANAGEMENT_RECORD"), "showToBuyer") && weedManagementRecords && weedManagementRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="weedManagementRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#weedManagementRecordsCollapse" aria-expanded="true" aria-controls="weedManagementRecordsCollapse">
                                                            {`${count}) Weed Management Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="weedManagementRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="weedManagementRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <WeedTable weedRecords={weedManagementRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("NUTRIENT_MANAGEMENT_RECORD"), "showToBuyer") && nutrientManagementRecords && nutrientManagementRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="nutrientManagementRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#nutrientManagementRecordsCollapse" aria-expanded="true" aria-controls="nutrientManagementRecordsCollapse">
                                                            {`${count}) Nutrient Management Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="nutrientManagementRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="nutrientManagementRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <NutrientTable nutrientRecords={nutrientManagementRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("PESTMANAGEMENT_RECORD"), "showToBuyer") && pestManagementRecords && pestManagementRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="pestManagementRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#pestManagementRecordsCollapse" aria-expanded="true" aria-controls="pestManagementRecordsCollapse">
                                                            {`${count}) Pest Management Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="pestManagementRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="pestManagementRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <PestTable pestRecords={pestManagementRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("INDUCTION_RECORD"), "showToBuyer") && inductionRecords && inductionRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="inductionRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#inductionRecordsCollapse" aria-expanded="true" aria-controls="inductionRecordsCollapse">
                                                            {`${count}) Induction Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="inductionRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="inductionRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <InductionTable inductionRecords={inductionRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("HARVESTING_RECORD"), "showToBuyer") && harvestingRecords && harvestingRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="harvestingRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#harvestingRecordsCollapse" aria-expanded="true" aria-controls="harvestingRecordsCollapse">
                                                            {`${count}) Harvesting Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="harvestingRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="harvestingRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <HarvestingTable harvestingrecords={harvestingRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("PACKHOUSE_RECORD"), "showToBuyer") && packhouseRecords && packhouseRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="packhouseRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#packhouseRecordsCollapse" aria-expanded="true" aria-controls="packhouseRecordsCollapse">
                                                            {`${count}) Packhouse Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="packhouseRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="packhouseRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <PackHouseTable packHouseRecords={packhouseRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("MANUFACTURING_RECORD"), "showToBuyer") && runningCompanyHasModule('MANUFACTURING') && manufacturingRecords && manufacturingRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="manufacturingRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#manufacturingRecordsCollapse" aria-expanded="true" aria-controls="manufacturingRecordsCollapse">
                                                            {`${count}) Manufacturing Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="manufacturingRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="manufacturingRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <ManufacturingTable manufacturingRecords={manufacturingRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("PACKAGING_RECORD"), "showToBuyer") && packagingRecords && packagingRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="packagingRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#packagingRecordsCollapse" aria-expanded="true" aria-controls="packagingRecordsCollapse">
                                                            {`${count}) Packaging Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="packagingRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="packagingRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <PackagingTable packagingRecords={packagingRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("TRANSFER_RECORD"), "showToBuyer") && transferRecords && transferRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="transferRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#transferRecordsCollapse" aria-expanded="true" aria-controls="transferRecordsCollapse">
                                                            {`${count}) Transfer Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="transferRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="transferRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <TransferTable transferRecords={transferRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("LOGISTICS_RECORD"), "showToBuyer") && logisticsRecords && logisticsRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="logisticsRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#logisticsRecordsCollapse" aria-expanded="true" aria-controls="logisticsRecordsCollapse">
                                                            {`${count}) Logistics Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="logisticsRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="logisticsRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <LogisticsTable logisticsRecords={logisticsRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("WAREHOUSE_RECORD"), "showToBuyer") && warehouseRecords && warehouseRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="warehouseRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#warehouseRecordsCollapse" aria-expanded="true" aria-controls="warehouseRecordsCollapse">
                                                            {`${count}) Warehouse Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="warehouseRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="warehouseRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <WarehouseTable warehouseRecords={warehouseRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                            {/*  */}
                                            {recordAccessAllowed(getRecordAccessSetupByCode("WAREHOUSE_RECORD"), "showToBuyer") && spoilageRecords && spoilageRecords.length > 0 && (<>
                                                {++count && null}
                                                <div className="accordion-container">
                                                    <div className="accordion-header" id="spoilageRecords">
                                                        <Link to="" className="" data-toggle="collapse" data-target="#spoilageRecordsCollapse" aria-expanded="true" aria-controls="spoilageRecordsCollapse">
                                                            {`${count}) Spoilage Records`}
                                                        </Link>
                                                    </div>
                                                    <div id="spoilageRecordsCollapse" className={"collapse"/* + ((count === 1) ? " show" : "")*/} aria-labelledby="spoilageRecords" data-parent="#defaultAccordion">
                                                        <div className="accordion-body">
                                                            <SpoilageTable spoilagerecords={spoilageRecords} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CropRecords;