import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import ExportCustomFormBasic from "./ExportCustomFormBasic";

const ExportCustomForm = ({ exportCustomRecord, setExportCustomRecord,crop }) => {
  const { user } = useAuth();
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [exportCustomId, setExportCustomId] = useState(null);

  useEffect(() => {
    if (exportCustomRecord) {
      setExportCustomId(exportCustomRecord.id);
      // setUserId(userData.id);
    }
  }, [exportCustomRecord]);

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const data = {
      cropId: crop.id,
      currency: exportCustomRecord.currency,
      customsDeclarationNumber: exportCustomRecord.customsDeclarationNumber,
      destinationCountry: exportCustomRecord.destinationCountry,
      exporter: exportCustomRecord.exporter,
      exportMode: exportCustomRecord.exportMode,
      portOfDischarge: exportCustomRecord.portOfDischarge,
      portOfLoading: exportCustomRecord.portOfLoading,
      totalDeclaredValue: exportCustomRecord.totalDeclaredValue,
      shippingLineOrAirline: exportCustomRecord.shippingLineOrAirline,
      flightOrVesselNumber: exportCustomRecord.flightOrVesselNumber,
      departureDate: exportCustomRecord.departureDate,
      arrivalDate: exportCustomRecord.arrivalDate,
      totalWeight: exportCustomRecord.totalWeight,
      weightUnit: exportCustomRecord.weightUnit,
      containerNumber: exportCustomRecord.containerNumber,
      freightForwarder: exportCustomRecord.freightForwarder,
      handlingAgent: exportCustomRecord.handlingAgent,
      shippingDocuments: exportCustomRecord.shippingDocuments,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/exportcustomsrecords`, requestOptions);

      if (response.ok) {
        toast.success("The Export Custom Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/exportcustomsrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the Export Custom record.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const data = {
      id: exportCustomId,
      cropId: crop.id,
      currency: exportCustomRecord.currency,
      customsDeclarationNumber: exportCustomRecord.customsDeclarationNumber,
      destinationCountry: exportCustomRecord.destinationCountry,
      exporter: exportCustomRecord.exporter,
      exportMode: exportCustomRecord.exportMode,
      portOfDischarge: exportCustomRecord.portOfDischarge,
      portOfLoading: exportCustomRecord.portOfLoading,
      totalDeclaredValue: exportCustomRecord.totalDeclaredValue,
      shippingLineOrAirline: exportCustomRecord.shippingLineOrAirline,
      flightOrVesselNumber: exportCustomRecord.flightOrVesselNumber,
      departureDate: exportCustomRecord.departureDate,
      arrivalDate: exportCustomRecord.arrivalDate,
      totalWeight: exportCustomRecord.totalWeight,
      weightUnit: exportCustomRecord.weightUnit,
      containerNumber: exportCustomRecord.containerNumber,
      freightForwarder: exportCustomRecord.freightForwarder,
      handlingAgent: exportCustomRecord.handlingAgent,
      shippingDocuments: exportCustomRecord.shippingDocuments,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/exportcustomsrecords/${exportCustomId}`, requestOptions);

      if (response.ok) {
        toast.success("The Export Custom Record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/exportcustomsrecords/crop/${crop.id}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the export customs record.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Export Custom Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/spoilagerecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <ExportCustomFormBasic crop={crop} exportCustomRecord={exportCustomRecord} setExportCustomRecord={setExportCustomRecord} handleSubmit={exportCustomId ? handleSubmitEdit : handleSubmitCreate} />
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default ExportCustomForm;