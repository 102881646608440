import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import useAuth from "../../../hooks/useAuth";
import Preloader from "../../common/Preloader";
import { getInvitationTargets } from "../../../api/Invitations/getInvitationTargets";
import { fieldRequired, fieldWithIdRequired } from "../../../utils/helpers";

const InvitationCreate = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [target, setTarget] = useState('');
  const [purpose, setPurpose] = useState('');

  const [targetList, setTargetList] = useState([]);

  const [showPreloader, setShowPreloader] = useState(false);
  useEffect(() => {
    getSetUpData();
  }, []);

  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    try {
      setShowPreloader(true);
      setTargetList(await getInvitationTargets(user.user.token));
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const validateFields = () => {
    let retVal = true;
    if (!telephone) {
      fieldWithIdRequired('telephone');
      toast.error("Telephone is required.");
      retVal = false;
    }
    if (!email) {
      fieldWithIdRequired('email');
      toast.error("Email is required.");
      retVal = false;
    }
    if (!target) {
      fieldWithIdRequired('target');
      toast.error("Target is required.");
      retVal = false;
    }
    if (!purpose) {
      fieldWithIdRequired('purpose');
      toast.error("Purpose is required.");
      retVal = false;
    }
    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      telephone,
      email,
      target,
      purpose,
      returnUrl: window.location.origin + "/register"
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/invitations`, requestOptions);

      if (response.ok) {
        toast.success("The Invitations has been successfully sent.");

        setTelephone("");
        setEmail("");
        setTarget("");

        navigate("/registration/invitations");

        setShowPreloader(false);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Could not send the invitation!");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Send an Invitation</div>
              <div className="card-sub-title">Enter the details of the Invitation.</div>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="telephone">Telephone *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="telephone"
                      value={telephone}
                      onChange={(e) => { setTelephone(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Telephone..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => { setEmail(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Email..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="target">Target *</label>
                    <select
                      className="form-control form-control-lg"
                      id="target"
                      value={target}
                      onChange={(e) => { setTarget(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Target...</option>
                      {targetList && targetList.map((tgList) => (
                        <option key={tgList} value={tgList}>
                          {tgList}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="purpose">Purpose *</label>
                    <textarea
                      className="form-control form-control-lg"
                      id="purpose"
                      value={purpose}
                      onChange={(e) => { setPurpose(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Purpose for the invitation..."
                      rows={3}
                    >
                    </textarea>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                  <div className="float-end">
                    <button type="button" onClick={handleSubmitCreate} className="btn btn-primary float-end">
                      Send Invitation
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default InvitationCreate;
