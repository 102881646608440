import ReferencedFormContractInfo from "./ReferencedFormContractInfo";
import ReferencedFormRejectionInstructions from "./ReferencedFormRejectionInstructions";
import ReferencedFormStageInstructions from "./ReferencedFormStageInstructions";

const ReferencedFormsHeader = ({theWorkflowRecordActivity}) => {
  return (
    <>
      {theWorkflowRecordActivity && (<>
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <ReferencedFormContractInfo theWorkflowRecordActivity={theWorkflowRecordActivity} />
          </div>
        </div>
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <ReferencedFormStageInstructions workflowStageId={theWorkflowRecordActivity.workflowStageId}/>
          </div>
        </div>
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <ReferencedFormRejectionInstructions theWorkflowRecordActivity={theWorkflowRecordActivity}/> 
          </div>
        </div>
      </>)}
    </>
  );
};

export default ReferencedFormsHeader;
