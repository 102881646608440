import { getFullCropName } from "../../utils/helpers";

const CropInfo = ({ contract }) => {
  return (
    <>
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card" style={{backgroundColor: "#eff1f5"}}>
            <div className="card-header">
              <div className="card-title">{`${(contract ? contract.crop.cropCode : 'Crop') + ' Details'} `}</div>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                {/* Crop */}
                <div className="col-12 mb-3">
                  <p><strong>Code:</strong> {contract ? contract.crop.cropCode  : 'n/a'}</p>
                </div>

                <div className="col-12 mb-3">
                  <p><strong>Name:</strong> {contract ? contract.crop.name  : 'n/a'}</p>
                </div>

                {/* Variety */}
                <div className="col-12 mb-3">
                  <p><strong>Variety:</strong> {contract ? contract.crop.variety : 'n/a'}</p>
                </div>

                {/* Lifespan */}
                <div className="col-12 mb-3">
                  <p><strong>Lifespan:</strong> {contract ? contract.crop.lifespan : 'n/a'}</p>
                </div>

                {/* LifespanUnit */}
                <div className="col-12 mb-3">
                  <p><strong>Lifespan Unit:</strong> {contract ? contract.crop.lifespanUnit : 'n/a'}</p>
                </div>

                {/* Quantity */}
                <div className="col-12 mb-3">
                  <p><strong>Quantity:</strong> {contract ? contract.crop.quantity : 'n/a'}</p>
                </div>

                {/* QuantityUnit */}
                <div className="col-12 mb-3">
                  <p><strong>Quantity Unit:</strong> {contract ? contract.crop.quantityUnit : 'n/a'}</p>
                </div>

                {/* Purpose */}
                <div className="col-12 mb-3">
                  <p><strong>Purpose:</strong> {contract ? contract.crop.purpose : 'n/a'}</p>
                </div>

                {/* IsOrganic */}
                <div className="col-12 mb-3">
                  <p><strong>Is Organic:</strong> {contract ? contract.crop.isOrganic ? 'Organic' : 'Not Organic' : 'n/a'}</p>
                </div>

              </div>
            </div>
            {/* <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default CropInfo;