import { baseURL } from "../../utils/misc";

export const getWorkflowStageById = async (token, id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/workflowstages/${id}`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error getting the Workflow Stage.");
      }
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching the Workflow Stage:", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};
