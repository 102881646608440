import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import ManufacturingForm from "../../../components/ManufacturingRecords/ManufacturingForm";
import Footer from "../../../layout/Footer";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { getCropById } from "../../../api/CropRecords/getCropById";

const CreateManufacturingRecord = () => {
    const [crop, setCrop] = useState();
    const { user } = useAuth();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const cropId = queryParams.get('crop'); // Retrieve the value of a specific query parameter


    useEffect(() => {
        getCropById(user.user.token, cropId).then(f => { setCrop(f); });
    });
    return (
        <>


            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item">{crop && crop.cropCode}</li>
                            <li className="breadcrumb-item">
                                <Link to={`/manufacturingrecords/crop/${crop && crop.id}`} >Manufacturing Records</Link>
                            </li>
                            <li className="breadcrumb-item active">Create</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                    {crop && <ManufacturingForm crop={crop}/>}
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer />

            </div>
            {/* Page wrapper end */}
        </>);
}

export default CreateManufacturingRecord;