// Preloader.js
import React, { useState, useEffect } from 'react';

const Preloader = ({ show }) => {
    const [shouldShow, setShouldShow] = useState(show);

    useEffect(() => {
        // When the "show" prop changes, update the "shouldShow" state accordingly
        setShouldShow(show);

        // If "show" becomes false, add the fade-out class to initiate the transition
        if (!show) {
            const timer = setTimeout(() => {
                setShouldShow(false);
            }, 500); // Adjust the duration as needed (in milliseconds)

            // Clear the timer on component unmount (cleanup)
            return () => clearTimeout(timer);
        }
    }, [show]);

    return shouldShow ? (
        <div id="loading-wrapper" className={`${show ? '' : 'fade-out'}`}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    ) : null;
};

export default Preloader;
