import { Link, useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../components/common/SideBarWrapper";
import Navbar from "../../layout/Navbar";
import React,{ useEffect, useState } from "react";
import { baseURL } from "../../utils/misc";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import Contracts from "../../components/Contracts/Contracts";
import ContractView from "../../components/Contracts/ContractView";
import Preloader from "../../components/common/Preloader";
import { getWorkflowRecordById } from "../../api/WorkflowRecord/getWorkflowRecordById";

const ContractViewIndex = () => {
    const [showPreloader, setShowPreloader] = useState(false);
    const { recordId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
  
    const [workflowRecord, setWorkflowRecord] = useState([]);
  
    useEffect(() => {
        getData();
    }, [])
  
    const getData = async () => {
      if (!(user && user.user.token)) {
        navigate("/login");
        toast.warn("You seem to have lost your access token. Please try again later.");
        return;
      }
  
      // Fetch user data when the component mounts
      setShowPreloader(true);
      getWorkflowRecordById(user.user.token, recordId).then(m => setWorkflowRecord(m));
      setShowPreloader(false);
    };

    return (
        <>
            <Preloader show={showPreloader} />
            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item">Contracts</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                        {workflowRecord.contract && <ContractView contract={workflowRecord.contract} /> }
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}

            </div>
            {/* Page wrapper end */}
        </>
    );
}

export default ContractViewIndex;