import Navbar from "../../../../layout/Navbar";
import useAuth from "../../../../hooks/useAuth";
import SideBarWrapper from "../../../../components/common/SideBarWrapper";
import { baseURL } from "../../../../utils/misc";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { isSinglePageActive } from "../../../../utils/daynamicNavigation";
import Preloader from "../../../../components/common/Preloader";
import Footer from "../../../../layout/Footer";

function Users() {
    const { user } = useAuth();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [telephone, setTelephone] = useState('');
    const [otherNames, setOtherNames] = useState('');
    const [username, setUsername] = useState('');
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);

    const profileMenuItems = [
        {
            id: 1,
            name: "Assign Role",
            icon: "flaticon-transfer-1",
            routerPath: "/user-roles",
        },
        {
            id: 2,
            name: "Edit User",
            icon: "flaticon-transfer-1",
            routerPath: "/user/edit",
        }
    ];


    // Define the path pattern for user profiles
    const $ = require("jquery");

    useEffect(() => {

        getUsers();
    }, []);

    useEffect(() => {
        if (users && users.length > 0) {
            //Initialize DataTable when users are available
            $(document).ready(function () {
                const dataTable = $("#data-table").DataTable();

                // Destroy DataTable if it exists
                if (dataTable) {
                    dataTable.destroy();
                }

                $("#data-table").DataTable({
                    // Add any required DataTable options here
                });
            });
        }
    }, [users]);

    const getUsers = async () => {
        
        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${user.user.token}`,
            }
        };
        
        setShowPreloader(true);

        // Fetch user data when the component mounts
        fetch(`${baseURL}/Users`, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.error('Error fetching users')
                }
            })
            .then((data) => {
                // Set the useState valuations with the fetched data
                setUsers(data);
            })
            .catch((error) => {
                toast.error("Error fetching users.");
                console.error("Error fetching user:", error);
            })
            .finally(()=>{
                setShowPreloader(false);
            });
    }


    const handleUserCreateSubmit = async (e) => {
        e.preventDefault();

        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }
        setShowPreloader(true);
        try {


            // Create the user object using the state values and uploaded image links
            const userData = {
                firstName: firstName,
                lastName: lastName,
                otherNames: otherNames,
                username: username,
                email: email,
                telephone: telephone,
                password: password,
            };

            console.log('userData >>>:', userData);

            // Create a request payload
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'text/plain',
                    Authorization: `Bearer ${user.user.token}`,
                },
                body: JSON.stringify(userData),
                redirect: 'follow',
            };
            console.log('requestOptions >>>:', requestOptions);

            //Send the POST request to create the billing
            const response = await fetch(`${baseURL}/Users`, requestOptions);
            if (response.ok) {
                toast.success('User has been successfully created.');
                const responseData = await response.json();
                setShowPreloader(true);
                navigate('/users')
            } else {
                const errorResponseData = await response.json();
                toast.error('Error creating user.');
                console.error('Error saving user data:', response.statusText);
            }
        } catch (error) {
            toast.error('Error creating user.');
            console.error('Error:', error);
        } finally {
            setShowPreloader(false);
        }
    };







    return (
        <>
            <Preloader show={showPreloader} />
            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active">Users</li>
                        </ol>

                        <ul className="app-actions">

                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">


                        <div className="row gutters">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                {/* User create modal */}
                                <div className="modal fade" id="addNewTask" tabIndex="-1" role="dialog" aria-labelledby="addNewTaskLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="addNewTaskLabel">Create User</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <form className="row gutters" onSubmit={handleUserCreateSubmit}>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="form-group">
                                                            <label htmlFor="taskTitle">First Name</label>
                                                            <input type="text" value={firstName} id="firstName" onChange={(e) => setFirstName(e.target.value)} className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="form-group">
                                                            <label htmlFor="taskTitle">Last Name</label>
                                                            <input type="text" value={lastName} id="lastName" onChange={(e) => setLastName(e.target.value)} className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="form-group">
                                                            <label htmlFor="taskTitle">Other Names</label>
                                                            <input type="text" value={otherNames} id="otherNames" onChange={(e) => setOtherNames(e.target.value)} className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="form-group">
                                                            <label htmlFor="taskTitle">UserName</label>
                                                            <input type="text" value={username} id="username" onChange={(e) => setUsername(e.target.value)} className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="form-group">
                                                            <label htmlFor="startDate">Email Address</label>
                                                            <div className="custom-date-input">
                                                                <input type="email" value={email} id="email" onChange={(e) => setEmail(e.target.value)} className="form-control datepicker" required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="form-group">
                                                            <label htmlFor="endDate">Phone Number</label>
                                                            <div className="custom-date-input">
                                                                <input type="tel" value={telephone} id="telephone" onChange={(e) => setTelephone(e.target.value)} className="form-control datepicker" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="form-group">
                                                            <label htmlFor="endDate">Password</label>
                                                            <div className="custom-date-input">
                                                                <input type="password" value={password} id="password" onChange={(e) => setPassword(e.target.value)} className="form-control datepicker" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                                                        <button type="submit" className="btn btn-primary">Create</button>

                                                    </div>

                                                </form>
                                            </div>
                                            {/* <div className="modal-footer custom">
                                                <div className="left-side">
                                                    <button type="button" className="btn btn-link danger btn-block" data-dismiss="modal">Cancel</button>
                                                </div>
                                                <div className="divider"></div>
                                                <div className="right-side">
                                                    <button type="button" className="btn btn-link success btn-block">Create</button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/*END: User create modal */}

                                <div className="card">
                                    <div className="card-header">
                                        <div className="card-title">List of Users</div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                                                <div className="float-end">
                                                    <Link className="btn btn-outline-primary btn-sm float-end" to= "/user/create" ><span className="icon-plus1"></span> Add New User</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="table-responsive">
                                                    <table id="data-table" className="table custom-table">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th>First Name</th>
                                                                <th>Last Name</th>
                                                                <th>Username</th>
                                                                <th>Email</th>
                                                                <th>Telephone</th>
                                                                <th><div className="float-end">Actions</div></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {users?.map((user) => (
                                                                <tr key={user.id}>
                                                                    <td>{user.firstName}</td>
                                                                    <td>{user.lastName}</td>
                                                                    <td>{user.username}</td>
                                                                    <td>{user.email}</td>
                                                                    <td>{user.telephone}</td>
                                                                    <td className="">
                                                                        <li className="user_setting" style={{ listStyle: "none" }}>
                                                                            <div className="dropdown">
                                                                                <a
                                                                                    className="btn btn-secondary btn-sm dropdown-toggle dn-1199 ms-2"
                                                                                    role="button"
                                                                                    href="#"
                                                                                    id={"dropdownMenuLink" + user.id}
                                                                                    data-bs-toggle="dropdown"
                                                                                >
                                                                                    Actions <i className="fa fa-caret-down"></i>
                                                                                </a>
                                                                                <ul
                                                                                    className="dropdown-menu"
                                                                                    aria-labelledby={"dropdownMenuLink" + user.id}
                                                                                >
                                                                                    {profileMenuItems.map((actionMenuItem) => (
                                                                                        <li>
                                                                                            <Link
                                                                                                to={actionMenuItem.routerPath + "/" + user.id}
                                                                                                key={user.id + '-' + actionMenuItem.id}
                                                                                                className="dropdown-item"
                                                                                                style={
                                                                                                    isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                                        ? { color: "#ff5a5f" }
                                                                                                        : undefined
                                                                                                }
                                                                                            >
                                                                                                <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                            </Link>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Row start */}

                        {/* Row end */}

                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer/>
            </div>
            {/* Page wrapper end */}

        </>
    );
}

export default Users;