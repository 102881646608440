import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL } from "../../utils/misc";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { toast } from "react-toastify";
import { getFullFieldName } from "../../utils/helpers";
import CropTable from "./CropTable";

function CropListing({ fieldId }) {
    const { user } = useAuth();
    const [cropList, setCropList] = useState([]);
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [field, setField] = useState();

    useEffect(() => {
        const fetchFields = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
                toast.warn("You seem to have lost your access token. Please try again later.");
                return;
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/fields/${fieldId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching fields');
                }
                const data = await response.json();
                setField(data);
            } catch (error) {
                toast.error("Error fetching fields.");
                console.error("Error fetching fields:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchFields();
    }, [user, navigate, fieldId]);

    useEffect(() => {
        const fetchCrops = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
                toast.warn("You seem to have lost your access token. Please try again later.");
                return;
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/crops/field/${fieldId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching Crops!');
                }
                const data = await response.json();
                setCropList(data);
            } catch (error) {
                console.error("Error fetching Crops:", error);
            } finally {
                setShowPreloader(false);
            }
        };

        fetchCrops();
    }, [fieldId]);


    return (
        <>
            <Preloader show={showPreloader} />
            <div className="main-container">
                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">List of Crops</div>
                                <div className="mt-3 p-2 parentLabel">
                                    <div className="mt-2 mb-1">
                                        <span>Field: </span>{getFullFieldName(field)}
                                        <Link to={`/fields/farm/${field && field.farmId}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                                    </div>
                                </div>
                                <br/>
                                <Link
                                    to={`/crop/create/?field=${fieldId}`}
                                    className="btn btn-primary float-end"
                                >
                                    <i className="icon-add"></i> Add Crop
                                </Link>
                            </div>
                            <CropTable cropList={cropList} allowEdit={true} />
                        </div>
                    </div>
                </div>


                {/* Row start */}

                {/* Row end */}

            </div>

        </>
    );
}

export default CropListing;