import React, { useState } from 'react';
import { fieldRequired } from '../../utils/helpers';
import { Link } from 'react-router-dom';

const FileInput = ({ id, value, setValue, required, className }) => {
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setValue(reader.result);
        setError(''); // Clear any previous errors
      };

      reader.onerror = () => {
        setError('Error reading file');
        setValue('');
      };

      reader.readAsDataURL(file);
    } else {
      setError('No file selected');
    }
  };

  return (
    <div>
      {!value ?
        <input id={id} type="file" onChange={(e) => {handleFileChange(e); if(required) fieldRequired(e);}} className={className ? className : ""} />
       :
        <div className="input-group mb-2 mr-sm-2">
          <input id={id}  type="file" onChange={(e) => {handleFileChange(e); if(required) fieldRequired(e);}} className={"form-control" + className ? " " + className : ""} />
          <div className="input-group-append">
            <div className="input-group-text"><Link to={value} target='_blank' rel="noopener noreferrer"><span className="icon-eye1"></span></Link></div>
          </div>
        </div>
      }
      <div className="invalid-feedback">This is required</div>
      {/* {error && (
        <div style={{ color: 'red' }}>
          <p>{error}</p>
        </div>
      )} */}
    </div>
  );
};

export default FileInput;
