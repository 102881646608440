import React, { useEffect, useState, } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { isSinglePageActive } from "../../utils/daynamicNavigation";
import SideBarWrapper from '../../components/common/SideBarWrapper';
import Navbar from '../../layout/Navbar';
import { baseURL, handleNotOkHttpResponse } from '../../utils/misc';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import Preloader from "../../components/common/Preloader";
import useAuth from '../../hooks/useAuth';

const WorkFLow = () => {
  const {user} = useAuth();
  const [workflows, setWorkflows] = useState([]);
  const token = user && user.user ? user.user.token : null;

  const navigate = useNavigate(); // Use useNavigate hook
  const [show, setShow] = useState(false);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState(null);
  const [showPreloader, setShowPreloader] = useState(false);

  
  const $ = require("jquery");

  useEffect(() => {
    if (!token) {
      alert("Not authorized");
      return;
    }

    fetchWorkflows();
  }, [token]);

  useEffect(() => {
    if (workflows && workflows.length > 0) {
      //Initialize DataTable when users are available
      $(document).ready(function () {
        const dataTable = $("#data-table").DataTable();

        // Destroy DataTable if it exists
        if (dataTable) {
          dataTable.destroy();
        }

        $("#data-table").DataTable({
          // Add any required DataTable options here
        });
      });
    }
  }, [workflows]);

  const fetchWorkflows = async () => {
    setShowPreloader(true);
    try {
      if (!token) {
        console.error("Token not available");
        return;
      }

      const response = await fetch(`${baseURL}/workflows`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setWorkflows(data);
      } else {
        console.error("Error fetching workflows:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching workflows:", error.message);
    } finally {
      setShowPreloader(false);
    }
  };

  const handleShow = (flowId) => {
    setShow(true);
    setSelectedWorkflowId(flowId);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedWorkflowId(null);
  };

  const handleRemoveWorkflow = async () => {
    if (!token) {
        console.error("Token not available");
        return;
      }
    try {
      setShowPreloader(true);

      if (!selectedWorkflowId) {
        console.error('No selected Services Provider ID');
        return;
      }

      const data = {
        id: selectedWorkflowId,
      }
      const requestOptions = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        body: JSON.stringify(data)
      };

      const response = await fetch(`${baseURL}/workflows/${selectedWorkflowId}`, requestOptions);
      if (response.ok) {
        toast.success("Workflow removed successfully");
        setShowPreloader(true);
        handleClose();
        window.location.reload();
      } else {
        handleNotOkHttpResponse(response);
        return false;
      }
    } catch (error) {
      console.error("Error on removing workflow:", error.message);
    } finally {
      setShowPreloader(false);
    }
  };

  const profileMenuItems = [
    // {
    //   id: 1,
    //   name: "Edit",
    //   icon: "flaticon-transfer-1",
    //   routerPath: "/workflow/edit",
    // },
    // {
    //   id: 2,
    //   name: "Delete",
    //   icon: "flaticon-transfer-1",
    //   onClick: (workflow) => {
    //     handleShow(workflow.id)
    //   },
    // },
    {
      id: 1,
      name: "WorkFlow Processes",
      icon: "flaticon-transfer-1",
      routerPath: "/workflowprocesses/workflow",
    },
    {
      id: 2,
      name: "Workflow Statuses",
      icon: "flaticon-transfer-1",
      routerPath: "/workflowstatuses",
    }

  ];


  return (
    <>
      <Preloader show={showPreloader} />
      <div className="page-wrapper">
        <SideBarWrapper />
        <div className="page-content">
          <Navbar />
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item active">All WorkFlows</li>
            </ol>
            <ul className="app-actions"></ul>
          </div>
          <div className="main-container">
            <div className="card">
              <div className="card-header">
                <div className="card-title">List of WorkFlows</div>
              </div>
              <div className="card-body pt-0">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="table-responsive">
                      <table id="data-table" className="table custom-table">
                        <thead className="thead-dark">
                          <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th><div className="float-end">Actions</div></th>
                          </tr>
                        </thead>
                        <tbody>
                          {workflows.map((workflow) => (
                            <tr key={workflow.id}>
                              <td>{workflow.name}</td>
                              <td>{workflow.description}</td>
                              <td className="">
                                <ul>
                                  <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                    <div className="dropdown">
                                      <a
                                        className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                        role="button"
                                        href="#"
                                        id={"dropdownMenuLink" + workflow.id}
                                        data-bs-toggle="dropdown"
                                      >
                                        Actions <i className="fa fa-caret-down"></i>
                                      </a>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby={"dropdownMenuLink" + workflow.id}
                                      >
                                        {profileMenuItems.map((actionMenuItem) => (
                                          <li key={workflow.id + '-' + actionMenuItem.id}>
                                            {actionMenuItem.onClick ? (
                                              <a
                                                href="#"
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                  e.preventDefault(); // Prevent default anchor behavior
                                                  actionMenuItem.onClick(workflow);
                                                }}
                                                style={
                                                  isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                    ? { color: "#ff5a5f" }
                                                    : undefined
                                                }
                                              >
                                                <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                              </a>
                                            ) : (
                                              <Link
                                                to={actionMenuItem.routerPath + "/" + workflow.id} // Changed href to to
                                                className="dropdown-item"
                                                style={
                                                  isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                    ? { color: "#ff5a5f" }
                                                    : undefined
                                                }
                                              >
                                                <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                              </Link>
                                            )}
                                          </li>
                                        ))}

                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                          <Modal.Title style={{ color: 'white' }}>Delete Workflow</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p className="mb-4">
                            Are you sure you want to delete this workflow?
                          </p>
                        </Modal.Body>
                        <Modal.Footer style={{ borderTop: 'none' }}>
                          <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleClose}>
                            Cancel
                          </Button>
                          <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveWorkflow}>
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkFLow;
