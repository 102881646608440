import React, { useEffect } from 'react';

const ChangeLogTable = ({ logs }) => {
  const $ = require("jquery");

  useEffect(() => {
    if (logs && logs.length > 0) {
      //Initialize DataTable when records are available
      $(document).ready(function () {
        const dataTable = $("#data-table").DataTable();

        // Destroy DataTable if it exists
        if (dataTable) {
          dataTable.destroy();
        }

        $("#data-table").DataTable({
          // Add any required DataTable options here
          columnDefs: [
            { width: "15%", targets: 0 },
            { width: "12%", targets: 2 }
          ]
        });
      });
    }
  }, [logs]);
  return (
    <div className="form-group row gutters mt-4">
      <div className="col-sm-12">
        <table id="data-table" className="table custom-table">
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>Crop</th>
              <th>Date</th>
              <th>Quantity</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{log.cropName}</td>
                <td>{new Date(log.date).toLocaleDateString()}</td>
                <td>{log.quantity}</td>
                <td>{log.details}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChangeLogTable;
