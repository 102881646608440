import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL } from "../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { getFullCropName } from "../../utils/helpers";
import FieldPreparationTable from "./FieldPreparationTable";

function FieldPreparationListing({ cropId }) {
    const { user } = useAuth();
    const [fieldPreparationRecords, setFieldPreparationRecords] = useState([]);
    const [crop, setCrop] = useState();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);


    useEffect(() => {
        const fetchCrops = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
                toast.warn("You seem to have lost your access token. Please try again later.");
                return;
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/crops/${cropId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching crops');
                }
                const data = await response.json();
                setCrop(data);
            } catch (error) {
                toast.error("Error fetching crops.");
                console.error("Error fetching crops:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchCrops();
    }, [user, navigate]);

    
    useEffect(() => {
        const fetchFieldPreparation = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
                toast.warn("You seem to have lost your access token. Please try again later.");
                return;
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/fieldpreparationrecords/crop/${cropId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching field preparation records');
                }
                const data = await response.json();
                setFieldPreparationRecords(data);
            } catch (error) {
                toast.error("Error fetching field preparation records.");
                console.error("Error fetching field preparation records:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchFieldPreparation();
    }, [cropId]);

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">List of Field Preparation Records</div>
                            <div className="mt-3 p-2 parentLabel">
                                <div className="mt-2 mb-1">
                                    <span>Crop: </span>{getFullCropName(crop)}
                                    <Link to={`/crops/field/${crop && crop.fieldId}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                                </div>
                            </div>
                            <br/>
                            <Link
                                to={`/fieldpreparationrecord/create/?crop=${cropId}`}
                                className="btn btn-primary float-end"
                            >
                                <i className="icon-add"></i> Add Field Preparation Record
                            </Link>
                        </div>
                        <FieldPreparationTable fieldPreparationRecords={fieldPreparationRecords} allowEdit={true} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FieldPreparationListing;