import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse, hasAdminRole } from "../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { isSinglePageActive } from "../../utils/daynamicNavigation";
import Preloader from "../common/Preloader";
import { displayDateTime, isoToDate } from "../../utils/helpers";
import { Button, Modal } from "react-bootstrap";
import LogisticsFormBasic from "./LogisticsFormBasic";

function LogisticsTable({ logisticsRecords, logisticsRecord, crop, setLogisticsRecord,
    allowEdit, handleModalShow, handleSubmit, showModal, handleModalClose, logisticsId, allowModalEdit }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const isAdmin = user && user.user && hasAdminRole(user.user.roles);


    const profileMenuItems = [
        {
            id: 1,
            name: "View",
            icon: "icon-eye1",
            onClick: (process) => {
                handleShowViewModal(process);
            },
        },
        {
            id: 2,
            name: "Edit",
            icon: "icon-edit1",
            authType: 'admin',
            onClick: (record) => {
                if (allowModalEdit) {
                    handleModalShow(record, true); // Open modal for editing
                } else {
                    // Redirect to edit page if modal edit is not allowed
                    window.location.href = `/logisticsrecord/edit/${record.id}`;
                }
            },
        },
        {
            id: 3,
            name: "Delete",
            icon: "icon-delete",
            onClick: (process) => {
                handleShowDeleteModal(process);
            },
            authType: 'admin',
        },
        {
            id: 4,
            name: "Generate Report",
            icon: "icon-file-text",
            routerPath: "/logisticsrecord/report",
            authType: 'admin',
        }
    ];

    // Define the path pattern for user profiles
    const $ = require("jquery");

    useEffect(() => {
        if (logisticsRecords && logisticsRecords.length > 0) {
            //Initialize DataTable when users are available
            $(document).ready(function () {
                const dataTable = $("#data-table").DataTable();

                // Destroy DataTable if it exists
                if (dataTable) {
                    dataTable.destroy();
                }

                $("#data-table").DataTable({
                    // Add any required DataTable options here
                    columnDefs: [
                        // { width: "4%", targets: 1 },
                        // { width: "12%", targets: 3 }
                    ]
                });
            });
        }
    }, [logisticsRecords, $]);

    const handleShowViewModal = (record) => {
        setSelectedRecord(record);
        setShowViewModal(true);
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setSelectedRecord(null);
    };

    const handleShowDeleteModal = (record) => {
        setSelectedRecord(record);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedRecord(null);
    };

    const handleRemoveLogistic = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }

        setShowPreloader(true);
        try {
            if (!selectedRecord) {
                console.error('No selected Record');
                return;
            }

            const requestOptions = {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow",
            };

            const response = await fetch(`${baseURL}/logisticsrecords/${selectedRecord.id}`, requestOptions);
            if (response.ok) {
                toast.success("Logistic Record removed successfully");
                handleCloseDeleteModal();
                setShowPreloader(true);
                window.location.reload();
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on removing Logistic Record:", error.message);
        } finally {
            setShowPreloader(false);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="card-body pt-0">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="table-responsive">
                            <table id="data-table" className="table custom-table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Code</th>
                                        <th>GIAI</th>
                                        <th>Date</th>
                                        <th>Dispatch Date</th>
                                        <th>Expected Delivery Date</th>
                                        <th>Transport Mode</th>
                                        <th>Departure Location</th>
                                        <th>Destination Location</th>
                                        {/* <th>Logistics Provider</th> */}
                                        <th>Quantity</th>
                                        <th>Logistics Status</th>
                                        <th><div className="float-end">Actions</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logisticsRecords && logisticsRecords.length > 0 ? logisticsRecords.map((logisticsRecord, index) => (
                                        <tr key={index}>
                                            <td>{logisticsRecord.code ? logisticsRecord.code : '--'}</td>
                                            <td>{logisticsRecord.giai ? logisticsRecord.giai : '--'}</td>
                                            <td style={{ width: '5rem' }}>{isoToDate(logisticsRecord.date)}</td>
                                            <td>{displayDateTime(logisticsRecord.dispatchTime)}</td>
                                            <td>{displayDateTime(logisticsRecord.expectedDeliveryTime)}</td>
                                            <td>{logisticsRecord.transportMode}</td>
                                            <td>{logisticsRecord.departureLocation}</td>
                                            <td>{logisticsRecord.destinationLocation}</td>
                                            {/* <td>{logisticsRecord.logisticsProvider}</td> */}
                                            <td>{logisticsRecord.quantity} {logisticsRecord.quantityUnit}</td>
                                            <td>{logisticsRecord.status}</td>
                                            <td className="">
                                                <ul>
                                                    <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                                        <div className="dropdown">
                                                            <Link
                                                                className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                                                role="button"
                                                                href="#"
                                                                id={"dropdownMenuLink" + logisticsRecord.id}
                                                                data-bs-toggle="dropdown"
                                                            >
                                                                Actions <i className="fa fa-caret-down"></i>
                                                            </Link>
                                                            <ul
                                                                className="dropdown-menu"
                                                                aria-labelledby={"dropdownMenuLink" + logisticsRecord.id}
                                                            >
                                                                {profileMenuItems
                                                                    .filter(item => !item.authType || (item.authType === "admin" && isAdmin)) // Filter items based on role
                                                                    .map(actionMenuItem => (
                                                                        <li key={logisticsRecord.id + '-' + actionMenuItem.id}>
                                                                            {actionMenuItem.onClick ? (
                                                                                <Link
                                                                                    href="#"
                                                                                    className="dropdown-item"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault(); // Prevent default anchor behavior
                                                                                        actionMenuItem.onClick(logisticsRecord);
                                                                                    }}
                                                                                    style={
                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                            ? { color: "#ff5a5f" }
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                </Link>
                                                                            ) : (
                                                                                <Link
                                                                                    to={actionMenuItem.routerPath + "/" + logisticsRecord.id} // Changed href to to
                                                                                    className="dropdown-item"
                                                                                    style={
                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                            ? { color: "#ff5a5f" }
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                </Link>
                                                                            )}
                                                                        </li>
                                                                    ))}

                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )) : (<tr><td colSpan="10" className="text-center"><em>No data available!</em></td></tr>)}
                                </tbody>
                            </table>
                            {/* Delete Modal */}
                            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                                <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                    <Modal.Title style={{ color: 'white' }}>Delete Logistics Record</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="mb-4">
                                        Are you sure you want to delete this logistics record?
                                    </p>
                                </Modal.Body>
                                <Modal.Footer style={{ borderTop: 'none' }}>
                                    <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleCloseDeleteModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveLogistic}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* View Modal */}
                            <Modal size="lg" show={showViewModal} onHide={handleCloseViewModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Logistics Record Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedRecord ? (
                                        <div className="formView">
                                            <p><strong>Code:</strong> {selectedRecord.code ? selectedRecord.code : "n/a"}</p>
                                            <p><strong>GIAI:</strong> {selectedRecord.giai ? selectedRecord.giai : "n/a"}</p>
                                            <p><strong>Date:</strong> {isoToDate(selectedRecord.date)}</p>
                                            <p><strong>Logistics Provider:</strong> {selectedRecord.logisticsProvider}</p>
                                            <p><strong>Transport Mode:</strong> {selectedRecord.transportMode}</p>
                                            <p><strong>Dispatch Time:</strong> {displayDateTime(selectedRecord.dispatchTime)}</p>
                                            <p><strong>Expected Delivery Time:</strong> {displayDateTime(selectedRecord.expectedDeliveryTime)}</p>
                                            <p><strong>Actual Delivery Time:</strong> {displayDateTime(selectedRecord.actualDeliveryTime)}</p>
                                            <p><strong>Departure Location:</strong> {selectedRecord.departureLocation}</p>
                                            <p><strong>Destination Location:</strong> {selectedRecord.destinationLocation}</p>
                                            <p><strong>Quantity:</strong> {selectedRecord.quantity} {selectedRecord.quantityUnit}</p>
                                            <p><strong>Transport Conditions:</strong> {selectedRecord.transportConditions}</p>
                                            <p><strong>Vehicle Details:</strong> {selectedRecord.vehicleDetails}</p>
                                            <p><strong>Logistics Status:</strong> {selectedRecord.status}</p>
                                            <p><strong>Comments:</strong> {selectedRecord.comments}</p>

                                            {/* Environmental Conditions */}
                                            <hr />
                                            <h4>Environmental Conditions</h4>
                                            {selectedRecord.environmentalCondition ? (
                                                <div>
                                                    <p><strong>Temperature:</strong> {selectedRecord.environmentalCondition.temperature}</p>
                                                    <p><strong>Weather Conditions:</strong> {selectedRecord.environmentalCondition.weatherConditions}</p>
                                                    <p><strong>Humidity:</strong> {selectedRecord.environmentalCondition.humidity ? selectedRecord.environmentalCondition.humidity : "N/A"}</p>
                                                    <p><strong>Rainfall:</strong> {selectedRecord.environmentalCondition.rainfall ? selectedRecord.environmentalCondition.rainfall : "N/A"}</p>
                                                    <p><strong>Wind Speed:</strong> {selectedRecord.environmentalCondition.windSpeed ? selectedRecord.environmentalCondition.windSpeed : "N/A"}</p>
                                                    <p><strong>Recorded Date:</strong> {isoToDate(selectedRecord.environmentalCondition.recordedDate)}</p>
                                                    <p><strong>Effects:</strong> {selectedRecord.environmentalCondition.effects}</p>
                                                </div>
                                            ) : (
                                                <p>No environmental conditions data available.</p>
                                            )}
                                        </div>
                                    ) : (
                                        <p>No record selected.</p>
                                    )}

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseViewModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal size="lg" show={showModal} onHide={handleModalClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{logisticsId ? "Edit Logistics Record" : "Add Logistics Record"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <LogisticsFormBasic
                                        crop={crop}
                                        logisticsRecord={logisticsRecord}
                                        setLogisticsRecord={setLogisticsRecord}
                                        handleSubmit={handleSubmit}
                                    />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LogisticsTable;