import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Preloader from "../common/Preloader";
import {fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getTransferDestinations, getTransferMethods, getTransferOrigins, getTransferQuantityUnit } from "../../api/TransferRecords/getTransferList";
import EnvironmentalConditionNew from "../common/EnvironmentalConditionNew";

const TransferFormBasic = ({ crop, transferRecord, setTransferRecord, handleSubmit }) => {
  const { user } = useAuth();

  const [quantityUnitList, setQuantityUnitList] = useState([]);
  const [transportMethodList, setTransportMethodList] = useState([]);
  const [transferOriginList, setTransferOriginList] = useState([]);
  const [transferDestinationList, setTransferDestinationList] = useState([]);

  //env conditions data
  const [environmentalCondition, setEnvironmentalCondition] = useState();

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getSetUpData();
  }, []);

  useEffect(() => {
    if (transferRecord) {
      if (transferRecord.environmentalCondition) {
        setEnvironmentalCondition(transferRecord.environmentalCondition);
      }
    }
  }, [transferRecord]);
  
  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    try {
      setShowPreloader(true);
      setQuantityUnitList(await getTransferQuantityUnit(user.user.token));
      setTransportMethodList(await getTransferMethods(user.user.token));
      setTransferOriginList(await getTransferOrigins(user.user.token));
      setTransferDestinationList(await getTransferDestinations(user.user.token));
    } catch {} finally {
      setShowPreloader(false);
    }
  };
  const validateFields = () => {
    let retVal = true;

    if (!transferRecord.date) {
      fieldWithIdRequired('date');
      toast.error("Date is required.");
      retVal = false;
    }

    if (!transferRecord.destination) {
      fieldWithIdRequired('destination');
      toast.error("Destination is required.");
      retVal = false;
    }

    if (!origin) {
      fieldWithIdRequired('origin');
      toast.error("Origin is required.");
      retVal = false;
    }

    if (!transferRecord.transporterName) {
      fieldWithIdRequired('transporterName');
      toast.error("Transporter Name is required.");
      retVal = false;
    }

    if (!transferRecord.transportMethod) {
      fieldWithIdRequired('transportMethod');
      toast.error("Transport Method is required.");
      retVal = false;
    }

    if (!transferRecord.quantity) {
      fieldWithIdRequired('quantity');
      toast.error("Quantity is required.");
      retVal = false;
    }

    if (!transferRecord.quantityUnit) {
      fieldWithIdRequired('quantityUnit');
      toast.error("Quantity Unit is required.");
      retVal = false;
    }

    if (!transferRecord.transporterContact) {
      fieldWithIdRequired('transporterContact');
      toast.error("Transporter Contact is required.");
      retVal = false;
    }

    // environmental conditions data

    if (!environmentalCondition.effects) {
      fieldWithIdRequired('effects');
      toast.error("Effects field is required.");
      retVal = false;
    }

    if (!environmentalCondition.recordedDate) {
      fieldWithIdRequired('recordedDate');
      toast.error("Recorded Date is required.");
      retVal = false;
    }

    if (!environmentalCondition.temperature) {
      fieldWithIdRequired('temperature');
      toast.error("Temperature is required.");
      retVal = false;
    }

    if (!environmentalCondition.weatherConditions) {
      fieldWithIdRequired('weatherConditions');
      toast.error("Whether Conditions is required.");
      retVal = false;
    }

    return retVal;
  };

  const handleChange = (field, value, required) => {
    const updatedItem = { ...transferRecord }; // Create a copy of the items array
    updatedItem[field] = value; // Update the value in the copy
    setTransferRecord(updatedItem); // Update the state with the modified copy
    if(required) fieldWithIdRequired(field); //Show UI validation
  };

  return (
    <>
      <Preloader show={showPreloader} />
              
      <div className="row gutters">

        {transferRecord && transferRecord.id && 
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
            <div className="form-group">
              <label htmlFor="name">Code</label>
              <input
                type="text"
                className="form-control"
                id="code"
                value={transferRecord.code ? transferRecord.code : '--'}
                readOnly
              />
            </div>
          </div>
        }

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="name">Date *</label>
            <input
              type="date"
              className="form-control"
              id="date"
              value={isoToDate(transferRecord && transferRecord.date)}
              onChange={(e) => handleChange("date", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="giai">GIAI</label>
            <input
              type="text"
              className="form-control"
              id="giai"
              placeholder="Enter the GIAI..."
              value={transferRecord && transferRecord.giai}
              onChange={(e) => handleChange("giai", e.target.value, false)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Origin *</label>
            <select
              className="form-control form-control-lg"
              id="origin"
              value={transferRecord && transferRecord.origin}
              onChange={(e) => handleChange("origin", e.target.value, true)}
            >
              <option value="">Select Origin...</option>
              {transferOriginList && transferOriginList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Destination *</label>
            <select
              className="form-control form-control-lg"
              id="source"
              value={transferRecord && transferRecord.destination}
              onChange={(e) => handleChange("destination", e.target.value, true)}
            >
              <option value="">Select Destination...</option>
              {transferDestinationList && transferDestinationList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="quantity">Quantity *</label>
            <input
              type="number"
              className="form-control"
              id="quantity"
              placeholder="Enter the quantity..."
              value={transferRecord && transferRecord.quantity}
              onChange={(e) => handleChange("quantity", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="quantityUnit">Quantity Unit *</label>
            <select
              className="form-control form-control-lg"
              id="quantityUnit"
              value={transferRecord && transferRecord.quantityUnit}
              onChange={(e) => handleChange("quantityUnit", e.target.value, true)}
            >
              <option value="">Select Quantity Type...</option>
              {quantityUnitList && quantityUnitList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Transfer Method *</label>
            <select
              className="form-control form-control-lg"
              id="transportMethod"
              value={transferRecord && transferRecord.transportMethod}
              onChange={(e) => handleChange("transportMethod", e.target.value, true)}
            >
              <option value="">Select Transfer Method...</option>
              {transportMethodList && transportMethodList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="transporterName">Transporter Name *</label>
            <input
              type="text"
              className="form-control"
              id="transporterName"
              placeholder="Enter the transporter name..."
              value={transferRecord && transferRecord.transporterName}
              onChange={(e) => handleChange("transporterName", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Transporter Contact *</label>
            <input
              type="tel"
              className="form-control"
              id="transporterContact"
              placeholder="Enter the transporter contact..."
              value={transferRecord && transferRecord.transporterContact}
              onChange={(e) => handleChange("transporterContact", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Vehicle Details</label>
            <input
              type="text"
              className="form-control"
              id="vehicleDetails"
              placeholder="Enter the vehicle details..."
              value={transferRecord && transferRecord.vehicleDetails}
              onChange={(e) => handleChange("vehicleDetails", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="remarks">Remarks</label>
            <textarea
              rows="2"
              className="form-control"
              id="remarks"
              placeholder="Enter the Remarks..."
              value={transferRecord && transferRecord.remarks}
              onChange={(e) => handleChange("remarks", e.target.value, false)}
            />
          </div>
        </div>
      </div>
      <br />
      <EnvironmentalConditionNew transferRecord={transferRecord} setTransferRecord={setTransferRecord} />
      <hr />
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
          <div className="float-end">
            <button type="button" className="btn btn-primary float-end"
              onClick={(e) => { if(validateFields()) { handleSubmit(e); }}}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransferFormBasic;