import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import React,{ useEffect, useState } from "react";
import Footer from "../../../layout/Footer";
import FarmerTable from "../../../components/Farmers/FarmerTable";
import { Link } from "react-router-dom";

const Farmers = () => {


    return (
        <>
            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            {/* <li className="breadcrumb-item">Farmer</li> */}
                            <li className="breadcrumb-item active">Outgrowers</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                       <FarmerTable  />
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer/>

            </div>
            {/* Page wrapper end */}
        </>
    );
}

export default Farmers;