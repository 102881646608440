import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { baseURL } from "../../utils/misc";
import useAuth from "../../hooks/useAuth";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ChangeLogTable from "./ChangeLogTable";

const ChangeLog = () => {
  const { user } = useAuth();
  const [cropList, setCropList] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [logs, setLogs] = useState([]); // Use dummy data for logs
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getSetupData();
  }, []);

  const getSetupData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch crops
    fetch(`${baseURL}/crops`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error('Error fetching Crops.');
          throw new Error('Error fetching Crops.');
        }
      })
      .then((data) => {
        setCropList(data);
      })
      .catch((error) => {
        toast.error(error.message || "Error fetching crops.");
      })
      .finally(() => { setShowPreloader(false); });
  };

  // crop list for react-select
  const cropOptions = cropList.map(crop => ({
    value: crop.id,
    label: `${crop.name} - ${crop.quantity} - ${crop.quantityUnit}`
  }));

  const handleCropChange = (selectedOption) => {
    setSelectedCrop(selectedOption);
  };

  const fetchLogs = () => {
    if (!selectedCrop || !startDate || !endDate) {
      toast.error("Please select crop, start date, and end date.");
      return;
    }

    setLoadingLogs(true);

    //testing logs data
    const testLogs = [
      { cropName: "Wheat", date: "2024-09-01", quantity: 150, details: "Harvested successfully" },
      { cropName: "Corn", date: "2024-09-05", quantity: 200, details: "Pest control applied" },
      { cropName: "Soybean", date: "2024-09-10", quantity: 180, details: "Fertilizer applied" }
    ];

    // Simulate API call delay
    setTimeout(() => {
      setLogs(testLogs);
      setLoadingLogs(false);
    }, 2000); // 1 second delay
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Select Record To Show The Logs</div>
            </div>
            <div className="card-body pt-0">
              <div className="form-group row gutters">
                <label
                  htmlFor="cropSelection"
                  className="col-sm-2 col-form-label"
                >
                  Table Name:
                </label>
                <div className="col-sm-6">
                  <Select
                    id="cropSelection"
                    options={cropOptions}
                    onChange={handleCropChange}
                    placeholder="Select Crop..."
                    isClearable
                  />
                </div>
              </div>
              {/* start date dropdown! */}
              {(selectedCrop &&
                <div className="form-group row gutters">
                  <label
                    htmlFor="startDate"
                    className="col-sm-2 col-form-label"
                  >
                    Start Date:
                  </label>
                  <div className="col-sm-6">
                    <DatePicker
                      id="startDate"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Select Start Date"
                      className="form-control"
                      dateFormat="MMMM d, yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
              )}
              {/* end date dropdown! */}
              {(startDate &&
                <div className="form-group row gutters">
                  <label
                    htmlFor="endDate"
                    className="col-sm-2 col-form-label"
                  >
                    End Date:
                  </label>
                  <div className="col-sm-6">
                    <DatePicker
                      id="endDate"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      placeholderText="-- Select End Date --"
                      className="form-control"
                      dateFormat="MMMM d, yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {(endDate &&
                    <div className="col-sm-3">
                      <button
                        className="btn btn-primary"
                        onClick={fetchLogs}
                        disabled={loadingLogs}
                      >
                        {loadingLogs ? 'Loading...' : 'Show Logs'}
                      </button>
                    </div>
                  )}
                </div>
              )}
              <br />
              <hr />
              {/* Import and use LogTable component */}
              {logs.length > 0 && <ChangeLogTable logs={logs} />}
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default ChangeLog;