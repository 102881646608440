import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getInvitationByCode } from "../../../api/Invitations/getInvitationByCode";
import Preloader from "../../../components/common/Preloader";
import { toast } from "react-toastify";

const RegisterAsIndex = () => {
  const { uniqueCode } = useParams();
  const navigate = useNavigate();

  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getSetUpData();
  }, []);

  const getSetUpData = async () => {
    try {
      setShowPreloader(true);
      const invitation = await getInvitationByCode(uniqueCode);

      if(!invitation) {
        toast.warn("The registration token submitted is invalid!");
        navigate('/');
        return;
      } else {
        const theTarget = invitation.target.toUpperCase();
        if(theTarget === "EXPORTER") {
          //Open the Exporter registration form
          navigate("/exporter/register/" + uniqueCode);
        } else if (theTarget === "OUTGROWER") {
          //Open the Farmer registration form
          navigate("/outgrower/register/" + uniqueCode);
        } else if (theTarget === "BUYER") {
          //Open the Importer registration form
          navigate("/buyer/register/" + uniqueCode);
        } else {
          toast.warn("The invitation target type is unidentified!");
        }
      }
    } catch {} finally {
      setShowPreloader(false);
    }
  };
  return (
    <>
      <Preloader show={showPreloader} />
      {/* We don't intend show any view for this component! */}
    </>
  );
};

export default RegisterAsIndex;
