import { baseURL } from "../../utils/misc";


export const getLogisticsQuantityUnit = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/miscellaneous/quantity-units`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Quantity Units!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};


export const getTransportModes = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/logisticsrecords/transport-modes`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Transport Modes!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};


export const getDocumentationOptions = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/logisticsrecords/documentation-options`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Documentation Options!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};

export const getLogisticsStatuses = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/logisticsrecords/logistics-statuses`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Logistic Statuses!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
}

export const getLogisticsRecordsByCropId = async (token, cropId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/logisticsrecords/crop/${cropId}`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Logistics Records!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};