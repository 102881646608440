
import { toast } from "react-toastify";
import { baseURL } from "../../utils/misc";


export const getGetWorkflowRecordActivityById = async (token, workflowRecordActivityId) => {
  
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/workflowrecordactivities/${workflowRecordActivityId}`, requestOptions)
  .then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      console.error("Error getting the Workflow Record Activity.");
      return;
    }
  })
  .then((data) => {
    return data;
  })
  .catch((error) => {
    toast.error("Error getting the Workflow Record Activity.");
    return;
  });
};
