import { useEffect} from "react";
import useAuth from "../../hooks/useAuth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { getFullCropName } from "../../utils/helpers";
import { Modal } from "react-bootstrap";
import { baseURL, handleNotOkHttpResponse, hasBuyAndAdminRole } from "../../utils/misc";
import SpoilageTable from "./SpoilageTable";
import SpoilageFormBasic from "./SpoilageFormBasic";
import { getSpoilageRecordsByCropId } from "../../api/SpoilageRecords/getSpoilageRecordsList";

function SpoilageRecords({ crop }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const [spoilageRecords, setSpoilageRecords] = useState([]);
  const [spoilageRecord, setSpoilageRecord] = useState();
  const [showModal, setShowModal] = useState(false)
  const [spoilageId, setSpoilageId] = useState(null);
  const isAdminAndBuyer = user && user.user && hasBuyAndAdminRole(user.user.roles);


  useEffect(() => {
    if (!user || !user.user || !user.user.token) {
      toast.warn("You are not authorized to do this!");
      navigate("/");
      return;
    }

    if (crop) {
      getSpoilageRecordsByCropId(user.user.token, crop.id).then(m => { setSpoilageRecords(m); });
    }
  }, [crop, user, navigate]);

  useEffect(() => {
    if (spoilageRecord) {
      setSpoilageId(spoilageRecord.id);

    }
  }, [spoilageRecord]);

  const handleModalShow = (record = null) => {
    setSpoilageRecord(record || {
      cropId: crop.id,
      dateReported: '',
      quantityAffected: null,
      quantityUnit: '',
      spoilageType: '',
      causes: '',
      reportedBy: '',
      actionsTaken: '',
      inspectionDetails: '',
      comments: '',
      attachments: ''
    });
    setSpoilageId(record ? record.id : null);
    setShowModal(true);
  };


  const handleModalClose = () => {
    setShowModal(false);
  };


  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const data = {
      cropId: crop.id,
      dateReported: spoilageRecord.dateReported,
      quantityAffected: spoilageRecord.quantityAffected,
      quantityUnit: spoilageRecord.quantityUnit,
      causes: spoilageRecord.causes,
      comments: spoilageRecord.comments,
      reportedBy: spoilageRecord.reportedBy,
      spoilageType: spoilageRecord.spoilageType,
      actionsTaken: spoilageRecord.actionsTaken,
      inspectionDetails: spoilageRecord.inspectionDetails,
      attachments: spoilageRecord.attachments,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/spoilagereports`, requestOptions);

      if (response.ok) {
        toast.success("The Spoilage Record has been successfully created.");
        getSpoilageRecordsByCropId(user.user.token, crop.id).then(records => {
          setSpoilageRecords(records);
        });
        handleModalClose();
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the Spoilage record.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const data = {
      id: spoilageId,
      cropId: crop.id,
      dateReported: spoilageRecord.dateReported,
      quantityAffected: spoilageRecord.quantityAffected,
      quantityUnit: spoilageRecord.quantityUnit,
      causes: spoilageRecord.causes,
      comments: spoilageRecord.comments,
      reportedBy: spoilageRecord.reportedBy,
      spoilageType: spoilageRecord.spoilageType,
      actionsTaken: spoilageRecord.actionsTaken,
      inspectionDetails: spoilageRecord.inspectionDetails,
      attachments: spoilageRecord.attachments,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/spoilagereports/${spoilageId}`, requestOptions);

      if (response.ok) {
        toast.success("The spoilage record has been successfully updated.");
        getSpoilageRecordsByCropId(user.user.token, crop.id).then(records => {
          setSpoilageRecords(records);
        });
        handleModalClose();
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the spoilageing record.");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      <div className="main-container">
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card" style={{ backgroundColor: "#eff1f5" }}>
              <div className="card-header">
                <div className="card-title">
                  Spoilage Records {crop ? " - " + getFullCropName(crop) : ""}
                </div>
                {isAdminAndBuyer && (
                  <Link
                    to="#"
                    onClick={() => handleModalShow()}
                    className="btn btn-primary float-end"
                  >
                    <i className="icon-add"></i> Add Spoilage Record
                  </Link>
                )}
              </div>
              {spoilageRecords && spoilageRecords.length > 0 && (
                <SpoilageTable 
                  spoilagerecords={spoilageRecords}
                  spoilagerecord={spoilageRecord}
                  setSpoilageRecord={setSpoilageRecord}
                  crop={crop}
                  handleSubmit={spoilageId ? handleSubmitEdit : handleSubmitCreate}
                  handleModalShow={handleModalShow}
                  handleModalClose={handleModalClose}
                  spoilageId={spoilageId}
                  allowModalEdit={true}
                />
              )}
            </div>
            <Modal size="lg" show={showModal} onHide={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>{spoilageId ? "Edit Spoilage Record" : "Add Spoilage Record"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <SpoilageFormBasic
                  crop={crop}
                  spoilageRecord={spoilageRecord}
                  setSpoilageRecord={setSpoilageRecord}
                  handleSubmit={spoilageId ? handleSubmitEdit : handleSubmitCreate}
                />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpoilageRecords;