import { Link } from 'react-router-dom';
import SideBarWrapper from '../../../components/common/SideBarWrapper';
import Invitations from '../../../components/Registration/Invitations/Invitations';
import Footer from '../../../layout/Footer';
import Navbar from '../../../layout/Navbar';

const InvitationsIndex = () => {
  return (
    <>
      <div className="page-wrapper">
        <SideBarWrapper />
        <div className="page-content">
          <Navbar />
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">All Invitations</li>
            </ol>
            <ul className="app-actions"></ul>
          </div>
          <div className="main-container">
            <Invitations />
          </div>
        </div>
        <Footer/>

      </div>
    </>
  );
};

export default InvitationsIndex;
