import { Link, useParams } from "react-router-dom";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import React,{ useEffect, useState } from "react";
import Footer from "../../../layout/Footer";
import { getFarmById } from "../../../api/Farms/getFarmById";
import useAuth from "../../../hooks/useAuth";
import FieldListing from "../../../components/Field/FieldListing";

const Fields = () => {
    const { farmId } = useParams();
    const { user } = useAuth();
    const [farm, setFarm] = useState();

    useEffect(() => {
        const fetchField = async () => {
            if (farmId) {
                const fieldData = await getFarmById(user.user.token, farmId);
                setFarm(fieldData);
            }
        };
    
        fetchField();
    }, [farmId, user.user.token]);

    return (
        <>



            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item">
                                <Link to={`/farms/farmer`}>Farm</Link>
                            </li>
                            <li className="breadcrumb-item">{farm && farm.farmCode}</li>
                            <li className="breadcrumb-item active">Fields</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                        <FieldListing farmId={farmId} />
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer/>

            </div>
            {/* Page wrapper end */}
        </>
    );
}

export default Fields;