import { Link, useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../components/common/SideBarWrapper";
import Navbar from "../../layout/Navbar";
import React,{ useEffect, useState } from "react";
import { baseURL } from "../../utils/misc";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import Contracts from "../../components/Contracts/Contracts";

const ContractsIndex = () => {
    return (
        <>
            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item">Contracts</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                        <Contracts />
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}

            </div>
            {/* Page wrapper end */}
        </>
    );
}

export default ContractsIndex;