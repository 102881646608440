import { useEffect} from "react";
import useAuth from "../../hooks/useAuth";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { getFullCropName } from "../../utils/helpers";
import { getTransferRecordsByCropId } from "../../api/TransferRecords/getTransferList";
import TransferTable from "./TransferTable";
import { Modal } from "react-bootstrap";
import TransferFormBasic from "./TransferFormBasic";
import { baseURL, handleNotOkHttpResponse, hasAdminRole } from "../../utils/misc";

function TransferRecords({ crop }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [transferRecords, setTransferRecords] = useState([]);
    const [transferRecord, setTransferRecord] = useState();
    const [showModal, setShowModal] = useState(false)
    const [transferId, setTransferId] = useState(null);
    const isAdmin = user && user.user && hasAdminRole(user.user.roles);

    //env conditions data
    const [environmentalCondition, setEnvironmentalCondition] = useState();
    const [environmentalConditionId, setEnvironmentalConditionId] = useState('');


    useEffect(() => {
        if(!user || !user.user || !user.user.token){
            toast.warn("You are not authorized to do this!");
            navigate("/");
            return;
        }

        if (crop) {
            getTransferRecordsByCropId(user.user.token, crop.id)
                .then(records => setTransferRecords(records))
                .catch(error => {
                    toast.error("Error fetching logistics records.");
                    console.error(error);
                });
        }
    }, [crop, user, navigate]);

    useEffect(() => {
        if (transferRecord) {
          setTransferId(transferRecord.id);
    
          if (transferRecord.environmentalCondition) {
            setEnvironmentalConditionId(transferRecord.environmentalCondition.id);
            setEnvironmentalCondition(transferRecord.environmentalCondition);
          }
        }
      }, [transferRecord]);

    const handleModalShow = (record = null) => {
        setTransferRecord(record || {
            cropId: crop.id,
            date: '',
            environmentalCondition: {
                temperature: '',
                weatherConditions: '',
                humidity: '',
                rainfall: '',
                windSpeed: '',
                recordedDate: '',
                effects: ''
            },
            origin: '',
            destination: '',
            quantity: null,
            quantityUnit: '',
            transportMethod: '',
            transporterName: '',
            transporterContact: '',
            vehicleDetails: '',
            remarks: ''
        });
        setTransferId(record ? record.id : null);
        setShowModal(true);
    };


    const handleModalClose = () => {
        setShowModal(false);
    };


    const handleSubmitCreate = async (e) => {
        // setShowPreloader(true);
        e.preventDefault();

        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }

        const data = {
            cropId: crop.id,
            date: transferRecord.date,
            destination: transferRecord.destination,
            origin: transferRecord.origin,
            transporterContact: transferRecord.transporterContact,
            remarks: transferRecord.remarks,
            vehicleDetails: transferRecord.vehicleDetails,
            transportMethod: transferRecord.transportMethod,
            transporterName: transferRecord.transporterName,
            quantity: transferRecord.quantity,
            quantityUnit: transferRecord.quantityUnit,
            environmentalCondition
        };


        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "text/plain",
                Authorization: `Bearer ${user.user.token}`,
            },
            body: JSON.stringify(data),
            redirect: "follow",
        };
        setShowPreloader(true);
        try {
            const response = await fetch(`${baseURL}/transferrecords`, requestOptions);

            if (response.ok) {
                toast.success("The Transfer Record has been successfully created.");
                // Fetch updated records list
                getTransferRecordsByCropId(user.user.token, crop.id).then(records => {
                    setTransferRecords(records);
                });
                handleModalClose();
            } else {
                handleNotOkHttpResponse(response);
            }
        } catch (error) {
            toast.error("Error creating the transferrecords.");
        } finally {
            setShowPreloader(false);
        }
    };
    const handleSubmitEdit = async (e) => {
        e.preventDefault();

        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }

        const data = {
            id: transferId,
            cropId: crop.id,
            date: transferRecord.date,
            destination: transferRecord.destination,
            origin: transferRecord.origin,
            transporterContact: transferRecord.transporterContact,
            remarks: transferRecord.remarks,
            vehicleDetails: transferRecord.vehicleDetails,
            transportMethod: transferRecord.transportMethod,
            transporterName: transferRecord.transporterName,
            quantity: transferRecord.quantity,
            quantityUnit: transferRecord.quantityUnit,
            environmentalConditionId,
            environmentalCondition
        };

        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "text/plain",
                Authorization: `Bearer ${user.user.token}`,
            },
            body: JSON.stringify(data),
            redirect: "follow",
        };
        setShowPreloader(true);
        try {
            const response = await fetch(`${baseURL}/transferrecords/${transferId}`, requestOptions);

            if (response.ok) {
                toast.success("The Transfer Record has been successfully updated.");
                getTransferRecordsByCropId(user.user.token, crop.id).then(records => {
                    setTransferRecords(records);
                });
                handleModalClose();
            } else {
                handleNotOkHttpResponse(response);
            }
        } catch (error) {
            toast.error("Error updating the transfer record.");
        } finally {
            setShowPreloader(false);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="main-container">
                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="card" style={{backgroundColor: "#eff1f5"}}>
                            <div className="card-header">
                                <div className="card-title">
                                    Transfer Records {crop ? " - " + getFullCropName(crop) : ""}
                                </div>
                                {isAdmin && (
                                <Link
                                    to="#"
                                    onClick={() => handleModalShow()}
                                    className="btn btn-primary float-end"
                                >
                                    <i className="icon-add"></i> Add Transfer Record
                                </Link>
                                )}
                            </div>
                            {transferRecords && transferRecords.length > 0 && (
                                <TransferTable 
                                    transferRecords={transferRecords}
                                    transferRecord={transferRecord}
                                    setTransferRecord={setTransferRecord}
                                    crop={crop}
                                    handleSubmit={transferId ? handleSubmitEdit : handleSubmitCreate}
                                    handleModalShow={handleModalShow}
                                    handleModalClose={handleModalClose}
                                    transferId={transferId}
                                    allowModalEdit={true}
                                />
                            )}
                        </div>
                        <Modal size="lg" show={showModal} onHide={handleModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>{transferId ? "Edit Transfer Record" : "Add Transfer Record"}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <TransferFormBasic
                                    crop={crop}
                                    transferRecord={transferRecord}
                                    setTransferRecord={setTransferRecord}
                                    handleSubmit={transferId ? handleSubmitEdit : handleSubmitCreate}
                                />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TransferRecords;