import { baseURL } from "../../utils/misc";


export const getProcessActivities = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(`${baseURL}/manufacturingprocesses/manufacturing-process-activities`, requestOptions);

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    const data = await response.json();

    // Ensure data is in the expected format
    if (Array.isArray(data)) {
      return data;
    } else {
      throw new Error('Unexpected data format.');
    }
  } catch (error) {
    console.error("Error fetching manufacturing process activities!", error);
    throw error; // Rethrow the error to propagate it to the caller
  }
};

