import { toast } from "react-toastify";
import { baseURL } from "../../utils/misc";


 export const getLifeSpan = async () => {
  const requestOptions = {
    method: "GET",
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/crops/lifespan-units`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Lifespan!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};


export const fetchCrops = async (token) => {
  const requestOptions = {
      method: 'GET',
      headers: {
          Authorization: `Bearer ${token}`,
      }
  };

  try {
      const response = await fetch(`${baseURL}/crops`, requestOptions);
      if (!response.ok) {
          throw new Error('Error fetching crops');
      }
      const data = await response.json();
      return data;
  } catch (error) {
      toast.error("Error fetching crops.");
      console.error("Error fetching crops:", error);
      throw error;
  }
};

