import { Navigate } from "react-router";
import useAuth from "../../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from 'react-router-dom';
import Preloader from "../../../components/common/Preloader";


const WorkFlowStagesForm = ({ workflowData }) => {
  const { user } = useAuth();
  const [workFlowProcessList, setWorkflowProcessList] = useState([]);
  const [workflowProcessId, setWorkflowProcessId] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [order, setOrder] = useState('');
  const [formURL, setFormURL] = useState('');
  const [workflowstagesId, setWorkFlowStagesId] = useState(null);
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  const location = useLocation();  
  const queryParams = new URLSearchParams(location.search);
  const value = queryParams.get('workflowProcess'); // Retrieve the value of a specific query parameter

  useEffect(() => {

    getWorkFlowData();

    if (workflowstagesId) {
      getWorkFlowProcessData(workflowstagesId);
    }
  }, [workflowstagesId])

  useEffect(() => {
    if (value) {
      setWorkflowProcessId(value);
    }
  }, [value]);

  useEffect(() => {
    if (workflowData) {
      setWorkflowProcessId(workflowData.workflowProcessId);
      setName(workflowData.name);
      setDescription(workflowData.description);
      setOrder(workflowData.order);
      setFormURL(workflowData.formURL);
      setWorkFlowStagesId(workflowData.id);
    }
  }, [workflowData]);

  const getWorkFlowData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);
    // Fetch user data when the component mounts
    fetch(`${baseURL}/workflowprocesses`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error('Error fetching users')
        }
      })
      .then((data) => {
        // Set the useState valuations with the fetched data
        setWorkflowProcessList(data);
      })
      .catch((error) => {
        toast.error("Error fetching WorkFlowProcess Types.");
      })
      .finally(()=>{
        setShowPreloader(false);
      })
  };

  const getWorkFlowProcessData = async (workflowstagesId) => {

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);
    // Fetch service data when the component mounts
    fetch(`${baseURL}/workflowstages/${workflowstagesId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setName(data.name);
        setWorkflowProcessId(data.workflowProcessId);
        setDescription(data.description);
        setOrder(data.order);
        setFormURL(data.formURL);
      })
      .catch((error) => {
        toast.error('Error fetching workflowstages data.');
        console.error("Error fetching workflowstages data:", error);
      })
      .finally(()=>{
        setShowPreloader(false);
      })
  };


  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    // Basic field validations
    if (!name || !description || !order || !formURL) {
      toast.error("All fields are required. Please fill in all the required fields.");
      return;
    }

    const data = {
      workflowProcessId: workflowProcessId,
      name: name,
      description: description,
      order: order,
      formURL: formURL
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/workflowstages`, requestOptions);

      if (response.ok) {
        toast.success("The WorkFlowStage has been successfully initiated.");
        setShowPreloader(true);
        navigate(`/workflowstages/workflowprocess/${workflowProcessId}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error initiating the Workflowstage.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }
    
    // Basic field validations
    if (!name || !description || order == null || order == undefined || order.length == 0) {
      toast.error("All fields are required. Please fill in all the required fields.");
      return;
    }

    const data = {
      id: workflowstagesId,
      workflowProcessId: workflowProcessId,
      name: name,
      description: description,
      order: order,
      formURL: formURL
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/workflowstages/${workflowstagesId}`, requestOptions);

      if (response.ok) {
        toast.success("The WorkFlow stage has been successfully updated.");
        setShowPreloader(true);
        navigate(`/workflowstages/workflowprocess/${workflowProcessId}`);
      } else {
        console.error('response failed to update');
      }
    } catch (error) {
      toast.error("Error initiating the Workflow stage.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">WorkFlowStage</div>
            </div>
            <div className="card-body pt-0">
              <div className="form-group row gutters">
                <label
                  htmlFor="workflowProcessId"
                  className="col-sm-2 col-form-label"
                >
                  WorkFLow Process :
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="workflowProcessId"
                    value={workFlowProcessList.find(workflowProcess => workflowProcess.id === workflowProcessId)?.name || ''}
                    readOnly
                  />
                </div>
              </div>
              {/* <hr /> */}
              <div className="row gutters">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter the Name..."
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="order">Order</label>
                    <input
                      type="number"
                      className="form-control"
                      id="order"
                      placeholder="Enter the Order..."
                      value={order}
                      onChange={(e) => setOrder(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="order">FormURL</label>
                    <input
                      type="text"
                      className="form-control"
                      id="order"
                      placeholder="Enter the Order..."
                      value={formURL}
                      onChange={(e) => setFormURL(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="fillVolume">Description</label>
                    <textarea
                      rows="7"
                      className="form-control"
                      id="description"
                      placeholder="Enter the Description.."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    >
                    </textarea>
                  </div>
                </div>

              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(workflowstagesId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default WorkFlowStagesForm;