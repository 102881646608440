import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import FileInput from "../common/FileInput";

const DocumentForm = ({ documentData }) => {
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [path, setPath] = useState('');
  const [documentCode, setDocumentCode] = useState('');
  const [dateOfIssuance, setDateOfIssuance] = useState('');
  const [dateOfExpiry, setDateOfExpiry] = useState('');
  const [description, setDescription] = useState('');
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [documentId, setDocumentId] = useState();

  useEffect(() => {
    if (documentId) {
      getDocumentData(documentId);
    }
  }, [documentId])

  useEffect(() => {
    if (documentData) {
      setDocumentId(documentData.id);
      setName(documentData.name);
      setPath(documentData.path);
      setDescription(documentData.description);
      setDateOfIssuance(documentData.dateOfIssuance);
      setDateOfExpiry(documentData.dateOfExpiry);
      setDocumentCode(documentData.farmCode);

    }
  }, [documentData]);

  const getDocumentData = async () => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/documents/${documentId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        setName(data.name);
        setPath(data.path);
        setDescription(data.description);
        setDateOfIssuance(data.dateOfIssuance);
        setDateOfExpiry(data.dateOfExpiry);
        setDocumentCode(data.farmCode);
      })
      .catch((error) => {
        toast.error('Error fetching document data.');
        console.error("Error fetching document data:", error);
      })
      .finally(() => {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;
    if (!name) {
      fieldWithIdRequired('name');
      toast.error("Document Name is required.");
      retVal = false;
    }
    if (!path) {
      fieldWithIdRequired('path');
      toast.error("Document Path is required.");
      retVal = false;
    }

    if (!dateOfIssuance) {
      fieldWithIdRequired('dateOfIssuance');
      toast.error("Date Of Issuance is required.");
      retVal = false;
    }
    if (!dateOfExpiry) {
      fieldWithIdRequired('dateOfExpiry');
      toast.error("Date Of Expiry is required.");
      retVal = false;
    }
    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      name: name,
      path: path,
      description: description,
      dateOfIssuance: dateOfIssuance,
      dateOfExpiry: dateOfExpiry,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/documents`, requestOptions);

      if (response.ok) {
        toast.success("The Document has been successfully created.");
        setShowPreloader(true);
        navigate(`/documents`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the document.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: documentId,
      name: name,
      path: path,
      description: description,
      dateOfIssuance: dateOfIssuance,
      dateOfExpiry: dateOfExpiry,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/documents/${documentId}`, requestOptions);

      if (response.ok) {
        toast.success("The document has been successfully updated.");
        setShowPreloader(true);
        navigate(`/documents`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the document.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Document Form</div>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">

                {documentId &&
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="name">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={documentCode ? documentCode : '--'}
                        readOnly
                      />
                    </div>
                  </div>
                }
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Document Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter the name of the Document..."
                      value={name}
                      onChange={(e) => { setName(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>


                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="path">Path *</label>
                    <FileInput
                      id="logo"
                      value={path}
                      setValue={setPath}
                      // accept="image/*"
                      required={false}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="dateOfIssuance">Date Of Issuance *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateOfIssuance"
                      value={isoToDate(dateOfIssuance)}
                      onChange={(e) => { setDateOfIssuance(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="dateOfExpiry">Date Of Expiry *</label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateOfExpiry"
                      value={isoToDate(dateOfExpiry)}
                      onChange={(e) => { setDateOfExpiry(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Decription</label>
                    <textarea
                      rows="2"
                      className="form-control"
                      id="description"
                      placeholder="Enter the Description..."
                      value={description}
                      onChange={(e) => { setDescription(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>


              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(documentId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default DocumentForm;