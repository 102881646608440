import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import Footer from "../../../layout/Footer";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import { getCropById } from "../../../api/CropRecords/getCropById";
import ExportCustomForm from "../../../components/ExportCustomsRecords/ExportCustomForm";

const CreateExportCustomRecord = () => {

    const [crop, setCrop] = useState();
    const { user } = useAuth();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const cropId = queryParams.get('crop'); // Retrieve the value of a specific query parameter
    const [exportCustomRecord, setExportCustomRecord] = useState(null);


    useEffect(() => {
        //Instantiation record with empty values
        setExportCustomRecord(
            {
                // cropId: crop.id,
                currency: '',
                customsDeclarationNumber: '',
                destinationCountry: '',
                exporter: '',
                exportMode: '',
                portOfDischarge: '',
                portOfLoading: '',
                totalDeclaredValue: '',
                shippingLineOrAirline: '',
                flightOrVesselNumber: '',
                departureDate: '',
                arrivalDate: '',
                totalWeight: '',
                weightUnit: '',
                containerNumber: '',
                freightForwarder: '',
                handlingAgent: '',
                shippingDocuments: '',
              }
          );
        
        //Get the crop record
        getCropById(user.user.token, cropId).then(f => { setCrop(f); });
    }, []);

    return (
        <>


            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item">{crop && crop.cropCode}</li>
                            <li className="breadcrumb-item">
                                <Link to={`/exportcustomsrecords/crop/${crop && crop.id}`} >Export Customs Records</Link>
                            </li>
                            <li className="breadcrumb-item active">Create</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                        {crop && <ExportCustomForm crop={crop} exportCustomRecord={exportCustomRecord} setExportCustomRecord={setExportCustomRecord} />}
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer />

            </div>
            {/* Page wrapper end */}
        </>);
}

export default CreateExportCustomRecord;