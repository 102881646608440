import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import CropForm from "../../../components/Crop/CropForm";
import Footer from "../../../layout/Footer";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFieldById } from "../../../api/Fields/getFieldById";
import useAuth from "../../../hooks/useAuth";

const CreateCrop = () => {
    const { user } = useAuth();
  const location = useLocation();  
  const queryParams = new URLSearchParams(location.search);
  const fieldId = queryParams.get('field'); // Retrieve the value of a specific query parameter

  const [field, setField] = useState();

  useEffect(() => {
    getFieldById(user.user.token, fieldId).then(f => {setField(f);});
  });

    return (
        <>

            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                {/* Page header start */}
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        {/* <li className="breadcrumb-item"><Link to={`/fields/farm/${field && field.farmId}`}>Fields</Link></li> */}
                        <li className="breadcrumb-item">{field && field.fieldCode}</li>
                        <li className="breadcrumb-item"><Link to={`/crops/field/${field && field.id}`}>Crops</Link></li>
                        <li className="breadcrumb-item active">Create</li>
                    </ol>
                    <ul className="app-actions">
                    </ul>
                </div>

                {/* Page header end */}

                {/* Main container start */}
                <div className="main-container">
                    {field && <CropForm field={field} /> }
                </div>
                {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer />

            </div>
            {/* Page wrapper end */}
        </>);
}

export default CreateCrop;