import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { isSinglePageActive } from "../../utils/daynamicNavigation";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { attachActors, fieldWithIdRequired, isoToDateTime } from "../../utils/helpers";

const FieldOptionsSetups = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  const [items, setItems] = useState([]);

  
  const $ = require("jquery");

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      //Initialize DataTable when users are available
      $(document).ready(function () {
        const dataTable = $("#data-table").DataTable();

        // Destroy DataTable if it exists
        if (dataTable) {
          dataTable.destroy();
        }

        $("#data-table").DataTable({
          // Add any required DataTable options here
        });
      });
    }
  }, []);
  
  const getData = async () => {
    setShowPreloader(true);
    if (!(user && user.user.token)) {
      navigate("/login");
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }
      try {
      const response = await fetch(`${baseURL}/fieldoptionssetups`, {
        headers: {
          Authorization: `Bearer ${user.user.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setItems(await response.json());
      } else {
        toast.warn("Error fetching Field Options Setups!");
      }
    } catch (error) {
      toast.error("Error fetching Field Options Setups!");
    } finally {
      setShowPreloader(false);
    }
  };

  const submitRecords = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your login token. Please try again later.");
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(items),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/fieldoptionssetups/in-bulk`, requestOptions);

      if (response.ok) {
        toast.success("The Record Access Setups have been successfully updated.");
        setShowPreloader(true);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the Record Access Setups.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleChange = (index, field, value, required) => {
      const updatedItems = [ ...items ];
      updatedItems[index][field] = value;
      setItems(updatedItems);
      if(required) fieldWithIdRequired(field);
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
              <div className="card-header">
                <div className="card-title">Field Options Setups</div>
                {/* <Link to={`/registration/invitations/create`} className="btn btn-primary float-end">
                  Create New Setup
                </Link> */}
              </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="table-responsive">
                    <table id="data-table" className="table custom-table">
                          <thead className="thead-dark">
                              <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  {/* <th>Code</th> */}
                                  <th>Values</th>
                                  <th>Actions</th>
                              </tr>
                          </thead>
                          <tbody>
                              {items && items.length > 0 ? items.map((item, index) => (
                                <>
                                  <tr key={index}> 
                                    <td>{index + 1})</td>
                                    <td>{item.name}</td>
                                    {/* <td>{item.code}</td> */}
                                    <td>{item.values && item.values.map((value, index) => (<>{index > 0 && <br />}{value}</>))}</td>
                                    <td>[action]</td>
                                  </tr>
                                </>
                              ))  : (<tr key='no-data-available'><td colSpan="3" className="text-center"><em>No data available!</em></td></tr>)}
                          </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={submitRecords}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
};

export default FieldOptionsSetups;
