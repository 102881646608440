import { useEffect, useRef } from "react";
import Navbar from "../../../layout/Navbar";
import useAuth from "../../../hooks/useAuth";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Footer from "../../../layout/Footer";


function ChangePassword() {
    const { user, token } = useAuth();
    const [showModal, setShowModal] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        if(user) {
          setUsername(user.user.username);
        }else {
          //go back from here
          //display message(warning): You are not authorized to perform this action
        }
        
      }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (newPassword !== confirmPassword) {
            toast.warning("The New Password and its confirmation do not match!");
            return;
        }

        if(oldPassword === newPassword) {
            toast.warning("The New Password cannot be the same as the Old Password!");
            return;
        }
      
    
        try {
          const data = {
            oldPassword,
            newPassword
          }
    
          const response = await fetch(`${baseURL}/Users/update-password-with-auth`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'text/plain',
              Authorization: `Bearer ${user.user.token}`,
            },
            body: JSON.stringify(data),
            redirect: 'follow', 
          });

          if (response.ok) {
            toast.success("Password has been updated successfully.")
            return true;
          } else {
            handleNotOkHttpResponse(response);
          }
        } catch (error) {
          toast.warning("Error updating password");
        } finally {
            setShowModal(false);
        }
      };

    return (
        <>
            {/* Loading starts */}
            {/* <div id="loading-wrapper">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div> */}
            {/* Loading ends */}


            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">User Profile</li>
                            <li className="breadcrumb-item active">Change Password</li>
                        </ol>

                        <ul className="app-actions">

                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                        <div className="row gutters justify-content-center">
                            <div className="col-xl-7 col-lg-5 col-md-4 col-sm-4 col-12">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <div className="card-title">Change Password</div>
                                    </div>

                                    <div className="card-body">
                                        <div className="row gutters">
                                            <div className="col-xl-12 col-lg-8 col-md-8 col-sm-8 col-12">
                                                <div className="my_profile_setting_input form-group">
                                                    <label htmlFor="formGroupExampleOldPass">Old Password</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="formGroupExampleOldPass"
                                                        value={oldPassword}
                                                        onChange={(e) => setOldPassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="my_profile_setting_input form-group">
                                                    <label htmlFor="formGroupExampleNewPass">New Password</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="formGroupExampleNewPass"
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                    />
                                                </div>
                                                <div className="my_profile_setting_input form-group">
                                                    <label htmlFor="formGroupExampleConfPass">
                                                        Confirm New Password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="formGroupExampleConfPass"
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    />
                                                </div>


                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="text-right">
                                                    <button onClick={() => setShowModal(true)} className="btn btn-primary">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer/>

            </div>
            {/* Page wrapper end */}


            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                    <Modal.Title style={{ color: 'white' }}>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-4">
                        Are you sure you want to change your password?
                    </p>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: 'none' }}>
                    <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleSubmit}>
                        Change Password
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ChangePassword;