import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { baseURL } from "../../../utils/misc";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../../common/Preloader";
import { getGetWorkflowRecordActivityById } from "../../../api/workflowRecordActivity/getGetWorkflowRecordActivityById";
import ReferencedFormsFooter from "../../../pages/Contracts/WorkflowForms/ReferencedFormsFooter";
import { getContractById } from "../../../api/Contracts/getContractById";
import ReferencedFormsHeader from "../../common/Workflow/ReferencedFormsHeader";
import { getFullBuyerName, getFullCropName, isoToDate } from "../../../utils/helpers";
import { Nav, Tab, Tabs } from "react-bootstrap";
import ContractView from "../ContractView";
import CropRecords from "../../Crop/CropRecords";
import CropInfo from "../../Crop/CropInfo";
import PackagingRecords from "../../PackagingRecords/PackagingRecords";
import TransferRecords from "../../TransferRecords/TransferRecords";
import LogisticsRecords from "../../LogisticsRecords/LogisticsRecords";
import WarehouseRecords from "../../WareHouseRecords/WarehouseRecords";
import SpoilageRecords from "../../SpoilageReports/SpoilageRecords";

const PostImportProcess = ({contractId, theWorkflowRecordActivity}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [theContract, setTheContract] = useState();
  
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    setShowPreloader(true);
    const contract = await getContractById(user.user.token, contractId);
    setTheContract(contract);
    setShowPreloader(false);
  };

  const updateFunction = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    return true;
    
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                {theWorkflowRecordActivity && theWorkflowRecordActivity.workflowProcess
                  ? theWorkflowRecordActivity.workflowProcess.name.replace("Contract - ", "") : "--"} - {theWorkflowRecordActivity && theWorkflowRecordActivity.workflowStage
                    ? theWorkflowRecordActivity.workflowStage.name : "--"}
              </div>
            </div>
            <div className="card-body pt-0">
              <ReferencedFormsHeader theWorkflowRecordActivity={theWorkflowRecordActivity} />
              {/* <hr /> */}
              <Tabs defaultActiveKey="contract">
                <Tab eventKey="contract" title="Contract Details">
                  {theContract && <ContractView contract={theContract} />}
                </Tab>
                <Tab eventKey="crop" title="Crop Information">
                {theContract && <CropInfo contract={theContract} />}
                </Tab>
                <Tab eventKey="transfer-records" title="Transfer Records">
                  {theContract && theContract.crop && <TransferRecords crop={theContract.crop} />}
                </Tab>
                <Tab eventKey="logistics-records" title="Logistics Records">
                  {theContract && theContract.crop && <LogisticsRecords crop={theContract.crop} />}
                </Tab>
                <Tab eventKey="spoilage-records" title="Spoilage Records">
                  {theContract && theContract.crop && <SpoilageRecords crop={theContract.crop} />}
                </Tab>
              </Tabs>
            </div>
            <div className="card-footer">
              {theWorkflowRecordActivity && (<ReferencedFormsFooter WorkflowRecordActivity={theWorkflowRecordActivity} submitFunction={updateFunction} />) }
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
};

export default PostImportProcess;
