import { useEffect, useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import CardBox from "../../../components/common/CardBox";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isSinglePageActive } from "../../../utils/daynamicNavigation";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Preloader from "../../../components/common/Preloader";

function TableStagesProcess({ workflowProcessId }) {
    const { user } = useAuth();
    const [workflowstages, setWorkFlowStages] = useState([]);
    const [totalActive, setTotalActive] = useState(0);
    const [totalDiActive, setTotalDiActive] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [selectedStageId, setSelectedStageId] = useState(null);
    const [showPreloader, setShowPreloader] = useState(false);

    
    const [theWorkflowProcess, setTheWorkflowProcess] = useState();
    

    const profileMenuItems = [
        // {
        //     id: 1,
        //     name: "Edit",
        //     icon: "flaticon-transfer-1",
        //     routerPath: "/workflowstage/edit",
        // },
        // {
        //     id: 2,
        //     name: "Delete",
        //     icon: "flaticon-transfer-1",
        //     onClick: (stage) => {
        //         handleShow(stage.id)
        //     },
        // },
        ,
        {
            id: 1,
            name: "WorFlow Transition",
            icon: "flaticon-transfer-1",
            routerPath: "/workflowtransition/workflowStage",
        },
        {
            id: 2,
            name: "Work Permission",
            icon: "flaticon-transfer-1",
            routerPath: "/workflowpermission/workflowStage",
        }
    ];

    // Define the path pattern for user profiles
    const $ = require("jquery");

    useEffect(() => {
        if (workflowstages && workflowstages.length > 0) {
            //Initialize DataTable when users are available
            $(document).ready(function () {
                const dataTable = $("#data-table").DataTable();

                // Destroy DataTable if it exists
                if (dataTable) {
                    dataTable.destroy();
                }

                $("#data-table").DataTable({
                    // Add any required DataTable options here
                    columnDefs: [
                        { width: "4%", targets: 0 },
                        { width: "12%", targets: 3 } 
                    ]
                });
            });
        }
    }, [workflowstages]);


    useEffect(() => {
        fetchTheWorkflowProcess();
        
        const fetchWorkflowStages = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
                toast.warn("You seem to have lost your access token. Please try again later.");
                return;
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/workflowstages/workflowprocess/${workflowProcessId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching workflow stages');
                }
                const data = await response.json();
                setWorkFlowStages(data);
            } catch (error) {
                toast.error("Error fetching workflow stages.");
                console.error("Error fetching workflow stages:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchWorkflowStages();
    }, [workflowProcessId]);

    
        
  const fetchTheWorkflowProcess = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }
    setShowPreloader(true);

    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.user.token}`,
        },
      };
      const response = await fetch(
        `${baseURL}/workflowProcesses/${workflowProcessId}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Error fetching orkflow process');
      }

      const data = await response.json();
      if (data) {
        setTheWorkflowProcess(data);
      }
    } catch (error) {
      toast.error('Error fetching workflow process.');
    }finally {
      setShowPreloader(false);
    };
  };

    const handleShow = (stageId) => {
        setShow(true);
        setSelectedStageId(stageId);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedStageId(null);
    };

    const handleRemoveStage = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }
        setShowPreloader(true);
        try {

            if (!selectedStageId) {
                console.error('No selected Services Provider ID');
                return;
            }

            const data = {
                id: selectedStageId,
            }
            const requestOptions = {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow",
                body: JSON.stringify(data)
            };

            const response = await fetch(`${baseURL}/workflowstages/${selectedStageId}`, requestOptions);
            if (response.ok) {
                toast.success("Workflow stage removed successfully");
                handleClose();
                setWorkFlowStages(workflowstages.filter(item => item.id !== selectedStageId));
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on removing workflow stage:", error.message);
        } finally {
            setShowPreloader(false);
        }
    };


    return (
        <>
            <Preloader show={showPreloader} />

            <div className="main-container">



                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">List of Workflow Stages {theWorkflowProcess ? ' for Workflow Process: \'' + theWorkflowProcess.name +  '\'' : ''}</div>
                                {/* <Link
                                    to={`/workflowstage/create/?workflowProcess=${workflowProcessId}`}
                                    className="btn btn-primary float-end"
                                >
                                    Add Workflow Stage
                                </Link> */}
                            </div>
                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        {workflowstages && workflowstages.length > 0 ? (
                                            <div className="table-responsive">
                                                <table id="data-table" className="table custom-table">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th>Order</th>
                                                            <th>Name</th>
                                                            <th>Form URL</th>
                                                            <th><div className="float-end">Actions</div></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {workflowstages?.map((stage) => (
                                                            <tr key={stage.id}>
                                                                <td>{stage.order}</td>
                                                                <td>{stage.name}</td>
                                                                <td>{stage.formURL}</td>
                                                                <td className="">
                                                                    <ul>
                                                                        <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                                                            <div className="dropdown">
                                                                                <a
                                                                                    className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                                                                    role="button"
                                                                                    href="#"
                                                                                    id={"dropdownMenuLink" + stage.id}
                                                                                    data-bs-toggle="dropdown"
                                                                                >
                                                                                    Actions <i className="fa fa-caret-down"></i>
                                                                                </a>
                                                                                <ul
                                                                                    className="dropdown-menu"
                                                                                    aria-labelledby={"dropdownMenuLink" + stage.id}
                                                                                >
                                                                                    {profileMenuItems.map((actionMenuItem) => (
                                                                                        <li key={stage.id + '-' + actionMenuItem.id}>
                                                                                            {actionMenuItem.onClick ? (
                                                                                                <a
                                                                                                    href="#"
                                                                                                    className="dropdown-item"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault(); // Prevent default anchor behavior
                                                                                                        actionMenuItem.onClick(stage);
                                                                                                    }}
                                                                                                    style={
                                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                                            ? { color: "#ff5a5f" }
                                                                                                            : undefined
                                                                                                    }
                                                                                                >
                                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                                </a>
                                                                                            ) : (
                                                                                                <Link
                                                                                                    to={actionMenuItem.routerPath + "/" + stage.id} // Changed href to to
                                                                                                    className="dropdown-item"
                                                                                                    style={
                                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                                            ? { color: "#ff5a5f" }
                                                                                                            : undefined
                                                                                                    }
                                                                                                >
                                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                                </Link>
                                                                                            )}
                                                                                        </li>
                                                                                    ))}

                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <Modal show={show} onHide={handleClose}>
                                                    <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                                        <Modal.Title style={{ color: 'white' }}>Delete Workflow Stage</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p className="mb-4">
                                                            Are you sure you want to delete this workflowstage?
                                                        </p>
                                                    </Modal.Body>
                                                    <Modal.Footer style={{ borderTop: 'none' }}>
                                                        <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveStage}>
                                                            Delete
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        ) : (
                                            <p>No data available</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Row start */}

                {/* Row end */}

            </div>

        </>
    );
}

export default TableStagesProcess;