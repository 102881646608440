import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse, hasAdminRole, hasBuyAndAdminRole } from "../../utils/misc";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { isSinglePageActive } from "../../utils/daynamicNavigation";
import Preloader from "../common/Preloader";
import { isoToDate } from "../../utils/helpers";
import { Button, Modal } from "react-bootstrap";
import SpoilageFormBasic from "./SpoilageFormBasic";

function SpoilageTable({ spoilagerecords, spoilageRecord, crop, setSpoilageRecord,
     handleModalShow, handleSubmit, showModal, handleModalClose, spoilageId, allowModalEdit }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const isAdminAndBuyer = user && user.user && hasBuyAndAdminRole(user.user.roles);

    const profileMenuItems = [
        {
            id: 1,
            name: "View",
            icon: "icon-eye1",
            onClick: (process) => {
                handleShowViewModal(process);
            },
        },
        {
            id: 2,
            name: "Edit",
            icon: "icon-edit1",
            authType: 'admin',
            onClick: (record) => {
                if (allowModalEdit) {
                    handleModalShow(record, true); // Open modal for editing
                } else {
                    // Redirect to edit page if modal edit is not allowed
                    window.location.href = `/spoilagerecord/edit/${record.id}`;
                }
            },
        },
        {
            id: 3,
            name: "Delete",
            icon: "icon-delete",
            onClick: (process) => {
                handleShowDeleteModal(process);
            },
            authType: 'admin',
        }
    ];

    // Define the path pattern for user profiles
    const $ = require("jquery");

    useEffect(() => {
        if (spoilagerecords && spoilagerecords.length > 0) {
            //Initialize DataTable when users are available
            $(document).ready(function () {
                const dataTable = $("#data-table").DataTable();

                // Destroy DataTable if it exists
                if (dataTable) {
                    dataTable.destroy();
                }

                $("#data-table").DataTable({
                    // Add any required DataTable options here
                    columnDefs: [
                        { width: "12%", targets: 0 },
                        { width: "12%", targets: 2 }
                    ]
                });
            });
        }
    }, [spoilagerecords, $]);

    const handleShowViewModal = (record) => {
        setSelectedRecord(record);
        setShowViewModal(true);
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setSelectedRecord(null);
    };

    const handleShowDeleteModal = (record) => {
        setSelectedRecord(record);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedRecord(null);
    };

    const handleRemoveLogistic = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }

        setShowPreloader(true);
        try {
            if (!selectedRecord) {
                console.error('No selected Record');
                return;
            }

            const requestOptions = {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow",
            };

            const response = await fetch(`${baseURL}/spoilagereports/${selectedRecord.id}`, requestOptions);
            if (response.ok) {
                toast.success("Spoilage Record removed successfully");
                handleCloseDeleteModal();
                setShowPreloader(true);
                window.location.reload();
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on removing Spoilage Record:", error.message);
        } finally {
            setShowPreloader(false);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="card-body pt-0">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="table-responsive">
                            <table id="data-table" className="table custom-table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Code</th>
                                        <th>Date Reported</th>
                                        <th>Reported By</th>
                                        <th>Spoilage Type</th>
                                        <th>Quantity Affected</th>
                                        <th>Actions Taken</th>
                                        <th><div className="float-end">Actions</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {spoilagerecords && spoilagerecords.length > 0 ? spoilagerecords.map((spoilageRecord, index) => (
                                        <tr key={index}>
                                            <td>{spoilageRecord.code ? spoilageRecord.code : '--'}</td>
                                            <td>{isoToDate(spoilageRecord.dateReported)}</td>
                                            <td>{spoilageRecord.reportedBy}</td>
                                            <td>{spoilageRecord.spoilageType}</td>
                                            <td>{spoilageRecord.quantityAffected} {spoilageRecord.quantityUnit}</td>
                                            <td>{spoilageRecord.actionsTaken}</td>
                                            <td className="">
                                                <ul>
                                                    <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                                        <div className="dropdown">
                                                            <Link
                                                                className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                                                role="button"
                                                                to="#"
                                                                id={"dropdownMenuLink" + spoilageRecord.id}
                                                                data-bs-toggle="dropdown"
                                                            >
                                                                Actions <i className="fa fa-caret-down"></i>
                                                            </Link>
                                                            <ul
                                                                className="dropdown-menu"
                                                                aria-labelledby={"dropdownMenuLink" + spoilageRecord.id}
                                                            >
                                                                {profileMenuItems
                                                                    .filter(item => !item.authType || (item.authType === "admin" && isAdminAndBuyer)) // Filter items based on role
                                                                    .map(actionMenuItem => (
                                                                        <li key={spoilageRecord.id + '-' + actionMenuItem.id}>
                                                                            {actionMenuItem.onClick ? (
                                                                                <Link
                                                                                    to="#"
                                                                                    className="dropdown-item"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault(); // Prevent default anchor behavior
                                                                                        actionMenuItem.onClick(spoilageRecord);
                                                                                    }}
                                                                                    style={
                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                            ? { color: "#ff5a5f" }
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                </Link>
                                                                            ) : (
                                                                                <Link
                                                                                    to={actionMenuItem.routerPath + "/" + spoilageRecord.id} // Changed href to to
                                                                                    className="dropdown-item"
                                                                                    style={
                                                                                        isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                            ? { color: "#ff5a5f" }
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;{actionMenuItem.name}
                                                                                </Link>
                                                                            )}
                                                                        </li>
                                                                    ))}

                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )) : (<tr><td colSpan="7" className="text-center"><em>No data available!</em></td></tr>)}
                                </tbody>
                            </table>
                            {/* Delete Modal */}
                            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                                <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                    <Modal.Title style={{ color: 'white' }}>Delete Logistics Record</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="mb-4">
                                        Are you sure you want to delete this logistics record?
                                    </p>
                                </Modal.Body>
                                <Modal.Footer style={{ borderTop: 'none' }}>
                                    <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleCloseDeleteModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveLogistic}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* View Modal */}
                            <Modal size="lg" show={showViewModal} onHide={handleCloseViewModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Logistics Record Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedRecord ? (
                                        <div className="formView">
                                            <p><strong>Code:</strong> {selectedRecord.code ? selectedRecord.code : "N/A"}</p>
                                            <p><strong>Date Reported:</strong> {isoToDate(selectedRecord.dateReported)}</p>
                                            <p><strong>Quantity Affected:</strong> {selectedRecord.quantityAffected} {selectedRecord.quantityUnit}</p>
                                            <p><strong>Spoilage Type:</strong> {selectedRecord.spoilageType}</p>
                                            <p><strong>Causes:</strong> {selectedRecord.causes}</p>
                                            <p><strong>Reported By:</strong> {selectedRecord.reportedBy}</p>
                                            <p><strong>Actions Taken:</strong> {selectedRecord.actionsTaken}</p>
                                            <p><strong>Inspection Details:</strong> {selectedRecord.inspectionDetails}</p>
                                            <p><strong>Comments:</strong> {selectedRecord.comments}</p>
                                            <p>
                                                <strong>Attachment:</strong>
                                                &nbsp;
                                                {selectedRecord.attachments ? (
                                                    <a className="btn btn-primary" href={selectedRecord.attachments} target="_blank" rel="noopener noreferrer">
                                                        <i className="icon-eye1" aria-hidden="true"></i> View Attachment
                                                    </a>
                                                ) : (
                                                    "N/A"
                                                )}
                                            </p>
                                        </div>
                                    ) : (
                                        <p>No record selected.</p>
                                    )}

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseViewModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal size="lg" show={showModal} onHide={handleModalClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{spoilageId ? "Edit Logistics Record" : "Add Logistics Record"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <SpoilageFormBasic
                                        crop={crop}
                                        spoilageRecord={spoilageRecord}
                                        setSpoilageRecord={setSpoilageRecord}
                                        handleSubmit={handleSubmit}
                                    />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SpoilageTable;