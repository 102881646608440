import SideBarWrapper from "../../components/common/SideBarWrapper";
import Navbar from "../../layout/Navbar";
import LogisticsWaybill from "../../components/LogisticsWaybill/LogisticsWaybill";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL } from "../../utils/misc";
import { toast } from "react-toastify";
import Preloader from "../../components/common/Preloader";

const LogisticsWaybillIndex = () => {
    const { logisticsId } = useParams(); // Assuming the ID is passed in the route
    const [waybillData, setWaybillData] = useState(null);
    const { user } = useAuth();
    const [showPreloader, setShowPreloader] = useState(false);

    useEffect(() => {
        const fetchWaybillData = async () => {
            setShowPreloader(true);
            try {
                const response = await fetch(`${baseURL}/logisticsrecords/${logisticsId}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log("way data >>:", data);
                    setWaybillData(data);
                } else {
                    toast.error("Failed to fetch waybill data");
                }
            } catch (error) {
                console.error("Error fetching waybill data:", error);
            } finally {
                setShowPreloader(false);
            }
        };

        fetchWaybillData();
    }, [logisticsId, user.user.token]);
    return (
        <>
            <Preloader show={showPreloader} />

            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to={`/logisticsrecords/crop/${waybillData && waybillData.cropId}`} >Logistics Records</Link>
                            </li>
                            <li className="breadcrumb-item active">Report</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                        <LogisticsWaybill waybillData={waybillData} />
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}

            </div>
            {/* Page wrapper end */}
        </>
    );
}

export default LogisticsWaybillIndex;