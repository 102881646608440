import { Link, useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import { useEffect, useState } from "react";
import { baseURL } from "../../../utils/misc";
import useAuth from "../../../hooks/useAuth";
import CropForm from "../../../components/Crop/CropForm";
import Footer from "../../../layout/Footer";
import { toast } from "react-toastify";

const EditCrop = () => {
    const { cropId } = useParams();
    const [cropData, setCropData] = useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();



    useEffect(() => {
        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }
        // Fetch workflow process data when the component mounts
        const fetchData = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                }
            };

            try {
                const response = await fetch(`${baseURL}/crops/${cropId}`, requestOptions);

                if (response.ok) {
                    const data = await response.json();
                    setCropData(data);
                } else {
                    console.error('Error fetching crop data');
                }
            } catch (error) {
                console.error('Error fetching crop data:', error);
            }
        };

        fetchData();
    }, [cropId,navigate, user]);



    return (<>


        {/* Page wrapper start */}
        <div className="page-wrapper">

            {/* Sidebar wrapper start */}
            <SideBarWrapper />
            {/* Sidebar wrapper end */}

            {/* Page content start  */}
            <div className="page-content">

                {/* Header start */}
                <Navbar />
                {/* Header end */}

                {/* Page header start */}
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item"><Link to={`/crops/field/${cropData && cropData && cropData.fieldId}`}>Crops</Link></li>
                        <li className="breadcrumb-item">{cropData && cropData && cropData.cropCode}</li>
                        <li className="breadcrumb-item active">Edit</li>
                    </ol>
                    <ul className="app-actions">
                    </ul>

                    
                    {/* <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={`/fields/farm/${field && field.farmId}`}>Field {field && '- ' + field.fieldCode}</Link></li>
                        <li className="breadcrumb-item"><Link to={`/crops/field/${field && field.id}`}>Crops</Link></li>
                        <li className="breadcrumb-item active">Create</li>
                    </ol>
                    <ul className="app-actions">
                    </ul> */}
                </div>
                {/* Page header end */}

                {/* Main container start */}
                <div className="main-container">
                    <CropForm field={cropData && cropData.field} cropData={cropData && cropData} />
                </div>
                {/* Main container end */}

            </div>
            {/* Page content end */}
            <Footer/>

        </div>
        {/* Page wrapper end */}
    </>);
}

export default EditCrop;