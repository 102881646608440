import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL } from "../../utils/misc";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../common/Preloader";
import { getFullFarmName } from "../../utils/helpers";
import FieldTable from "./FieldTable";
import { toast } from "react-toastify";

function FieldListing({ farmId }) {
    const { user } = useAuth();
    const [fields, setFields] = useState([]);
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [farm, setFarm] = useState();

    useEffect(() => {
        const fetchFields = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
                toast.warn("You seem to have lost your access token. Please try again later.");
                return;
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/farms/${farmId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching farms');
                }
                const data = await response.json();
                setFarm(data);
            } catch (error) {
                toast.error("Error fetching farms.");
                console.error("Error fetching farms:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchFields();
    }, [user, navigate, farmId]);

    useEffect(() => {
        const fetchFields = async () => {
            if (!(user && user.user.token)) {
                navigate('/login');
                toast.warn("You seem to have lost your access token. Please try again later.");
                return;
            }
            setShowPreloader(true);
            try {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.user.token}`,
                    }
                };
                const response = await fetch(`${baseURL}/fields/farm/${farmId}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Error fetching fields');
                }
                const data = await response.json();
                setFields(data);
            } catch (error) {
                console.error("Error fetching fields:", error);
            } finally {
                setShowPreloader(false);
            }
        };
        fetchFields();
    }, [farmId, navigate, user]);

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="main-container">

                <div className="row gutters">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">List of Fields</div>

                                <div className="mt-3 p-2 parentLabel">
                                    <div className="mt-2 mb-1">
                                        <span>Farm: </span>{getFullFarmName(farm)}
                                        <Link to={`/farms/farmer/${farm && farm.farmerId}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                                    </div>
                                </div>
                                <br />
                                <Link
                                    to={`/field/create/?farm=${farmId}`}
                                    className="btn btn-primary float-end"
                                >
                                    <i className="icon-add"></i> Add Field
                                </Link>
                                {/* <div className="parentLabel"><span>Farm: </span> <strong className="card-title ">{farmName}</strong></div> */}
                            </div>
                            <FieldTable fields={fields} allowEdit={true} />
                        </div>
                    </div>
                </div>


                {/* Row start */}

                {/* Row end */}

            </div>

        </>
    );
}

export default FieldListing;