import { baseURL } from "../../utils/misc";


 export const getPestMaturityStagesOfCrop = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/pestmanagementrecords/maturity-stages-of-crop`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Maturity Stages Of Crop!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};


export const getPestIngredientQuantityUnit = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/pestmanagementrecords/ingredient-quantity-units`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Ingredient Quantity Units!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};


export const getPestSources = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/miscellaneous/material-sources`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Sources", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};

export const getPestRecordsByCropId = async (token, cropId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/pestmanagementrecords/crop/${cropId}`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Pest Records!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};