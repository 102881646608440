import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../../common/Preloader";
import ReferencedFormsFooter from "../../../pages/Contracts/WorkflowForms/ReferencedFormsFooter";
import { getContractById } from "../../../api/Contracts/getContractById";
import ReferencedFormsHeader from "../../common/Workflow/ReferencedFormsHeader";
import { Nav, Tab, Tabs } from "react-bootstrap";
import InitiateContractForm from "../InitiateContractForm";
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";

const ContractUpdate = ({contractId, theWorkflowRecordActivity}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [contract, setContract] = useState(null);
  
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    setShowPreloader(true);
    setContract(await getContractById(user.user.token, contractId));
    setShowPreloader(false);
  };

  const updateFunction = async (e) => {
    e.preventDefault();
  
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const data = {
      id: contract.id,
      cropId: contract.cropId,
      buyerId: contract.buyerId,
      description: contract.description,
      details: contract.details,
      startDate: contract.startDate,
      endDate: contract.endDate,
      contractAmount: contract.contractAmount,
      currency: contract.currency,
      contractStatus: contract.contractStatus,
      paymentTerms: contract.paymentTerms,
      deliveryTerms: contract.deliveryTerms,
      exporterContactInfo: contract.exporterContactInfo,
      buyerContactInfo: contract.buyerContactInfo,
      // attachments
    };
  
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
  
    try {
      setShowPreloader(true);
      
      const response = await fetch(`${baseURL}/contracts/${contract.id}`, requestOptions);
  
      if (response.ok) {
        toast.success("The Contract has been successfully update.");
        return true;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the Contract!");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                {theWorkflowRecordActivity && theWorkflowRecordActivity.workflowProcess
                  ? theWorkflowRecordActivity.workflowProcess.name.replace("Contract - ", "") : "--"} - {theWorkflowRecordActivity && theWorkflowRecordActivity.workflowStage
                    ? theWorkflowRecordActivity.workflowStage.name : "--"}
              </div>
            </div>
            <div className="card-body pt-0">
              <ReferencedFormsHeader theWorkflowRecordActivity={theWorkflowRecordActivity} />
              {/* <hr /> */}
              <Tabs defaultActiveKey="contract">
                <Tab eventKey="contract" title="Contact">
                  {contract && <InitiateContractForm contract={contract} setContract={setContract} />}
                </Tab>
                <Tab eventKey="crop" title="Crop Information">
                </Tab>
              </Tabs>
            </div>
            <div className="card-footer">
              {theWorkflowRecordActivity && (<ReferencedFormsFooter WorkflowRecordActivity={theWorkflowRecordActivity} submitFunction={updateFunction} />) }
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
};

export default ContractUpdate;
