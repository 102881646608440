import { useEffect, useState } from "react";
import { fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getHumidityConditions, getRainfallConditions, getTemperatureConditions, getWindSpeedConditions } from "../../api/miscellaneous/getQuantityUnits";

const EnvironmentalCondition = ({ environmentalCondition, setEnvironmentalCondition }) => {

    const [rainfallList, setRainfallList] = useState([]);
    const [temperatureList, setTemperatureList] = useState([]);
    const [humidityList, setHumidityList] = useState([]);
    const [windSpeedList, setWindSpeedList] = useState([]);

    useEffect(() => {
        getRainfallConditions().then((data) => setRainfallList(data));
        getTemperatureConditions().then((data) => setTemperatureList(data));
        getHumidityConditions().then((data) => setHumidityList(data));
        getWindSpeedConditions().then((data) => setWindSpeedList(data));
      }, []);

    useEffect(() => {
        if (!environmentalCondition) {
            setEnvironmentalCondition({
                effects: "",
                recordedDate: "",
                temperature: "",
                weatherConditions: "",
                humidity: "",
                rainfall: "",
                windSpeed: "",
                environmentalConditionId: "",
            });
        }
    }, []);

    useEffect(() => { }, [environmentalCondition]);

    const handleChange = (field, value, required) => {
        const updatedItem = { ...environmentalCondition }; // Create a copy of the items array
        updatedItem[field] = value; // Update the value in the copy
        setEnvironmentalCondition(updatedItem); // Update the state with the modified copy
        if(required) fieldWithIdRequired(field); //Show UI validation
    };

    return (<>
        {environmentalCondition && (
            <div className="row gutters" style={{ paddingTop: "8px", backgroundColor: "#f1f7fa" }}>
                <br />
                <div className="col-12">
                    <div className="card-title"><strong>Environmental Conditions</strong></div>
                </div>

               
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                        <label htmlFor="temperature">Temperature *</label>
                        <select
                            className="form-control"
                            id="temperature"
                            value={environmentalCondition.temperature}
                            onChange={(e) => handleChange("temperature", e.target.value, true)}
                        >
                            <option value="">Select Temperature...</option>
                            {temperatureList && temperatureList.map((unit, index) =>
                                (<option key={index} value={unit}>{unit}</option>)
                            )}
                        </select>
                        <div className="invalid-feedback">This is required</div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                        <label htmlFor="weatherConditions">Weather Conditions *</label>
                        <input
                            type="text"
                            className="form-control"
                            id="weatherConditions"
                            placeholder="Enter the weather conditions..."
                            value={environmentalCondition.weatherConditions}
                            onChange={(e) => handleChange("weatherConditions", e.target.value, true)}
                        />
                        <div className="invalid-feedback">This is required</div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                        <label htmlFor="name">Humidity</label>
                        <select
                            className="form-control"
                            id="humidity"
                            value={environmentalCondition.humidity}
                            onChange={(e) => handleChange("humidity", e.target.value, true)}
                        >
                            <option value="">Select Humidity...</option>
                            {humidityList && humidityList.map((unit, index) =>
                                (<option key={index} value={unit}>{unit}</option>)
                            )}
                        </select>
                        <div className="invalid-feedback">This is required</div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                        <label htmlFor="rainfall">Rainfall</label>
                        <select
                            className="form-control"
                            id="rainfall"
                            value={environmentalCondition.rainfall}
                            onChange={(e) => handleChange("rainfall", e.target.value, true)}
                        >
                            <option value="">Select Rainfall...</option>
                            {rainfallList && rainfallList.map((unit, index) =>
                                (<option key={index} value={unit}>{unit}</option>)
                            )}
                        </select>
                        <div className="invalid-feedback">This is required</div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                        <label htmlFor="windSpeed">Wind Speed</label>
                        <select
                            className="form-control"
                            id="windSpeed"
                            value={environmentalCondition.windSpeed}
                            onChange={(e) => handleChange("windSpeed", e.target.value, true)}
                        >
                            <option value="">Select Wind Speed...</option>
                            {windSpeedList && windSpeedList.map((unit, index) =>
                                (<option key={index} value={unit}>{unit}</option>)
                            )}
                        </select>
                        <div className="invalid-feedback">This is required</div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                        <label htmlFor="recordedDate">Recorded Date *</label>
                        <input
                            type="date"
                            className="form-control"
                            id="recordedDate"
                            value={isoToDate(environmentalCondition.recordedDate)}
                            onChange={(e) => handleChange("recordedDate", e.target.value, true)}
                        />
                        <div className="invalid-feedback">This is required</div>
                    </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                        <label htmlFor="effects">Effects *</label>
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            id="effects"
                            placeholder="Enter the Effects..."
                            value={environmentalCondition.effects}
                            onChange={(e) => handleChange("effects", e.target.value, true)}
                        />
                        <div className="invalid-feedback">This is required</div>
                    </div>
                </div>
            </div>
        )}
    </>);
}

export default EnvironmentalCondition;