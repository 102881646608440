import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import TransferForm from "../../../components/TransferRecords/TransferForm";
import Footer from "../../../layout/Footer";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { getCropById } from "../../../api/CropRecords/getCropById";

const CreateTransferRecord = () => {

    const [crop, setCrop] = useState();
    const { user } = useAuth();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const cropId = queryParams.get('crop'); // Retrieve the value of a specific query parameter
    
    const [transferRecord, setTransferRecord] = useState(null);

    useEffect(() => {
        //Instantiation record with empty values
        setTransferRecord(
            {
              cropId: cropId,
              date: '',
              environmentalCondition: {
                temperature: '',
                weatherConditions: '',
                humidity: '',
                rainfall: '',
                windSpeed: '',
                recordedDate: '',
                effects: null
              },
              origin: '',
              destination: '',
              quantity: null,
              quantityUnit: '',
              transportMethod: '',
              transporterName: '',
              transporterContact: '',
              vehicleDetails: '',
              remarks: ''
            }
          );
        
        //Get the crop record
        getCropById(user.user.token, cropId).then(f => { setCrop(f); });
    }, []);

    return (
        <>


            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item">{crop && crop.cropCode}</li>
                            <li className="breadcrumb-item">
                                <Link to={`/transferrecords/crop/${crop && crop.id}`} >Transfer Records</Link>
                            </li>
                            <li className="breadcrumb-item active">Create</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                        {crop && <TransferForm crop={crop} transferRecord={transferRecord} setTransferRecord={setTransferRecord} />}
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer />

            </div>
            {/* Page wrapper end */}
        </>);
}

export default CreateTransferRecord;