import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { baseURL, handleNotOkHttpResponse } from "../../../utils/misc";
import useAuth from "../../../hooks/useAuth";
import Preloader from "../../common/Preloader";
import { getInvitationTargets } from "../../../api/Invitations/getInvitationTargets";
import { fieldRequired, fieldWithIdRequired } from "../../../utils/helpers";
import { getInvitationByCode } from "../../../api/Invitations/getInvitationByCode";

const RegisterFarmer = ({invitationUniqueCode}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [profilePicture, setProfilePicture] = useState('');
  const [uniqueCode, setUniqueCode] = useState('');
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [otherNames, setOtherNames] = useState('');
  const [gender, setGender] = useState('');
  const [baseLevel, setBaseLevel] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [startingYearOfFarming, setStartingYearOfFarming] = useState('');
  const [invitationId, setInvitationId] = useState('');

  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getSetUpData();
  }, []);

  const getSetUpData = async () => {
    try {
      setShowPreloader(true);
      const invitation = await getInvitationByCode(invitationUniqueCode);

      if(!invitation) {
        toast.warn("The registration token submitted is invalid!");
        navigate('/');
        return;
      } else {
        //Set default fields
        setInvitationId(invitation.id);
        setEmail(invitation.email);
        setMobileNumber(invitation.telephone);

        //Make sure the right registration is ensured
        const theTarget = invitation.target.toUpperCase();
        if(theTarget !== "OUTGROWER") {
          toast.warn("Invalid registration type!");
          navigate('/');
          return;
        }
      }
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const validateFields = () => {
    let retVal = true;
    if (!mobileNumber) {
      fieldWithIdRequired('mobileNumber');
      toast.warn("Mobile Number is required.");
      retVal = false;
    }
    if (!email) {
      fieldWithIdRequired('email');
      toast.warn("Email is required.");
      retVal = false;
    }
    if (!invitationId) {
      toast.warn("The Invitation information is missing.");
      retVal = false;
    }
    if (!lastName) {
      fieldWithIdRequired('lastName');
      toast.warn("Last Name is required.");
      retVal = false;
    }
    if (!firstName) {
      fieldWithIdRequired('firstName');
      toast.warn("First Name is required.");
      retVal = false;
    }
    if (!gender) {
      fieldWithIdRequired('gender');
      toast.warn("Gender is required.");
      retVal = false;
    }
    if (!homeAddress) {
      fieldWithIdRequired('homeAddress');
      toast.warn("Home Address is required.");
      retVal = false;
    }
    if (!startingYearOfFarming) {
      fieldWithIdRequired('startingYearOfFarming');
      toast.warn("Starting Year of Farming is required.");
      retVal = false;
    }
    if (startingYearOfFarming < 1900 || startingYearOfFarming > new Date().getFullYear()) {
      fieldWithIdRequired('startingYearOfFarming');
      toast.warn(`Starting Year of Farming must be from 1900 to ${new Date().getFullYear()}`);
      retVal = false;
    }
    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    const data = {
      invitationId,
      profilePicture,
      email,
      lastName,
      firstName,
      otherNames,
      gender,
      baseLevel,
      mobileNumber,
      homeAddress,
      startingYearOfFarming
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/farmers/register`, requestOptions);

      if (response.ok) {
        toast.success("Your registration as a farmer is complete.");
        navigate("/");
        setShowPreloader(false);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Could not complete the registration!");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Register as an Outgrower</div>
              <div className="card-sub-title">Enter your details below and submit when you are done.</div>
            </div>
            <div className="card-body pt-0">
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="firstName">First Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      value={firstName}
                      onChange={(e) => { setFirstName(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the First Name..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      value={lastName}
                      onChange={(e) => { setLastName(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Last Name..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Other Names</label>
                    <input
                      type="text"
                      className="form-control"
                      id="otherNames"
                      value={otherNames}
                      onChange={(e) => { setOtherNames(e.target.value); }}
                      placeholder="Enter the Other Names..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="gender">Gender *</label>
                    <select
                      type="text"
                      className="form-control"
                      id="gender"
                      value={gender}
                      onChange={(e) => { setGender(e.target.value); fieldRequired(e);}}
                    >
                      <option>Select Gender...</option>
                      <option value={"Male"}>Male</option>
                      <option value={"Female"}>Female</option>
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="baseLevel">Base Level</label>
                    <input
                      type="text"
                      className="form-control"
                      id="baseLevel"
                      value={baseLevel}
                      onChange={(e) => { setBaseLevel(e.target.value); }}
                      placeholder="Enter the Base Level..."
                    />
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      readOnly
                      placeholder="Enter the Email..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number *</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="mobileNumber"
                      value={mobileNumber}
                      readOnly
                      placeholder="Enter the Mobile Number..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="homeAddress">Home Address *</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="homeAddress"
                      value={homeAddress}
                      onChange={(e) => { setHomeAddress(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Home Address..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="form-group">
                    <label htmlFor="purpose">Starting Year of Farming *</label>
                    <input
                      type="number"
                      min="1900"
                      max="2100"
                      className="form-control form-control-lg"
                      id="startingYearOfFarming"
                      value={startingYearOfFarming}
                      onChange={(e) => { setStartingYearOfFarming(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Starting Year of Farming..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                  <div className="float-end">
                    <button type="button" onClick={handleSubmitCreate} className="btn btn-primary float-end">
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default RegisterFarmer;
