import { Link, useParams } from "react-router-dom";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import Navbar from "../../../layout/Navbar";
import React,{ useEffect, useState } from "react";
import Footer from "../../../layout/Footer";
import useAuth from "../../../hooks/useAuth";
import { getCropById } from "../../../api/CropRecords/getCropById";
import PlantingListing from "../../../components/PlantingRecords/PlantingListing";

const PlantingRecords = () => {
    const { cropId } = useParams();

    const [crop, setCrop] = useState();
    const { user } = useAuth();

    useEffect(() => {
        const fetchPlanting = async () => {
            if (cropId) {
                const cropData = await getCropById(user.user.token, cropId);
                setCrop(cropData);
            }
        };

        fetchPlanting();
    }, [cropId, user.user.token]);
    return (
        <>



            {/* Page wrapper start */}
            <div className="page-wrapper">

                {/* Sidebar wrapper start */}
                <SideBarWrapper />
                {/* Sidebar wrapper end */}

                {/* Page content start  */}
                <div className="page-content">

                    {/* Header start */}
                    <Navbar />
                    {/* Header end */}

                    {/* Page header start */}
                    <div className="page-header">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item">
                                <Link to={`/crops/field/${crop && crop.fieldId}`} >Crop</Link>
                            </li>
                            <li className="breadcrumb-item active">Planting Records</li>
                        </ol>
                        <ul className="app-actions">
                        </ul>
                    </div>
                    {/* Page header end */}

                    {/* Main container start */}
                    <div className="main-container">
                    <PlantingListing cropId={cropId} />
                    </div>
                    {/* Main container end */}

                </div>
                {/* Page content end */}
                <Footer/>

            </div>
            {/* Page wrapper end */}
        </>
    );
}

export default PlantingRecords;