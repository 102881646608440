import {
  useRef,
  useState,
} from 'react';

import {
	Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import axios from '../../../api/axios';
import { baseURL, getTheHttpResponseValue } from '../../../utils/misc';
import Footer from '../../../layout/Footer';
import useAuth from '../../../hooks/useAuth';
import { runningCompany } from '../../../appSetup';

function Login() {
	const { dispatch } = useAuth();

	const usernameRef = useRef();
	const passwordRef = useRef();
	const [error, setError] = useState('');
	const [isConnecting, setIsConnecting] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const from = location?.state?.from.pathname || '/';

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsConnecting(true);
		//payload of authentication
		const user = {
			username: usernameRef.current.value,
			password: passwordRef.current.value
		};

		//submit the form to authentication endpoint
		axios.post(baseURL + "/Users/Authenticate", JSON.stringify(user), {
        	headers: { "Content-Type": "application/json" },
      	})
      	.then((resp) => {
			setIsConnecting(false);
			setError("");
			const currentUser = resp.data;

			const payload = {
				user: {
					id: currentUser.id,
					username: currentUser.username,
					fullName: currentUser.firstName + (currentUser.otherNames ? " " + currentUser.otherNames : "") + ", " + currentUser.lastName,
					firstName: currentUser.firstName,
					lastName: currentUser.lastName,
					otherNames: currentUser.otherNames,
					phone: currentUser.telephone,
					profilePicture: currentUser.profilePicture,
					token: currentUser.token,
					active: currentUser.active,
					roles: currentUser.roles,
				},
			};

			dispatch({ type: "LOGIN_SUCCESS", payload: payload });

			navigate('/', { replace: true });
		})
		.catch(async (err) => {
			await handleNotOkHttpResponse(err.response);
			setIsConnecting(false);
		})
		.finally(() => {
			setIsConnecting(false);
		});
	}
	const handleNotOkHttpResponse = async (response, navigate = null) => {
		// Handle authentication error
		if (!response) {
			console.warn("The response object is invalid!");
			return;
		}
	  
		if (response.status == 400 || response.status == 404 || response.status == 401) {
			const responseMessage = response.data.message ? response.data.message : response.data;
			if(responseMessage) {
				setError(responseMessage);
				// console.error(responseMessage);
			}
			if (response.status == 401) {
				navigate("/");
			}
		} else {
			setError("Could not login. Please try again!!!");
		  	console.error("This request has failed.");
		}
	};
	const handleNotOkHttpResponse2 = async (response, navigate = null) => {
		// Handle authentication error
		if (!response) {
			console.warn("The response object is invalid!");
			return;
		}

		console.log('response >>> : ', response);

		return getTheHttpResponseValue(response)
	};

	return (

		<div className="container">
			<form onSubmit={handleSubmit}>
				<div className="row justify-content-md-center">
					<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
						<div className="login-screen">
							<div className="login-box">
								{error && <div className="alert alert-danger">{error}</div>}
								<div className="text-center">
									<Link to="#" className="login-logo">
										<img src={`${window.location.origin}/assets/img/company_logos/${runningCompany.logo}`} alt={`${runningCompany.name} Logo`} style={{ display: 'block', margin: 'auto' }} />
									</Link>
								</div>
								<h5 style={{color:'black'}}>Welcome back,<br />Please Login to your Account.</h5>
								<div className="form-group">
									<input type="text" ref={usernameRef} className="form-control" placeholder="Username" required />
								</div>
								<div className="form-group">
									<input type="password" ref={passwordRef} className="form-control" placeholder="Password" required />
								</div>
								<div className="actions mt-4">
									{isConnecting ? (
										<button className="btn btn-primary">
											<span className="spinner-border spinner-border-sm"></span> Connecting...
										</button>
									) : (
										<button type="submit" className="btn btn-primary">
											Login
										</button>
									)}
								</div>
								
								<div className="forgot-pwd">
									<h5>
										<a className="link" href="/forgotpassword">
											<i className="fas fa-question-circle"></i> Forgot password?
										</a>
									</h5>
								</div>
								<Footer/>
								
								
							</div>
						</div>
					</div>
					
				</div>
			</form>
			{/* Footer */}
			
		</div>

	);
}

export default Login;
