import { baseURL } from "../../utils/misc";


 export const getQuantityUnits = async () => {
  const requestOptions = {
    method: "GET",
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/miscellaneous/quantity-units`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Quantity Units!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};


export const getRainfallConditions = async () => {
  const requestOptions = {
    method: "GET",
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/miscellaneous/rainfall-conditions`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Rainfall Conditions!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};

export const getTemperatureConditions = async () => {
  const requestOptions = {
    method: "GET",
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/miscellaneous/temperature-conditions`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Temperature Conditions!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};

export const getHumidityConditions = async () => {
  const requestOptions = {
    method: "GET",
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/miscellaneous/humidity-conditions`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Humidity Conditions!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};

export const getWindSpeedConditions = async () => {
  const requestOptions = {
    method: "GET",
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/miscellaneous/wind-speed-conditions`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Quantity Units!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};

export const getLifespanUnits = async () => {
  const requestOptions = {
    method: "GET",
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/miscellaneous/lifespan-units`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Lifespan Units!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};