import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import {  fieldWithIdRequired } from "../../utils/helpers";
import {  getHarvestQuantityUnit } from "../../api/HarvestingRecords/getHarvestingRecords";
import FileInput from "../common/FileInput";
import { getDestinationCountries, getExportModes } from "../../api/ExportCustomsRecords/getExportCustomRecords";
import { getGetAllCurrencies } from "../../api/Currencies/getGetAllCurrencies";

const ExportCustomFormBasic = ({ exportCustomRecord, setExportCustomRecord, handleSubmit, crop }) => {
  const { user } = useAuth();

  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [quantityUnitList, setQuantityUnitList] = useState([]);
  const [exportModeList, setExportModeList] = useState([]);
  const [destinationCountriesList, setDestinationCountriesList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  useEffect(() => {
    getSetUpData();
  }, []);

  useEffect(() => {
    if (exportCustomRecord) {
      // setUserId(userData.id);
    }
  }, [exportCustomRecord]);

  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    try {
      setShowPreloader(true);
      setExportModeList(await getExportModes(user.user.token));
      setQuantityUnitList(await getHarvestQuantityUnit(user.user.token));
      setDestinationCountriesList(await getDestinationCountries(user.user.token));
      setCurrencyList(await getGetAllCurrencies(user.user.token));
    } catch { } finally {
      setShowPreloader(false);
    }
  };


  const validateFields = () => {
    let retVal = true;

    if (!exportCustomRecord.currency) {
      fieldWithIdRequired('currency');
      toast.error("Currency is required.");
      retVal = false;
    }

    if (!exportCustomRecord.customsDeclarationNumber) {
      fieldWithIdRequired('customsDeclarationNumber');
      toast.error("Customs Declaration Number is required.");
      retVal = false;
    }

    if (!exportCustomRecord.destinationCountry) {
      fieldWithIdRequired('destinationCountry');
      toast.error("Destination Country is required.");
      retVal = false;
    }

    if (!exportCustomRecord.exporter) {
      fieldWithIdRequired('exporter');
      toast.error("Exporter is required.");
      retVal = false;
    }

    if (!exportCustomRecord.exportMode) {
      fieldWithIdRequired('exportMode');
      toast.error("Export Mode is required.");
      retVal = false;
    }

    if (!exportCustomRecord.portOfDischarge) {
      fieldWithIdRequired('portOfDischarge');
      toast.error("Port of Discharge is required.");
      retVal = false;
    }

    if (!exportCustomRecord.portOfLoading) {
      fieldWithIdRequired('portOfLoading');
      toast.error("Port of Loading is required.");
      retVal = false;
    }

    if (!exportCustomRecord.totalDeclaredValue) {
      fieldWithIdRequired('totalDeclaredValue');
      toast.error("Total Declared Value is required.");
      retVal = false;
    }



    // environmental conditions data

    return retVal;
  };

  const handleChange = (field, value, required) => {
    const updatedItem = { ...exportCustomRecord }; // Create a copy of the items array
    updatedItem[field] = value; // Update the value in the copy
    setExportCustomRecord(updatedItem); // Update the state with the modified copy
    if (required) fieldWithIdRequired(field); //Show UI validation
  };


  return (
    <>
      <Preloader show={showPreloader} />
      <div className="row gutters">

        {exportCustomRecord && exportCustomRecord.id &&
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
            <div className="form-group">
              <label htmlFor="name">Code</label>
              <input
                type="text"
                className="form-control"
                id="code"
                value={exportCustomRecord.code ? exportCustomRecord.code : '--'}
                readOnly
              />
            </div>
          </div>
        }

        {/* Exporter */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="exporter">Exporter *</label>
            <input
              type="text"
              className="form-control"
              id="exporter"
              placeholder="Enter the exporter..."
              value={exportCustomRecord && exportCustomRecord.exporter}
              onChange={(e) => handleChange("exporter", e.target.value, true)}
            />
            <div className="invalid-feedback">Exporter is required</div>
          </div>
        </div>

        {/* Destination Country */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="destinationCountry">Destination Country *</label>
            <select
              className="form-control form-control-lg"
              id="destinationCountry"
              value={exportCustomRecord && exportCustomRecord.destinationCountry}
              onChange={(e) => handleChange("destinationCountry", e.target.value, true)}
            >
            <option value="">-- Select Destination Country --</option>
            {destinationCountriesList && destinationCountriesList.map((source) => (
              <option key={source} value={source}>
                {source}
              </option>
            ))}
          </select>
            <div className="invalid-feedback">Destination Country is required</div>
          </div>
        </div>

        {/* Export Mode */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="exportMode">Export Mode *</label>
            <select
              className="form-control form-control-lg"
              id="exportMode"
              value={exportCustomRecord && exportCustomRecord.exportMode}
              onChange={(e) => handleChange("exportMode", e.target.value, true)}
            >
              <option value="">-- Select Export Mode --</option>
              {exportModeList && exportModeList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Export Mode is required</div>
          </div>
        </div>



        {/* Customs Declaration Number */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="customsDeclarationNumber">Customs Declaration Number *</label>
            <input
              type="text"
              className="form-control"
              id="customsDeclarationNumber"
              placeholder="Enter the customs declaration number..."
              value={exportCustomRecord && exportCustomRecord.customsDeclarationNumber}
              onChange={(e) => handleChange("customsDeclarationNumber", e.target.value, true)}
            />
            <div className="invalid-feedback">Customs Declaration Number is required</div>
          </div>
        </div>

        {/* Shipping Line or Airline */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="shippingLineOrAirline">Shipping Line or Airline</label>
            <input
              type="text"
              className="form-control"
              id="shippingLineOrAirline"
              placeholder="Enter the shipping line or airline..."
              value={exportCustomRecord && exportCustomRecord.shippingLineOrAirline}
              onChange={(e) => handleChange("shippingLineOrAirline", e.target.value, false)}
            />
          </div>
        </div>

        {/* Flight or Vessel Number */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="flightOrVesselNumber">Flight or Vessel Number</label>
            <input
              type="text"
              className="form-control"
              id="flightOrVesselNumber"
              placeholder="Enter the flight or vessel number..."
              value={exportCustomRecord && exportCustomRecord.flightOrVesselNumber}
              onChange={(e) => handleChange("flightOrVesselNumber", e.target.value, false)}
            />
          </div>
        </div>

        {/* Port of Loading */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="portOfLoading">Port of Loading *</label>
            <input
              type="text"
              className="form-control"
              id="portOfLoading"
              placeholder="Enter the port of loading..."
              value={exportCustomRecord && exportCustomRecord.portOfLoading}
              onChange={(e) => handleChange("portOfLoading", e.target.value, true)}
            />
            <div className="invalid-feedback">Port of Loading is required</div>
          </div>
        </div>

        {/* Port of Discharge */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="portOfDischarge">Port of Discharge *</label>
            <input
              type="text"
              className="form-control"
              id="portOfDischarge"
              placeholder="Enter the port of discharge..."
              value={exportCustomRecord && exportCustomRecord.portOfDischarge}
              onChange={(e) => handleChange("portOfDischarge", e.target.value, true)}
            />
            <div className="invalid-feedback">Port of Discharge is required</div>
          </div>
        </div>

        {/* Departure Date */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="departureDate">Departure Date</label>
            <input
              type="date"
              className="form-control"
              id="departureDate"
              placeholder="Enter the departure date..."
              value={exportCustomRecord && exportCustomRecord.departureDate}
              onChange={(e) => handleChange("departureDate", e.target.value, false)}
            />
          </div>
        </div>

        {/* Arrival Date */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="arrivalDate">Arrival Date</label>
            <input
              type="date"
              className="form-control"
              id="arrivalDate"
              placeholder="Enter the arrival date..."
              value={exportCustomRecord && exportCustomRecord.arrivalDate}
              onChange={(e) => handleChange("arrivalDate", e.target.value, false)}
            />
          </div>
        </div>

        {/* Total Weight */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="totalWeight">Total Weight</label>
            <input
              type="number"
              className="form-control"
              id="totalWeight"
              placeholder="Enter the total weight..."
              value={exportCustomRecord && exportCustomRecord.totalWeight}
              onChange={(e) => handleChange("totalWeight", e.target.value, false)}
            />
          </div>
        </div>

        {/* Weight Unit */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="weightUnit">Weight Unit</label>
            <select
              className="form-control form-control-lg"
              id="weightUnit"
              value={exportCustomRecord && exportCustomRecord.weightUnit}
              onChange={(e) => handleChange("weightUnit", e.target.value, false)}
            >
            <option value="">-- Select Weight Unit --</option>
              {quantityUnitList && quantityUnitList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
              </select>
          </div>
        </div>

        {/* Container Number */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="containerNumber">Container Number</label>
            <input
              type="text"
              className="form-control"
              id="containerNumber"
              placeholder="Enter the container number..."
              value={exportCustomRecord && exportCustomRecord.containerNumber}
              onChange={(e) => handleChange("containerNumber", e.target.value, false)}
            />
          </div>
        </div>

        {/* Total Declared Value */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="totalDeclaredValue">Total Declared Value *</label>
            <input
              type="number"
              className="form-control"
              id="totalDeclaredValue"
              placeholder="Enter the total declared value..."
              value={exportCustomRecord && exportCustomRecord.totalDeclaredValue}
              onChange={(e) => handleChange("totalDeclaredValue", e.target.value, true)}
            />
            <div className="invalid-feedback">Total Declared Value is required</div>
          </div>
        </div>

        {/* Currency */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="currency">Currency *</label>
            <select
              className="form-control"
              id="currency"
              value={exportCustomRecord && exportCustomRecord.currency}
              onChange={(e) => handleChange("currency", e.target.value, true)}
            >
            <option value="">-- Select Currency --</option>
            {currencyList && currencyList.map((item, index) =>
              (<option key={index} value={item.code}>{item.name + ' (' + item.name + ')'}</option>)
            )}
          </select>
            <div className="invalid-feedback">Currency is required</div>
          </div>
        </div>

        {/* Freight Forwarder */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="freightForwarder">Freight Forwarder</label>
            <input
              type="text"
              className="form-control"
              id="freightForwarder"
              placeholder="Enter the freight forwarder..."
              value={exportCustomRecord && exportCustomRecord.freightForwarder}
              onChange={(e) => handleChange("freightForwarder", e.target.value, false)}
            />
          </div>
        </div>

        {/* Handling Agent */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="handlingAgent">Handling Agent</label>
            <input
              type="text"
              className="form-control"
              id="handlingAgent"
              placeholder="Enter the handling agent..."
              value={exportCustomRecord && exportCustomRecord.handlingAgent}
              onChange={(e) => handleChange("handlingAgent", e.target.value, false)}
            />
          </div>
        </div>

        {/* Shipping Documents */}
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="shippingDocuments">Shipping Documents</label>
            <FileInput 
          id="shippingDocuments"
          setValue={(value) => handleChange("shippingDocuments", value, false)}
          required={false}
          className="form-control"
        />
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
          <div className="float-end">
            <button type="button" className="btn btn-primary float-end"
              onClick={(e) => { if (validateFields()) { handleSubmit(e); } }}>
              Submit
            </button>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default ExportCustomFormBasic;