import { Link, useNavigate, useParams } from "react-router-dom";
import SideBarWrapper from "../../../../components/common/SideBarWrapper";
import Navbar from "../../../../layout/Navbar";
import { useEffect, useState } from "react";
import { baseURL } from "../../../../utils/misc";
import useAuth from "../../../../hooks/useAuth";
import Footer from "../../../../layout/Footer";
import ManufacturingProcessForm from "../../../../components/ManufacturingRecords/ManufacturingProcessRecords/ManufacturingProcessForm";
import { toast } from "react-toastify";

const EditManufacturingProcess = () => {
    const { manufacturingProcessId } = useParams();
    const [manufacturingProcesses, setManufacturingProcesses] = useState(null);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!(user && user.user.token)) {
            navigate('/login');
            toast.warn("You seem to have lost your access token. Please try again later.");
            return;
        }
        // Fetch workflow process data when the component mounts
        const fetchData = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                }
            };

            try {
                const response = await fetch(`${baseURL}/manufacturingprocesses/${manufacturingProcessId}`, requestOptions);

                if (response.ok) {
                    const data = await response.json();
                    setManufacturingProcesses(data);
                } else {
                    console.error('Error fetching manufacturing processes data');
                }
            } catch (error) {
                console.error('Error fetching pack house record data:', error);
            }
        };

        fetchData();
    }, [manufacturingProcessId, navigate, user]);



    return (<>


        {/* Page wrapper start */}
        <div className="page-wrapper">

            {/* Sidebar wrapper start */}
            <SideBarWrapper />
            {/* Sidebar wrapper end */}

            {/* Page content start  */}
            <div className="page-content">

                {/* Header start */}
                <Navbar />
                {/* Header end */}

                {/* Page header start */}
                <div className="page-header">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item">{manufacturingProcesses && manufacturingProcesses && manufacturingProcesses.code}</li>
                        <li className="breadcrumb-item">
                            <Link to={`/manufacturingrecord/edit/${manufacturingProcesses && manufacturingProcesses && manufacturingProcesses.manufacturingRecordId}`} >Manufacturing Record</Link>
                        </li>
                        <li className="breadcrumb-item">Manufacturing Process</li>
                        <li className="breadcrumb-item active">Edit</li>
                    </ol>
                    <ul className="app-actions">
                    </ul>
                </div>
                {/* Page header end */}

                {/* Main container start */}
                <div className="main-container">
                    <ManufacturingProcessForm manufacturing={manufacturingProcesses && manufacturingProcesses.manufacturingRecord} manufacturingProcesses={manufacturingProcesses && manufacturingProcesses}/>
                </div>
                {/* Main container end */}

            </div>
            {/* Page content end */}
            <Footer/>

        </div>
        {/* Page wrapper end */}
    </>);
}

export default EditManufacturingProcess;