import { Link } from 'react-router-dom';
import RecordAccessSetups from '../../components/RecordAccessSetups/RecordAccessSetups';
import SideBarWrapper from '../../components/common/SideBarWrapper';
import Navbar from '../../layout/Navbar';
import Footer from '../../layout/Footer';

const RecordAccessSetupsIndex = () => {
  return (
    <>
      <div className="page-wrapper">
        <SideBarWrapper />
        <div className="page-content">
          <Navbar />
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">Record Access Setups</li>
            </ol>
            <ul className="app-actions"></ul>
          </div>
          <div className="main-container">
            <RecordAccessSetups />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default RecordAccessSetupsIndex;
