import { useParams } from "react-router-dom";
import SideBarWrapper from "../../../components/common/SideBarWrapper";
import RegisterExporter from "../../../components/Exporters/RegisterExporter/RegisterExporter";
import Navbar from "../../../layout/Navbar";
import { useEffect } from "react";
import RegisterFarmer from "../../../components/Farmers/RegisterFarmer/RegisterFarmer";
import { runningCompany } from "../../../appSetup";


const RegisterFarmerIndex = () => {
    const { uniqueCode } = useParams();
    return (
    <>
        <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                    <div className="no-auth-screen">
                        <div className="no-auth-box">
                            <br />
                            <img src={`${window.location.origin}/assets/img/company_logos/${runningCompany.logo}`} alt={`${runningCompany.name} Logo`} style={{ display: 'block', margin: 'auto' }} />
                            <br />
                            <RegisterFarmer invitationUniqueCode={uniqueCode}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </> );
}
 
export default RegisterFarmerIndex;