import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { baseURL } from "../../../utils/misc";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../../common/Preloader";
import { getGetWorkflowRecordActivityById } from "../../../api/workflowRecordActivity/getGetWorkflowRecordActivityById";
import ReferencedFormsFooter from "../../../pages/Contracts/WorkflowForms/ReferencedFormsFooter";
import { getContractById } from "../../../api/Contracts/getContractById";
import ReferencedFormsHeader from "../../common/Workflow/ReferencedFormsHeader";
import { getFullBuyerName, getFullCropName, isoToDate } from "../../../utils/helpers";
import { Nav, Tab, Tabs } from "react-bootstrap";
import ContractView from "../ContractView";
import CropRecords from "../../Crop/CropRecords";
import CropInfo from "../../Crop/CropInfo";

const ContractReview = ({contractId, theWorkflowRecordActivity}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [theContract, setTheContract] = useState();
  
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
      toast.warn("You seem to have lost your access token. Please try again later.");
      return;
    }

    setShowPreloader(true);
    const contract = await getContractById(user.user.token, contractId);
    setTheContract(contract);
    setShowPreloader(false);
  };

  const updateFunction = async () => {
    if (!(user && user.user.token)) {
      navigate("/login");
      toast.warn("You seem to have lost your login access. Please try again later.");
      return;
    }

    return true;
    
    // if(!batchNumber) {
    //   toast.warn("Please, enter the  Batch Number!");
    //   return;
    // }

    // const data = batchNumber;
  
    // const requestOptions = {
    //   method: "PATCH",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "text/plain",
    //     Authorization: `Bearer ${user.user.token}`,
    //   },
    //   // body: JSON.stringify(data),
    //   redirect: "follow",
    // };

    // return fetch(`${baseURL}/bmrs/bmrInformation/${contractId}?batchNumber=${batchNumber}`, requestOptions)
    //   .then((response) => {
    //     if (response.ok) {
    //       return;
    //     } else {
    //       throw new Error("Error updating record!");
    //     }
    //   })
    //   .then((data) => {
    //     // Set the useState valuations with the fetched data
    //     toast.success("Record successfully updated.");

    //     //Very necessary part of code
    //     return true;
    //   })
    //   .catch((error) => {
    //     toast.error("Error updating record!");
    //   });
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                {theWorkflowRecordActivity && theWorkflowRecordActivity.workflowProcess
                  ? theWorkflowRecordActivity.workflowProcess.name.replace("Contract - ", "") : "--"} - {theWorkflowRecordActivity && theWorkflowRecordActivity.workflowStage
                    ? theWorkflowRecordActivity.workflowStage.name : "--"}
              </div>
            </div>
            <div className="card-body pt-0" >
              <ReferencedFormsHeader theWorkflowRecordActivity={theWorkflowRecordActivity} />
              {/* <hr /> */}
              <Tabs defaultActiveKey="contract" style={{backgroundColor: "#eff1f5"}}>
                <Tab eventKey="contract" title="Contract Details" style={{backgroundColor: "#eff1f5"}}>
                  {theContract && <ContractView contract={theContract} />}
                </Tab>
                <Tab eventKey="crop" title="Crop Information" style={{backgroundColor: "#eff1f5"}}>
                {theContract && <CropInfo contract={theContract} />}
                </Tab>
                <Tab eventKey="crop-records" title="Crop Records" style={{backgroundColor: "#eff1f5"}}>
                  {theContract && theContract.crop && <CropRecords crop={theContract.crop} />}
                </Tab>
              </Tabs>
            </div>
            <div className="card-footer">
              {theWorkflowRecordActivity && (<ReferencedFormsFooter WorkflowRecordActivity={theWorkflowRecordActivity} submitFunction={updateFunction} />) }
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
};

export default ContractReview;
