import { CompanyIds, runningCompanyId } from "../../appSetup";
import { baseURL } from "../../utils/misc";


export const getPackagingQuantityUnit = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/packagingrecords/quantity-units`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Quantity Units!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};


export const getPackagingTypes = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/packagingrecords/packaging-types`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Packaging Types!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};

export const getPackagingMaterials = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const url = (runningCompanyId !== CompanyIds.TropighaFarmsLtd) ? `${baseURL}/packagingrecords/packaging-materials` : `${baseURL}/packagingrecords/tropigha-packaging-materials`;
  // Return the fetch Promise
  return await fetch(url, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Packaging Materials!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};

export const getPackagingMaterialsByCode = async (token, code) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/fieldoptionssetups/code/${code}`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Packaging Materials!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};

export const getPackagingRecordsByCropId = async (token, cropId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Return the fetch Promise
  return await fetch(`${baseURL}/packagingrecords/crop/${cropId}`, requestOptions)
    .then((response) => { 
      if (!response.ok) {
        return;
      }
      
      return response.json();
    })
    .then((data) => {
      return data; // Return the data as a resolved value
    })
    .catch((error) => {
      console.error("Error fetching Packaging Records!", error);
      throw error; // Rethrow the error to propagate it to the caller
    });
};